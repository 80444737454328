import React, { useEffect, useRef, useState } from 'react';
import useUserLoggedStore from '../../store/user';
import { Col, Layout, Row, Divider, Typography, List, notification, Input, Select, Table, Tag, Space, Button, Drawer, DatePicker } from 'antd';
import { NotificationPlacement } from "antd/es/notification/interface";
import { StoreHeader } from '../../components/StoreHeader';
import { useNavigate, useParams } from 'react-router-dom';
import CasherSale from "../../services/CasherSale"
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import type { FilterValue, SorterResult } from 'antd/es/table/interface';
import { dateFormatter, floatToMoneyFormat, moneyMask, moneyToNumber } from '../../utils/formatters';
import useProductsPriceTag from '../../store/useProductsPriceTag';
import { PrinterOutlined } from '@ant-design/icons';

const { Header, Footer, Content } = Layout;
const { Title, Text } = Typography;
const Context = React.createContext({ name: 'Lojas Infor' });
const { Column, ColumnGroup } = Table;


interface DataType {
    id: number,
    saler_by_auth_user?: {
        first_name: string,
    },
    buyer_by_auth_user?: {
        first_name: string,
    },
    total_to_receive: number,
    total_received: number,
    cost_total_value: number,
    updated_at: string,
    client_level: number,
    state: string,
    total_discount: number,
}

interface Ifilter{
    id?: number,
    state?: string,
    client_level?: number,
    buyer_by_auth_user__first_name__unaccent__icontains?: string,
    saler_by_auth_user__first_name__unaccent__icontains?: string,
    advanced_authorization_by_auth_user__name__unaccent__id?: string,
    exchange?: boolean,
    gift?: boolean,
    inserted_at__gte?: string,
    inserted_at__lte?: string,
    total_to_receive__gte?: number,
    total_to_receive__lte?: number,
    total_wait_receive__gte?: string,
    total_wait_receive__lte?: string,
    buyer_by_auth_user__auth_user_information_related__whatsapp_phone__unaccent__icontains?: string,
    buyer_by_auth_user__auth_user_information_related__cpf__unaccent__icontains?: string,
}

interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: string;
    sortOrder?: string;
    filters?: Record<string, FilterValue>;
  }

const SalesAdminPage = () => {        
    const dataFetchedRef = useRef(false);
    const { storeId } = useParams();
    const [products, setProducts] = useState([]);
    const clearUser = useUserLoggedStore( (state: any) => state.clearUser );    
    const [api, contextHolder] = notification.useNotification();  
    const [searchType, setSearchType] = useState("Código");
    const [searchParam, setSearchParam] = useState<string>("");    
    const [loading, setLoading] = useState(false); 
    const [data, setData] = useState<DataType[]>();    
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
        current: 1,
        pageSize: 15,
        pageSizeOptions: ["15", "50", "100"],
        showSizeChanger: true,
        locale: { items_per_page: " / Página" }
        },
    });   
    const clearProduct = useProductsPriceTag( (state: any) => state.clearProduct )
    const addProduct = useProductsPriceTag( (state: any) => state.addProduct )
    const useProducts = useProductsPriceTag( (state: any) => state.products )
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const [searchParamId, setSearchParamId] = useState("");
    const [searchParamState, setSearchParamState] = useState("Todos os Estados");
    const [searchParamClientLevel, setSearchParamClientLevel] = useState("Todos os Níveis");
    const [searchParamBuyerName, setSearchParamBuyerName] = useState("");
    const [searchParamBuyerWhatsapp, setSearchParamBuyerWathsapp] = useState("");
    const [searchParamBuyerCpf, setSearchParamBuyerCpf] = useState("");
    const [searchParamSaler, setSearchParamSaler] = useState("");
    const [searchParamAdvancedPerm, setSearchParamAdvancedPerm] = useState();
    const [searchParamExchange, setSearchParamExchange] = useState();
    const [searchParamGift, setSearchParamGift] = useState();
    const [searchParamInsertGte, setSearchParamInsertGte] = useState<any|null>();
    const [searchParamInsertLte, setSearchParamInsertLte] = useState<any|null>();
    const [searchParamTotalGte, setSearchParamTotalGte] = useState("R$ 0,00");
    const [searchParamTotalLte, setSearchParamTotalLte] = useState("R$ 0,00");
    const [searchParamTotaWaitlGte, setSearchParamTotaWaitlGte] = useState();
    const [searchParamTotalWaitLte, setSearchParamTotalWaitLte] = useState();
    const [lastFilter, setLastFilter] = useState<Ifilter>();

    useEffect(() => {
        document.title = 'Adm Vendas - Lojas Infor';
    }, []);

    useEffect(()=>{        
        if(searchParamId && searchParamId.length > 0){                     
            searchSales();
        }
    }, [searchParamId]);
    
    useEffect(()=>{        
        if(searchParamClientLevel && searchParamClientLevel.length > 0){            
            searchSales();
        }
    }, [searchParamClientLevel]);
    
    useEffect(()=>{        
        if(searchParamState && searchParamState.length > 0){            
            searchSales();
        }
    }, [searchParamState]);

    useEffect(()=>{        
        if(searchParamBuyerName && searchParamBuyerName.length > 0){            
            searchSales();
        }
    }, [searchParamBuyerName]);

    useEffect(()=>{        
        if(searchParamBuyerWhatsapp && searchParamBuyerWhatsapp.length > 0){            
            searchSales();
        }
    }, [searchParamBuyerWhatsapp]);

    useEffect(()=>{        
        if(searchParamBuyerCpf && searchParamBuyerCpf.length > 0){            
            searchSales();
        }
    }, [searchParamBuyerCpf]);

    useEffect(()=>{        
        if(searchParamSaler && searchParamSaler.length > 0){            
            searchSales();
        }
    }, [searchParamSaler]);

    useEffect(()=>{        
        if(searchParamInsertGte){            
            searchSales();
        }
    }, [searchParamInsertGte]);

    useEffect(()=>{        
        if(searchParamInsertLte){            
            searchSales();
        }
    }, [searchParamInsertLte]);
    
    useEffect(()=>{        
        if(searchParamTotalGte && searchParamTotalGte.length > 0){            
            searchSales();
        }
    }, [searchParamTotalGte]);
    
    useEffect(()=>{        
        if(searchParamTotalLte && searchParamTotalLte.length > 0){            
            searchSales();
        }
    }, [searchParamTotalLte]);
    
    const openNotificationSuccess = (placement: NotificationPlacement, description: string) => {    
        api.success({
          message: "Sucesso",
          description: description,
          placement,
          duration: 2
        });
    }

    const openNotificationError = (placement: NotificationPlacement, description: string) => {    
        api.error({
          message: "Erro",
          description: description,
          placement,
          duration: 2
        });
    }

    const handleOk = (sale?: any) => {        
        // openNotificationSuccess("bottomRight", "Direcinando Para Venda.")
        // if(props?.origin && props.origin=="sale"){
        //     navigate("/lojas/"+storeId+"/venda/"+sale.id);
        // }
        // else{
        //     navigate("/lojas/"+storeId+"/caixa/venda/"+sale.id);    
        // }
        
        // props.handleCancel();
    };
    
    const handleSearch = async (e: any) => {
        setSearchParam(e.target.value);
    }

    useEffect(()=>{
        if (searchParam.length > 0) {
            searchSales();
        }
    }, [searchParam]);

    const searchSales = (limit?: number, offset?: number, order?: string) => {
        if(loading == false) {        
            setLoading(true);                         

            let filter: Ifilter = {} ;     
            
            filter.id = searchParamId && searchParamId.length > 0 ? parseInt(searchParamId) : undefined;  
            filter.state = searchParamState && searchParamState.length > 0 && searchParamState != "Todos os Estados"  ? searchParamState : undefined;     
            filter.client_level = searchParamClientLevel && searchParamClientLevel.length > 0 && searchParamClientLevel != "Todos os Níveis"  ? parseInt(searchParamClientLevel) : undefined;     
            filter.buyer_by_auth_user__auth_user_information_related__whatsapp_phone__unaccent__icontains = searchParamBuyerWhatsapp ? searchParamBuyerWhatsapp : undefined;
            filter.buyer_by_auth_user__auth_user_information_related__cpf__unaccent__icontains = searchParamBuyerCpf ? searchParamBuyerCpf : undefined;
            filter.buyer_by_auth_user__first_name__unaccent__icontains = searchParamBuyerName ? searchParamBuyerName : undefined;   
            filter.saler_by_auth_user__first_name__unaccent__icontains = searchParamSaler ? searchParamSaler : undefined;                                    
            // filter.exchange = searchParamExchange ? searchParamExchange : undefined;    
            // filter.gift = searchParamGift ? searchParamGift : undefined;
            filter.inserted_at__gte = searchParamInsertGte ? searchParamInsertGte.format("YYYY") + "-" + searchParamInsertGte.format("MM") + "-" + searchParamInsertGte.format("DD") : undefined;   
            filter.inserted_at__lte = searchParamInsertLte ? searchParamInsertLte.format("YYYY") + "-" + searchParamInsertLte.format("MM") + "-" + searchParamInsertLte.format("DD") : undefined;   
            filter.total_to_receive__gte = moneyToNumber(searchParamTotalGte) > 0 ? moneyToNumber(searchParamTotalGte) : undefined;     
            filter.total_to_receive__lte = moneyToNumber(searchParamTotalLte) > 0 ? moneyToNumber(searchParamTotalLte) : undefined;   
            // filter.total_wait_receive__gte = searchParamTotaWaitlGte ? searchParamTotaWaitlGte : undefined;     
            // filter.total_wait_receive__lte = searchParamTotalWaitLte ? searchParamTotalWaitLte : undefined;                  
                        
            offset = ((tableParams?.pagination?.pageSize ? tableParams?.pagination?.pageSize : 15) * (tableParams?.pagination?.current ? tableParams?.pagination?.current-1 : 0));            
            limit = tableParams?.pagination?.pageSize;
            
            
            CasherSale.list(storeId ? storeId : "0", filter, limit, offset, order)
            .then((res: any) => {
                setProducts(res.data.data);
                setData(res.data.data);
                setTableParams({
                    ...tableParams,
                    pagination: {
                    ...tableParams.pagination,
                    total: res.data.total,
                    // 200 is mock data, you should read it from server
                    // total: data.totalCount,
                },
                });
            })
            .catch( (error: any) => {
                setProducts([])
                // setLoading(false);
            })
            .finally(() =>{
                setLoading(false);                    
            });
        }
    }

    const handleChange = (value: string) =>{        
        setSearchType(value);        
    }

    useEffect(() => {
        searchSales();
      }, [JSON.stringify(tableParams)]);

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<DataType> | SorterResult<DataType>[],
      ) => {

        setTableParams({
          pagination,
          ...sorter,
        });
    
        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
          setData([]);
        }
      };

    const handleClearFilter = () =>{
        setSearchParamId("");
        setSearchParamState("Todos os Estados");
        setSearchParamClientLevel("Todos os Níveis");
        setSearchParamBuyerName("");
        setSearchParamBuyerWathsapp("");
        setSearchParamBuyerCpf("");
        setSearchParamSaler("");
        setSearchParamInsertGte(null);
        setSearchParamInsertLte(null);
        setSearchParamTotalGte("R$ 0,00");
        setSearchParamTotalLte("R$ 0,00");
        searchSales();
    }

    return(     
        <Context.Provider value={{name: "Geral"}}>
            {contextHolder}
            <Layout>
            <StoreHeader />                              
            <Content style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
              background: "#fff",
            }}>
                <Button onClick={()=>setOpen(true)} icon={<PrinterOutlined />}>Imprimir {useProducts.length}</Button>
                <Title>Vendas</Title>
                <Input.Group compact style={{marginBottom: "15px"}}>                           
                    <Input size="large" style={{ width: '10%' }}  placeholder="ID" value={searchParamId} onChange={(e:any)=>{setSearchParamId(e.target.value)}} autoComplete="off"/>
                    <Select
                        size="large"
                        defaultValue="Todos os Níveis"
                        style={{ width: "20%" }}
                        onChange={(value: any)=>{setSearchParamClientLevel(value)}}     
                        value={searchParamClientLevel}               
                        options={[
                            {
                                value: 'Todos os Níveis',
                                label: 'Todos os Níveis',
                            },
                            {
                                value: '1',
                                label: 'Nível 1',
                            },
                            {
                                value: '2',
                                label: 'Nível 2',
                            },
                            {
                                value: '3',
                                label: 'Nível 3',
                            },
                            {
                                value: '4',
                                label: 'Nível 4',
                            },
                        ]}
                    />
                    <Select
                        size="large"
                        defaultValue="Todos os Estados"
                        style={{ width: "20%" }}
                        onChange={(value: any)=>{setSearchParamState(value)}}     
                        value={searchParamState}               
                        options={[
                            {
                                value: 'Todos os Estados',
                                label: 'Todos os Estados',
                            },
                            {
                                value: 'Carrinho de Compras',
                                label: 'Carrinho de Compras',
                            },
                            {
                                value: 'Em Pausa',
                                label: 'Em Pausa',
                            },
                            {
                                value: 'A Preparar',
                                label: 'A Preparar',
                            },
                            {
                                value: 'A Retirar',
                                label: 'A Retirar',
                            },
                            {
                                value: 'A Enviar',
                                label: 'A Enviar',
                            },
                            {
                                value: 'A Caminho',
                                label: 'A Caminho',
                            },
                            {
                                value: 'Em Devolução',
                                label: 'Em Devolução',
                            },
                            {
                                value: 'Concluído',
                                label: 'Concluído',
                            },
                            {
                                value: 'Cancelado',
                                label: 'Cancelado',
                            },
                        ]}
                    />
                    <Input size="large" style={{ width: '10%' }}  placeholder="Nome Vendedor" value={searchParamSaler} onChange={(e:any)=>{setSearchParamSaler(e.target.value)}} autoComplete="off"/>   
                    <DatePicker size="large" placeholder='De:' value={searchParamInsertGte} onChange={(value: any) => setSearchParamInsertGte(value)} format={"DD/MM/YYYY"} />
                    <DatePicker size="large" placeholder='Até:' value={searchParamInsertLte} onChange={(value: any) => setSearchParamInsertLte(value)} format={"DD/MM/YYYY"} /> 
                    </Input.Group>
                    <Input.Group>
                    <Input size="large" style={{ width: '20%' }}  placeholder="Nome Cliente" value={searchParamBuyerName} onChange={(e:any)=>{setSearchParamBuyerName(e.target.value)}} autoComplete="off"/>   
                    <Input size="large" style={{ width: '20%' }}  placeholder="Whatsapp" value={searchParamBuyerWhatsapp} onChange={(e:any)=>{setSearchParamBuyerWathsapp(e.target.value)}} autoComplete="off"/>                                   
                    <Input size="large" style={{ width: '20%' }}  placeholder="CPF" value={searchParamBuyerCpf} onChange={(e:any)=>{setSearchParamBuyerCpf(e.target.value)}} autoComplete="off"/>      
                    <Input size="large" style={{ width: '15%' }}  placeholder="Valor De:" value={searchParamTotalGte} onChange={(e:any)=>{setSearchParamTotalGte(moneyMask(e.target.value))}} autoComplete="off"/>   
                    <Input size="large" style={{ width: '15%' }}  placeholder="Valor Até:" value={searchParamTotalLte} onChange={(e:any)=>{setSearchParamTotalLte(moneyMask(e.target.value))}} autoComplete="off"/>   
                    <Button type="link" onClick={handleClearFilter}>Limpar</Button>
                    </Input.Group>
                                
                <Table
                    // columns={columns}
                    rowKey={(record) => record.id}
                    dataSource={data}
                    pagination={tableParams.pagination}
                    loading={loading}
                    onChange={handleTableChange}                                      
                    expandable={{
                        expandedRowRender: (record: any) =>                             
                            <Text style={{ margin: 0 }}>
                                
                                <>
                                <Text>Whatsapp: <b>{record?.buyer_by_auth_user?.auth_user_information_related?.whatsapp_phone ? record?.buyer_by_auth_user?.auth_user_information_related?.whatsapp_phone : "-"}</b></Text>
                                <br/>
                                <Text>Troca: <b>{record.exchange ? "Sim" : "Não"}</b></Text>
                                <br/>
                                <Text>Brinde: <b>{record.gift ? "Sim" : "Não"}</b></Text>
                                <br/>
                                <Text>Adicionado Por: <b>{record?.inserted_by_auth_user?.first_name}</b></Text>
                                <br/>
                                <Text>Total Sem Desconto: <b>{floatToMoneyFormat(record?.sub_total_without_discount, 2)}</b></Text>
                                <br/>
                                <Text>Total de Descontos: <b>{floatToMoneyFormat(record?.total_discount, 2)}</b></Text>
                                <br/>
                                <Text>QTD. de Itens: <b>{record?.total_quantity}</b></Text>
                                <br/>
                                <Text>Atualizado Por: <b>{record?.updated_by_auth_user?.first_name}</b></Text>
                                <br/>
                                <Text>Atualizado Em: <b>{dateFormatter(record.updated_at)}</b></Text>
                                <br/>
                                <Button type='link' target="_blank" href={"/lojas/" + storeId + "/venda/" + record?.id}>+Detalhes</Button>
                                </>                               

                            </Text>,
                        rowExpandable: (record: any) => record.name !== 'Sem Desconto',
                    }}
                    >
                        <Column
                            title="ID"
                            key="id"
                            render={(_: any, record: DataType) => (                                
                                <p>{record.id}</p>                                
                            )}
                        />
                        <Column
                            title="Cliente"
                            key="buyer_by_auth_user"
                            render={(_: any, record: DataType) => (                                
                                <p>{(record.buyer_by_auth_user?.first_name ? record.buyer_by_auth_user?.first_name : "")+" Nível ["+record.client_level+"]"}</p>                                
                            )}
                        />
                        <Column
                            title="Vendedor"
                            key="saler_by_auth_user"
                            render={(_: any, record: DataType) => (                                
                                <p>{(record.saler_by_auth_user?.first_name ? record.saler_by_auth_user?.first_name : "")}</p>                                
                            )}
                        />
                        <Column
                            title="Total a Pagar"
                            key="total_to_receive"
                            render={(_: any, record: DataType) => (                                
                                <p>{floatToMoneyFormat(record.total_to_receive, 2)}</p>                                
                            )}
                        />
                        <Column
                            title="Total Pago"
                            key="total_received"
                            render={(_: any, record: DataType) => (                                
                                <p>{floatToMoneyFormat(record.total_received, 2)}</p>                                
                            )}
                        />
                         <Column
                            title="Desconto"
                            key="total_discount"
                            render={(_: any, record: DataType) => (                                
                                <p>{floatToMoneyFormat(record.total_discount, 2)}</p>                                
                            )}
                        />
                        <Column
                            title="Data"
                            key="inserted_at"
                            render={(_: any, record: DataType) => (                                
                                <p>{dateFormatter(record.updated_at)}</p>                                
                            )}
                        />
                        <Column
                            title="Lucro"
                            key="cost_total_value"
                            render={(_: any, record: DataType) => (                                
                                <p style={{color: (record.total_received-record.cost_total_value) < 0 ? 'red' : 'black' }}>{floatToMoneyFormat(record.total_received-record.cost_total_value, 2)}</p>                                
                            )}
                        />
                        <Column
                            title="Estado"
                            key="state"
                            render={(_: any, record: DataType) => (                                
                                <p>{record.state}</p>                                
                            )}
                        />
                </Table>
                <Drawer title="Produtos Selecionados" placement="right" onClose={()=>setOpen(false)} open={open}>
                    {useProducts.map((prod: any)=>{
                        return <p>{prod.code} | {prod.name}</p>
                    })}
                    <Button type="link" onClick={()=>clearProduct()}>Remover Todos</Button>
                    <Button type="primary" onClick={()=>window.open("/lojas/"+storeId+"/produtos/imprimir", '_blank', 'noreferrer')}>Confirmar</Button>
                </Drawer>                    
            </Content>
            <Footer style={{textAlign: "center"}}>Todos os Direitos Reservados</Footer>
        </Layout>   
        </Context.Provider>  
    ); 
}
  
export default SalesAdminPage;