import create from 'zustand';
import { persist } from 'zustand/middleware';

const useBarcode = create(
    persist(
        (set, get) => ({
            barcode: "",
            addBarcode: (params: any) => set((state: any) => ({ barcode: params })),
            clearBarcode: () => set((state: any) => ({ barcode: "" })),            
        }),
        {
            name: 'casher-barcode-storage', 
        }
    )
)

export default useBarcode;