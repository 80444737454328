import { useEffect, useRef, useState } from 'react';
import useUserLoggedStore from '../../store/user';
import Authentication from "../../services/Authentication";
import { Col, Layout, Row, Divider, Typography, List } from 'antd';
import { useNavigate } from 'react-router-dom';

const { Header, Footer, Content } = Layout;
const { Title } = Typography;

const StorePage = () => {        
    const dataFetchedRef = useRef(false);
    const [lojas, setLojas] = useState([]);
    const clearUser = useUserLoggedStore( (state: any) => state.clearUser );
    const navigate = useNavigate();
    
    useEffect(() => {
        document.title = 'Lojas - Lojas Infor';
    }, []);

    useEffect(()=>{ 
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        Authentication.get_stores()
            .then((resStore: any)=>{        
                setLojas(resStore.data.data);
            })
            .catch((error: any)=>{
                if (error.response.status == 401){
                    clearUser();
                }
                else{

                }                
            });
            ;
    }, [])

    return(       
        <>
        <Header style={{background: "#000", display: "flex"}}>
                <Title style={{color: "#fff", padding: "0px 15px", cursor: "pointer"}} onClick={()=>navigate("/")}>Grupo Infor</Title>
            </Header>
        <Layout>     
            
            <Content style={{minHeight: "80vh"}}>
                <List
                    header={<div>Lojas</div>}  
                    bordered
                    dataSource={lojas}
                    renderItem={(item: any) => (
                        <List.Item>
                            <Typography.Text mark>
                            <a href={'/lojas/'+item?.id+'/produtos'} 
                                        style={{color: item?.color_css? item?.color_css : "#fff"}}>
                                        <div style={{ 
                                                background: item?.background_css ? item?.background_css : "rgb(153 153 153)", padding: '8px 10px' }}>
                                            {item?.name}                                            
                                        </div>
                                    </a>
                            </Typography.Text>
                        </List.Item>
                    )}
                    />
            </Content>
            <Footer style={{textAlign: "center"}}>Todos os Direitos Reservados</Footer>
        </Layout>   
        </>     
    ); 
}
  
export default StorePage;