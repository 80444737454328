import React from 'react';
import logo from './logo.svg';
import './App.scss';
import AppRouters from './routers';

function App() {
  return (
    <AppRouters />    
  );
}

export default App;
