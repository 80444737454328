import { useQuery } from "react-query";
import StoreService from "../services/Organization";
import { IAPIUserStoreRes } from "../services/Organization/organization.interfaces";

const fetchUserStoreMe = (storeId: string | undefined): Promise<IAPIUserStoreRes> => {
    return StoreService.get_user_store(storeId)
            .then( (res: any) => {
                return res.data;
            })
            .catch( (error: any) => {
                return null
            })
};

const useUserStoreMe = (storeId: string | undefined) => {
    return useQuery(["userStoreMe", storeId], ()=>fetchUserStoreMe(storeId));
}

export {useUserStoreMe}