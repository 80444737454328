import { useQuery } from "react-query";
import CasherService from "../services/Casher";
import { IAPICTerminalRes, IAPIMacRes } from "../services/Casher/casher.interfaces";

const fetchTerminal = (): Promise<IAPICTerminalRes | null> => {
    return CasherService.get_mac()            
            .then((response: any) => {
                let machine_data: IAPIMacRes = response.data;
                return CasherService.get_terminal(machine_data)
                    .then((resTerminal: any)=>{
                        let terminal: IAPICTerminalRes = resTerminal.data;                    
                        return terminal;
                    })
            })
            .catch( (error: any) => {
                return null
            })
};

const useLocalTerminal = (storeId: string | undefined) => {
    return useQuery(["useLocalTerminal", storeId], ()=>fetchTerminal());
}

export {useLocalTerminal}