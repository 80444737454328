import { Button, Dropdown, Layout, MenuProps, Space, Spin, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../store/useStore";
import {PoweroffOutlined, MenuOutlined, AppstoreOutlined, FileTextOutlined, PercentageOutlined, BarcodeOutlined} from '@ant-design/icons';
import Authentication from "../../services/Authentication";
import { useUserStoreMe } from "../../store/useUserStoreMe";
import useUserLoggedStore from "../../store/user";
const { Sider, Content, Header } = Layout;
const {Title} = Typography;


export const StoreHeader = () => {
    const { storeId } = useParams();    
    const store = useStore(storeId);    
    const navigate = useNavigate();
    const userStoreMe = useUserStoreMe(storeId);
    const userLogged = useUserLoggedStore( (state: any) => state.user );
    const clearUser = useUserLoggedStore( (state: any) => state.clearUser );    

    const handleLoggout = () => {
        Authentication.logout()
        .then(function (response: any) {
          clearUser();
          navigate("/login");
        })
        .catch(function (error: any) {          
        });
      }
    let items: MenuProps['items'] = [
      {
        key: '1',
        label: (
          <Button type="link" onClick={()=>navigate("/lojas")}><AppstoreOutlined />Lojas</Button>
        ),
      },
      {
        key: '2',
        label: (
          <><Button type="link" onClick={handleLoggout}><PoweroffOutlined /> Sair</Button></>
        ),
      }
    ];
    
    if (userStoreMe && userStoreMe?.data && userStoreMe?.data?.role == "Vendedor"){
      items = [
          {
            key: '1',
            label: (
              <Button type="link" onClick={()=>navigate("/lojas")}><AppstoreOutlined />Lojas</Button>
            ),
          },
          {
            key: '2',
            label: (
              <><Button type="link" onClick={()=>navigate("/lojas/"+ storeId + "/venda")}><PercentageOutlined /> Venda</Button></>
            ),
          },
          {
            key: '3',
            label: (
              <><Button type="link" onClick={()=>navigate("/lojas/" + storeId + "/produtos")}><FileTextOutlined /> Produtos</Button></>
            ),
          },
          {
            key: '4',
            label: (
              <><Button type="link" onClick={handleLoggout}><PoweroffOutlined /> Sair</Button></>
            ),
          }
      ];
    }

    if (userStoreMe && userStoreMe?.data && userStoreMe?.data?.role == "Caixa"){
      items = [
          {
            key: '1',
            label: (
              <Button type="link" onClick={()=>navigate("/lojas")}><AppstoreOutlined />Lojas</Button>
            ),
          },
          {
            key: '2',
            label: (
              <><Button type="link" onClick={()=>navigate("/lojas/"+ storeId + "/caixa")}><BarcodeOutlined /> Caixa</Button></>
            ),
          },
          {
            key: '3',
            label: (
              <><Button type="link" onClick={()=>navigate("/lojas/" + storeId + "/produtos")}><FileTextOutlined /> Produtos</Button></>
            ),
          },     
          {
            key: '4',
            label: (
              <><Button type="link" onClick={handleLoggout}><PoweroffOutlined /> Sair</Button></>
            ),
          }
      ];
    }

    if (userStoreMe && userStoreMe?.data && userStoreMe?.data?.role == "Gerente"){
      items = [
          {
            key: '1',
            label: (
              <Button type="link" onClick={()=>navigate("/lojas")}><AppstoreOutlined />Lojas</Button>
            ),
          },
          {
            key: '2',
            label: (
              <><Button type="link" onClick={()=>navigate("/lojas/"+ storeId + "/venda")}><PercentageOutlined /> Venda</Button></>
            ),
          },
          {
            key: '3',
            label: (
              <><Button type="link" onClick={()=>navigate("/lojas/"+ storeId + "/caixa")}><BarcodeOutlined /> Caixa</Button></>
            ),
          },
          {
            key: '4',
            label: (
              <><Button type="link" onClick={()=>navigate("/lojas/" + storeId + "/produtos")}><FileTextOutlined /> Produtos</Button></>
            ),
          },
          {
            key: '5',
            label: (
              <><Button type="link" onClick={()=>navigate("/lojas/" + storeId + "/admin/vendas")}><FileTextOutlined /> Admin Vendas</Button></>
            ),
          },        
          {
            key: '6',
            label: (
              <><Button type="link" onClick={handleLoggout}><PoweroffOutlined /> Sair</Button></>
            ),
          }
      ];
    }

    return (
        <>

        <Header style={
            {
                background: store?.data?.background_css ? store?.data?.background_css : "#fff",
                color: store?.data?.color_css ? store?.data?.color_css : "#000",
                padding: "0px 15px",
                display:"flex",
                justifyContent: "space-between",
            }
        }>  
            <Space>
                <h1>{store?.data?.name ? store?.data?.name : <Spin /> }</h1>                
                <Dropdown menu={{ items }} placement="bottomLeft">
                    <Button type='text' style={{color: store?.data?.color_css ? store?.data?.color_css : "#000", fontWeight: "bold"}}>
                        <MenuOutlined />
                    </Button>
                </Dropdown>                
            </Space>
            <Space>              
              <p>{userLogged?.first_name}</p>                            
              <p>{userStoreMe?.data?.role}</p>
            </Space>
        </Header>     
        </>    
    );
}