import { Button, Modal, notification } from "antd";
import casherSale from "../../services/CasherSale";
import { useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import React, { useState } from "react";
import { NotificationPlacement } from "antd/es/notification/interface";

const Context = React.createContext({ name: 'Lojas Infor' });

interface IAddSaleProductModalProps {
    visible: boolean, handleCancel: Function
}

export const CasherUpdateStateCanceladoModal = (props: IAddSaleProductModalProps) => {
    const [api, contextHolder] = notification.useNotification();
    const { storeId, saleId } = useParams();    
    const queryClient = useQueryClient()    
    const [searchType, setSearchType] = useState("1");
    
    const openNotificationSuccess = (placement: NotificationPlacement, description: string) => {    
        api.success({
          message: "Sucesso",
          description: description,
          placement,
          duration: 2
        });
    }

    const openNotificationError = (placement: NotificationPlacement, description: string) => {    
        api.error({
          message: "Erro",
          description: description,
          placement,
          duration: 2
        });
    }

    const handleOk = (user: any) => {        
        const param = {
            state: "Cancelado",
        };
        
        casherSale.put_state(saleId, param)
            .then((res: any) =>{                
                queryClient.invalidateQueries(['currentSale', storeId, saleId]);
                queryClient.invalidateQueries(['currentSaleProducts', saleId]);           
                openNotificationSuccess("bottomRight", "Cancelado com Sucesso." );
                onCancel();
            })
            .catch((error:any)=>{
                if(error.response?.data?.detail) {
                    openNotificationError("bottomRight", error.response?.data?.detail);
                }
                else{
                    openNotificationError("bottomRight", "Problema ao Atualizar Estado");
                }
            })
    };
    
    const onCancel = () => {        
        props.handleCancel();      
    };     

    return (
        <Context.Provider value={{name: "Geral"}}>
        {contextHolder}
        <Modal
            open={props.visible}
            title="Cancelar Venda"
            onOk={handleOk}
            onCancel={onCancel}
            footer={[                        
            <Button key="back" onClick={onCancel}>
                Voltar
            </Button>,            
            <Button type="primary" key="remove" onClick={handleOk}>
                Confirmar
            </Button>
            ]}
    >     
        Cancelar Venda?
        </Modal>
        </Context.Provider>
    );
}