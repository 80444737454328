import React, { useState } from "react"
import {
    BrowserRouter,
    Routes,
    Route,
  } from "react-router-dom";
import CasherPage from "./pages/casher";
import LoginPage from "./pages/login";
import StorePage from "./pages/store";
import SalePage from "./pages/sale";
import ProductsPage from "./pages/products";
import SalesAdminPage from "./pages/salesAdmin";
import useUserLoggedStore from "./store/user";
import ProductsPrintTag from "./pages/productsPrintTag";
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from "react-query/devtools";
import EcommMain from "./pages/ecommMain";
import EcommStore from "./pages/ecommStore";
import SalePrintPaper from "./pages/salePrintPaper";

const AppRouters = (props: any) =>{        
    const user = useUserLoggedStore( (state: any) => state.user );
    const queryClient = new QueryClient();
    const queryClientNotLogged = new QueryClient();

    let page = null;
    if(user.username == null){
        page =  
        <QueryClientProvider client={queryClientNotLogged}>
            <Routes>            
                <Route path="/" element={<EcommMain />} />
                <Route path="/loja/:storeId" element={<EcommStore />} />
                <Route path="/lojas" element={<StorePage />} />
                <Route path="*" element={<LoginPage />} />
            </Routes>
            <ReactQueryDevtools />
        </QueryClientProvider>
    }
    else{
        page =
            <QueryClientProvider client={queryClient}>
                <Routes>                
                    <Route path="/" element={<EcommMain />} />
                    <Route path="/loja/:storeId" element={<EcommStore />} />
                    <Route path="/lojas" element={<StorePage />} />
                    <Route path="/lojas/:storeId/caixa/venda/:saleId/" element={<CasherPage />} />
                    <Route path="/lojas/:storeId/caixa" element={<CasherPage />} />
                    <Route path="/lojas/:storeId/venda/:saleId/" element={<SalePage />} />
                    <Route path="/lojas/:storeId/venda/:saleId/imprimir" element={<SalePrintPaper />} />
                    <Route path="/lojas/:storeId/venda" element={<SalePage />} />
                    <Route path="/lojas/:storeId/produtos" element={<ProductsPage />} />
                    <Route path="/lojas/:storeId/admin/vendas" element={<SalesAdminPage />} />
                    <Route path="/lojas/:storeId/produtos/imprimir" element={<ProductsPrintTag />} />                    
                    <Route path="/login" element={<LoginPage />} />
                </Routes>
                <ReactQueryDevtools />
            </QueryClientProvider>
    }

    return (
        <BrowserRouter>
            {page}
        </BrowserRouter>
    );
}

export default AppRouters;