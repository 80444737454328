import { Button, Input, Modal,notification, Typography } from "antd";
import { LoadingOutlined, BarcodeOutlined } from '@ant-design/icons';
import casherSale from "../../services/CasherSale";
import { useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import React, { useEffect, useState } from "react";
import { NotificationPlacement } from "antd/es/notification/interface";
import useBarcode from "../../store/useBarcode";

const Context = React.createContext({ name: 'Lojas Infor' });
const {Text} = Typography;

interface IAddSaleProductModalProps {
    visible: boolean, handleCancel: Function, product: any
}


export const CasherRemoveProductModal = (props: IAddSaleProductModalProps) => {    
    const [api, contextHolder] = notification.useNotification();
    const { storeId, saleId } = useParams();    
    const queryClient = useQueryClient();
    const [loading, setLoading] = useState(false);    
    
    const openNotificationSuccess = (placement: NotificationPlacement, description: string) => {    
        api.success({
          message: "Sucesso",
          description: description,
          placement,
          duration: 2
        });
    }

    const openNotificationError = (placement: NotificationPlacement, description: string) => {    
        api.error({
          message: "Erro",
          description: description,
          placement,
          duration: 2
        });
    }

    const handleOk = () => {    
        casherSale.delete_product(saleId, props.product.id)
            .then((res: any) =>{                
                queryClient.invalidateQueries(['currentSale', storeId, saleId]);       
                queryClient.invalidateQueries(['currentSaleProducts', saleId]);
                openNotificationSuccess("bottomRight", "Sucesso ao Remover Produto." );
                onCancel();
            })
            .catch((error:any)=>{
                if(error.response?.data?.detail) {
                    openNotificationError("bottomRight", error.response?.data?.detail);
                }
                else{
                    openNotificationError("bottomRight", "Problema ao Remover Produto");
                }
            })
    };
    
    const onCancel = () => {        
        props.handleCancel();    
    };

    return (
        <Context.Provider value={{name: "Geral"}}>
        {contextHolder}
        <Modal
            open={props.visible}
            title="Cancelar Produto"
            onOk={handleOk}
            onCancel={onCancel}
            footer={[
                <Button key="back" onClick={onCancel}>
                    Voltar
                </Button>,
                <Button type="primary" key="Remover da Venda" onClick={handleOk}>
                    Confirmar
                </Button>,
            ]}
    >                      
            <Text> 
                Remover {props.product?.iventory_product?.name} da venda?
            </Text>
        </Modal>
        </Context.Provider>
    );
}