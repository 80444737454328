import create from 'zustand';
import { persist } from 'zustand/middleware';
import { getCookie } from "../utils/getCookie";

const useProductsPriceTag = create(
    persist(
        (set, get) => ({
            products: [],
            addProduct: (params: any) => set((state: any) => ({ products: params })),            
            clearProduct: () => set((state: any) => ({products: []})),
        }),
        {
            name: 'products-to-print', 
        }
    )
// })
)

export default useProductsPriceTag;