import { Button, Modal, notification } from "antd";
import casherSale from "../../services/CasherSale";
import { useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import React, { useState } from "react";
import { NotificationPlacement } from "antd/es/notification/interface";
import { CasherSalePrinterModal } from "../CasherSalePrinterModal";

const Context = React.createContext({ name: 'Lojas Infor' });

interface IAddSaleProductModalProps {
    visible: boolean, handleCancel: Function
}

export const CasherUpdateStateConcluidoModal = (props: IAddSaleProductModalProps) => {
    const [api, contextHolder] = notification.useNotification();
    const { storeId, saleId } = useParams();    
    const queryClient = useQueryClient()    
    const [searchType, setSearchType] = useState("1");
    const [isVisibleSalePrinterModal, setIsVisibleSalePrinterModal] = useState(false);
    const [toPrint, setToPrint] = useState(false);
    
    const openNotificationSuccess = (placement: NotificationPlacement, description: string) => {    
        api.success({
          message: "Sucesso",
          description: description,
          placement,
          duration: 2
        });
    }

    const openNotificationError = (placement: NotificationPlacement, description: string) => {    
        api.error({
          message: "Erro",
          description: description,
          placement,
          duration: 2
        });
    }

    const handleOk = (state: string) => {        
        const param = {
            state: state,
        };
        
        casherSale.put_state(saleId, param)
            .then((res: any) =>{                
                queryClient.invalidateQueries(['currentSale', storeId, saleId]);
                queryClient.invalidateQueries(['currentSaleProducts', saleId]);           
                openNotificationSuccess("bottomRight", "Concluído com Sucesso." );
                setToPrint(true);
                onCancel();
            })
            .catch((error:any)=>{
                if(error.response?.data?.detail) {
                    openNotificationError("bottomRight", error.response?.data?.detail);
                }
                else{
                    openNotificationError("bottomRight", "Problema ao Atualizar Estado");
                }
            })
    };
    
    const onCancel = () => {        
        props.handleCancel();      
    };     

    return (
        <Context.Provider value={{name: "Geral"}}>
        {contextHolder}
        <Modal
            open={props.visible}
            title="Concluir"
            onOk={()=>handleOk("Concluído")}
            onCancel={onCancel}
            footer={[
            <Button type="link" key="back" onClick={()=>handleOk("A Enviar")}>
                A Enviar
            </Button>,   
            <Button type="link" key="back" onClick={()=>handleOk("A Retirar")}>
                A Retirar
            </Button>,                     
            <Button key="back" onClick={onCancel}>
                Voltar
            </Button>,            
            <Button type="primary" key="remove" onClick={()=>handleOk("Concluído")}>
                Concluir
            </Button>
            ]}
    >     
        Atualizar Venda Para Concluído?
        </Modal>
        <CasherSalePrinterModal visible={isVisibleSalePrinterModal} toPrint={toPrint} handleCancel={()=>{}}/>
        </Context.Provider>
    );
}