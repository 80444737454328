import { Alert, Button, Divider, Input, List, Modal, notification, Select, Space } from "antd";
import { LoadingOutlined, CaretRightOutlined, PlusOutlined } from '@ant-design/icons';
import casherSale from "../../services/CasherSale";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { NotificationPlacement } from "antd/es/notification/interface";
import UserService from "../../services/User";
import { ClientCreateModal } from "../ClientCreateModal";

const Context = React.createContext({ name: 'Lojas Infor' });

interface IAddSaleProductModalProps {
    visible: boolean, handleCancel: Function,
    origin?: "casher" | "sale"
}

interface IUserFound {
    id?:number,
    first_name?:string,
    auth_user_store_related_user?: any[]
}

export const CasherAddSaleModal = (props: IAddSaleProductModalProps) => {
    const [api, contextHolder] = notification.useNotification();
    const { storeId } = useParams();    
    const [loading, setLoading] = useState(false);    
    const [salerFounds, setSalerFounds] = useState<IUserFound[]>([]);
    const [buyerFound, setBuyerFound] = useState<IUserFound[]>([]);
    const [searchSaler, setSearchSaler] = useState<string>("");    
    const [searchBuyer, setSearchBuyer] = useState<string>("");    
    const refInputSearch =  useRef<any | null>(null);
    const [salerType, setSalerType] = useState("cod");
    const [buyerType, setBuyerType] = useState("whatsapp");
    const [salerSelected, setSalerSelected] = useState<IUserFound>();
    const navigate = useNavigate();
    const [isVisibleClientCreateModal, setIsVisibleClientCreateModal] = useState(false);

    useEffect(()=>{
        setSearchSaler("");
        setSalerType("cod");
        setSearchBuyer("");
        setBuyerType("whatsapp");
        setSalerFounds([]);
        setBuyerFound([]);
        setSalerSelected({});
    }, [props.visible])

    useEffect(()=>{
        if(searchBuyer.length > 10){
            searchUserBuyer();
        }
        else{
            setBuyerFound([]);
        }
    }, [buyerType]);
    
    const openNotificationSuccess = (placement: NotificationPlacement, description: string) => {    
        api.success({
          message: "Sucesso",
          description: description,
          placement,
          duration: 2
        });
    }

    const openNotificationError = (placement: NotificationPlacement, description: string) => {    
        api.error({
          message: "Erro",
          description: description,
          placement,
          duration: 2
        });
    }

    const handleOk = (user?: any) => {
        
        let param = {
            buyer_by_auth_user_id: buyerFound[0]?.id ? buyerFound[0]?.id : undefined,
            saler_by_auth_user_id: salerSelected?.id ? salerSelected?.id : undefined,
            client_level: buyerFound[0]?.id ? undefined : 1,            
        };
        
        setLoading(true);
        let storeIdA = storeId ? storeId : '';
        casherSale.post(storeIdA, param)
            .then((resCreate: any) => {                
                openNotificationSuccess("bottomRight", "Atualizado com Sucesso." );
                if(props?.origin && props.origin=="sale"){
                    navigate("/lojas/" + storeId + "/venda/" + resCreate?.data?.id);
                }
                else{
                    navigate("/lojas/" + storeId + "/caixa/venda/" + resCreate?.data?.id);
                }
                onCancel();
            })
            .catch((error:any)=>{
                if(error.response?.data?.detail) {
                    openNotificationError("bottomRight", error.response?.data?.detail);
                }
                else{
                    openNotificationError("bottomRight", "Problema ao Criar venda");
                }
            })
            .finally(()=>{
                setLoading(false);
            })
    };
    
    const onCancel = () => {        
        props.handleCancel();      
    };

    useEffect(()=>{
        if (searchSaler.length > 0) {
            searchUserSaler();
        }
    }, [searchSaler]);

    useEffect(()=>{
        if (searchBuyer.length > 10) {
            searchUserBuyer();
        }
        else{
            setBuyerFound([]);
        }
    }, [searchBuyer]);

    const searchUserBuyer = () => {
        if(searchBuyer.length > 10 && loading == false) {
            setLoading(true);         
                        
            let filter: any;
            if (buyerType == "whatsapp"){
                filter = {
                    auth_user_information_related__whatsapp_phone: searchBuyer,
                }
            }
            else{
                filter = {
                    auth_user_information_related__cpf: searchBuyer,                    
                }
            }           
            
            UserService.list(storeId, filter)
            .then((res: any) => {
                setBuyerFound(res.data.data);  
                if(res?.data?.data && res?.data?.data.length > 0){
                    const params = {
                        organization_store_id: parseInt(storeId ? storeId : ""),
                        auth_user_id: res.data.data[0].id,
                        client_level: 1,
                    }           
                    UserService.post_store(params)
                    .then((res: any) => {
                        openNotificationSuccess("bottomRight", "Cliente Cadastrado Na Loja");
                    })                
                }
            })
            .catch( (error: any) => {
                setBuyerFound([]);
                openNotificationError("bottomRight", "Cliente Não Encontrado");
            })
            .finally(() =>{
                setLoading(false);                    
            });
        }
    }

    const searchUserSaler = () => {
        if(searchSaler.length > 0 && loading == false) {
            setLoading(true); 
            setSalerSelected({});
                        
            let filter: any;
            if (salerType == "cod"){
                filter = {
                    auth_user_store_related_user__role: "Vendedor",
                    id: searchSaler
                }
            }
            else{
                filter = {
                    auth_user_store_related_user__role: "Vendedor",
                    first_name__unaccent__icontains: searchSaler
                }
            }           
            
            UserService.list(storeId, filter)
                .then((res: any) => {
                    setSalerFounds(res.data.data);
                })
                .catch( (error: any) => {
                    setSalerFounds([]);
                    // setLoading(false);
                })
                .finally(() =>{
                    setLoading(false);                    
                });
        }
    }

    const handleChangeSalerType = (value: string) =>{        
        setSalerType(value);        
    }

    const handleChangeBuyerType = (value: string) =>{        
        setBuyerType(value);        
    }

    const handleSalerSelected = (item: any) =>{
        setSalerSelected(item);
    }

    const disableConfirmation = () => {
        let toDisable: boolean = false;
        if(!buyerFound[0]){
            return false;
        }
        buyerFound[0]?.auth_user_store_related_user?.map((userStore: any)=>{
            if(userStore?.organization_store_id == storeId && userStore?.is_active_store == false){
                toDisable = true;
            }
        })
        return toDisable;
    }

    return (
        <Context.Provider value={{name: "Geral"}}>
        {contextHolder}
        <Modal
            open={props.visible}
            title="Nova Venda"
            onOk={handleOk}
            onCancel={onCancel}
            footer={[
            <Button type="link" key="remove" onClick={handleOk}>
                Voltar
            </Button>,
            <>
                <Button type="primary" key="back" onClick={handleOk} disabled={disableConfirmation()}>
                    Confirmar
                </Button>
            </>
            ]}
    >     
            <Divider>Cliente:</Divider>                    
            <Input.Group compact>                  
                <Select
                    size="large"
                    defaultValue="whatsapp"
                    style={{ width: "30%" }}
                    onChange={handleChangeBuyerType}     
                    value={buyerType}               
                    options={[
                        {
                        value: 'whatsapp',
                        label: 'Whatsapp',
                        },
                        {
                        value: 'cpf',
                        label: 'cpf',
                        },
                    ]}
                    />
                <Input ref={refInputSearch} size="large" style={{ width: '50%' }}  placeholder="Somente Números" value={searchBuyer} onChange={(e: any)=>setSearchBuyer(e.target.value)} autoComplete="off"/>                               
                {(buyerFound.length == 0 && searchBuyer.length > 10) &&
                    <Button size="large" style={{ width: '10%' }} icon={<PlusOutlined />} onClick={()=>setIsVisibleClientCreateModal(true)} />
                }
            </Input.Group>            

            <Divider>Vendedor:</Divider>                    
            
            <Input.Group compact>                  
                <Select
                    size="large"
                    defaultValue="cod"
                    style={{ width: "30%" }}
                    onChange={handleChangeSalerType}     
                    value={salerType}               
                    options={[
                        {
                        value: 'cod',
                        label: 'COD',
                        },
                        {
                        value: 'first_name',
                        label: 'Nome',
                        },
                    ]}
                    />
                <Input ref={refInputSearch} size="large" style={{ width: '50%' }}  placeholder="Somente Números" value={searchSaler} onChange={(e: any)=>setSearchSaler(e.target.value)} autoComplete="off" />               
            </Input.Group>
            <br />
            <br />

            {loading
                ? <LoadingOutlined />
                : <List
                    size="small"
                    header={<div>Vendedor: </div>}
                    bordered
                    dataSource={salerFounds}
                    locale={{ emptyText: <p>Não Encontrado</p> }}
                    renderItem={(item: any) => {
                        return <List.Item>
                            {item.id} | {item.first_name}
                            <Button style={{float: "right", marginTop: "-6px"}} onClick={()=>handleSalerSelected(item)}>
                            Selecionar<CaretRightOutlined />
                            </Button>
                        </List.Item>
                    }
                    }
                />
            }
            <br />
            <br />

            <Space>
                {buyerFound[0] &&
                    <>
                        <Alert message={buyerFound[0]?.first_name ?  "Cliente " + buyerFound[0]?.first_name : "Cliente Não Encontrado"} type="info" showIcon />
                        {buyerFound[0]?.auth_user_store_related_user?.map((userStore: any)=>
                            <>
                                {userStore?.organization_store_id == storeId && userStore?.is_active_store == false &&
                                    <Alert message="Cliente INATIVO" type="error" showIcon />
                                }
                            </>
                        )}
                    </>                    
                }
                {salerSelected &&
                    <Alert message={salerSelected?.first_name ?  "Vendedor " + salerSelected?.first_name : "Sem Vendedor"} type="info" showIcon />
                }

            </Space>
        </Modal>
        <ClientCreateModal visible={isVisibleClientCreateModal} handleCancel={()=>{setIsVisibleClientCreateModal(false); searchUserBuyer();}} setUser={setBuyerFound} />
        </Context.Provider>
    );
}