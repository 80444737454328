import { Button, Divider, Input, List, Modal, notification, Select } from "antd";
import { LoadingOutlined, CaretRightOutlined } from '@ant-design/icons';
import CasherSale from "../../services/CasherSale";
import { useNavigate, useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import React, { useEffect, useRef, useState } from "react";
import { NotificationPlacement } from "antd/es/notification/interface";
import { floatToMoneyFormat } from "../../utils/formatters";
import useUserLoggedStore from "../../store/user";
// import UserService from "../../services/User";

const Context = React.createContext({ name: 'Lojas Infor' });

interface IAddSaleProductModalProps {
    visible: boolean, handleCancel: Function,
    origin?: "casher" | "sale"
}

export const CasherSearchSaleModal = (props: IAddSaleProductModalProps) => {
    const [api, contextHolder] = notification.useNotification();
    const { storeId, saleId } = useParams();        
    const [loading, setLoading] = useState(false);    
    const [salesFounds, setSalesFounds] = useState([]);
    const [searchParam, setSearchParam] = useState<string>("");    
    const refInputSearch =  useRef<any | null>(null);
    const [searchType, setSearchType] = useState("Whatsapp");
    const user = useUserLoggedStore( (state: any) => state.user );
    const navigate = useNavigate();

    useEffect(()=>{
        if(searchParam.length > 0 || searchType=="Minhas Vendas"){
            searchUser();
        }
    }, [searchType])
    
    const openNotificationSuccess = (placement: NotificationPlacement, description: string) => {    
        api.success({
          message: "Sucesso",
          description: description,
          placement,
          duration: 2
        });
    }

    const openNotificationError = (placement: NotificationPlacement, description: string) => {    
        api.error({
          message: "Erro",
          description: description,
          placement,
          duration: 2
        });
    }

    const handleOk = (sale?: any) => {        
        openNotificationSuccess("bottomRight", "Direcinando Para Venda.")
        if(props?.origin && props.origin=="sale"){
            navigate("/lojas/"+storeId+"/venda/"+sale.id);
        }
        else{
            navigate("/lojas/"+storeId+"/caixa/venda/"+sale.id);    
        }
        
        props.handleCancel();
    };
    
    const onCancel = () => {        
        props.handleCancel();      
        setSalesFounds([]);
        setSearchType("Whatsapp");
        setSearchParam("");
    };
    
    const handleSearch = async (e: any) => {
        setSearchParam(e.target.value);
    }

    useEffect(()=>{
        if (searchParam.length > 0) {
            searchUser();
        }
    }, [searchParam]);

    const searchUser = () => {
        if((searchParam.length > 0 || searchType == "Minhas Vendas")) {        
            setLoading(true); 
                        
            let filter: any;
            if (searchType == "Whatsapp"){
                filter = {
                    buyer_by_auth_user__auth_user_information_related__whatsapp_phone: searchParam
                }
            }
            else if (searchType == "CPF"){
                filter = {
                    buyer_by_auth_user__auth_user_information_related__cpf: searchParam
                }
            }
            else if (searchType == "CNPJ"){
                filter = {
                    buyer_by_auth_user__auth_user_information_related__cnpj: searchParam
                }
            }
            else if (searchType == "Minhas Vendas"){
                filter = {
                    inserted_by_auth_user_id: user.id
                }
            }
            else{
                filter = {
                    id: searchParam
                }
            }          
            
            CasherSale.list(storeId ? storeId : "0", filter)
            .then((res: any) => {
                setSalesFounds(res.data.data);
            })
            .catch( (error: any) => {
                setSalesFounds([])
                // setLoading(false);
            })
            .finally(() =>{
                setLoading(false);                    
            });
        }
    }

    const handleChange = (value: string) =>{        
        setSearchType(value);        
    }

    return (
        <Context.Provider value={{name: "Geral"}}>
        {contextHolder}
        <Modal
            open={props.visible}
            title="Buscar Venda"
            onOk={handleOk}
            onCancel={onCancel}
            footer={[
            <Button key="back" onClick={onCancel}>
                Voltar
            </Button>,
            ]}
    >     
            <Input.Group compact>                  
                <Select
                    size="large"
                    defaultValue="Whatsapp"
                    style={{ width: "30%" }}
                    onChange={handleChange}     
                    value={searchType}               
                    options={[
                        {
                        value: 'Código',
                        label: 'Código',
                        },
                        {
                        value: 'Whatsapp',
                        label: 'Whatsapp',
                        },
                        {
                        value: 'CPF',
                        label: 'CPF',
                        },
                        {
                        value: 'CNPJ',
                        label: 'CNPJ',
                        },
                        {
                        value: 'Minhas Vendas',
                        label: 'Minhas Vendas',
                        },
                    ]}
                    />
                <Input ref={refInputSearch} size="large" style={{ width: '50%' }}  placeholder="Somente Números" value={searchParam} onChange={handleSearch} disabled={searchType=="Minhas Vendas"} autoComplete="off"/>               
            </Input.Group>

            <Divider>Resultado:</Divider>                    
            {loading
                ? <LoadingOutlined />
                : <List
                    size="small"
                    header={<div>Cliente: </div>}
                    bordered
                    dataSource={salesFounds}
                    locale={{ emptyText: <p>Não Encontrado</p> }}
                    renderItem={(item: any) => {
                        return <List.Item>
                            {item.id} | {item.state} | {item?.buyer_by_auth_user ? item?.buyer_by_auth_user?.first_name : "Não Identificado"} 
                            | {floatToMoneyFormat(item.total_to_receive, 2)} / Pago {floatToMoneyFormat(item.total_received, 2)}
                            <Button style={{float: "right", marginTop: "-6px"}} onClick={()=>handleOk(item)} icon={<CaretRightOutlined />} />
                        </List.Item>
                    }
                    }
                />
            }
        </Modal>
        </Context.Provider>
    );
}