import { Button, Typography, Modal,notification } from "antd";
import { LoadingOutlined, BarcodeOutlined } from '@ant-design/icons';
import casherSale from "../../services/CasherSale";
import { useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import React, { useEffect, useState } from "react";
import { NotificationPlacement } from "antd/es/notification/interface";

const Context = React.createContext({ name: 'Lojas Infor' });

interface IAddSaleProductModalProps {
    visible: boolean, handleCancel: Function
}

const {Text} = Typography;

export const CasherRemoveAdvancedModal = (props: IAddSaleProductModalProps) => {
    const [api, contextHolder] = notification.useNotification();
    const { saleId } = useParams();    
    const queryClient = useQueryClient();
    const [loading, setLoading] = useState(false);     
    
    const openNotificationSuccess = (placement: NotificationPlacement, description: string) => {    
        api.success({
          message: "Sucesso",
          description: description,
          placement,
          duration: 2
        });
    }

    const openNotificationError = (placement: NotificationPlacement, description: string) => {    
        api.error({
          message: "Erro",
          description: description,
          placement,
          duration: 2
        });
    }

    const handleOk = () => {
        casherSale.delete_by_pass(saleId)
            .then((res: any) =>{                
                queryClient.invalidateQueries('currentSale');                
                openNotificationSuccess("bottomRight", "Autorizado com Sucesso." );
                onCancel();
            })
            .catch((error:any)=>{
                openNotificationError("bottomRight", "Problema ao autorizar");
            })
    };
    
    const onCancel = () => {        
        props.handleCancel();        
    };
    

    return (
        <Context.Provider value={{name: "Geral"}}>
        {contextHolder}
        <Modal
            visible={props.visible}
            title="Desativar Permição Avançada"
            onOk={handleOk}
            onCancel={onCancel}
            footer={[
                <Button key="back" onClick={onCancel}>
                    Voltar
                </Button>,
                <Button type="primary" key="Confirmar" onClick={handleOk}>
                    Remover Permissão
                </Button>,
            ]}
    >                      
            {loading &&
                <LoadingOutlined />
            }
            {!loading &&
                <Text>
                    Deseja Remover a Permissão Avançada Dessa Venda?
                </Text>
            }
        </Modal>
        </Context.Provider>
    );
}