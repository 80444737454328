import {  useEffect } from 'react';
import { Card, Layout } from 'antd';
import { EcommStoreHeader } from '../../components/EcommStoreHeader';
import { EcommProductList } from '../../components/EcommProductList';
import { EcommCheckoutDrawer } from '../../components/EcommCheckoutDrawer';

const { Footer } = Layout;

const EcommStore = () => {        

    useEffect(() => {
        document.title = 'Ecommerce - Lojas Infor';
    }, []);

    return(       
        <>
        <EcommStoreHeader />
        <EcommProductList />  
        <EcommCheckoutDrawer />
        <Footer style={{textAlign: "center"}}>Todos os Direitos Reservados</Footer>
        </>     
    ); 
}
  
export default EcommStore;