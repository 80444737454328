import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { IAPIProductRes } from "../../services/Ecomm/ecomm.interfaces";
import EcommService from "../../services/Ecomm";
import { Button, Card, Col, Layout, notification, Pagination, Row, Tag, Typography } from "antd";
import { formatter_money_br } from "../../utils/formatters";
import useCheckoutDrawer from "../../store/useCheckoutDrawer";
import { NotificationPlacement } from "antd/es/notification/interface";
import Authentication from "../../services/Authentication";
import { useUserStoreMe } from "../../store/useUserStoreMe";

const MAX_PROD_PER_PAG = 20;
const { Content, Sider } = Layout;
const { Meta } = Card;
const { Text } = Typography;
const Context = React.createContext({ name: 'Lojas Infor' });

export const EcommProductList = () => {
    const dataFetchedRef = useRef(false);
    const { storeId } = useParams();  
    const [searchParams, setSearchParams] = useSearchParams();
    const pag = searchParams.get("pag");
    const search = searchParams.get("search");
    const dep = searchParams.get("dep");
    const cat = searchParams.get("cat");
    const sub_cat = searchParams.get("sub_cat");
    const [api, contextHolder] = notification.useNotification();
    const [products, setProducts] = useState<IAPIProductRes[] | undefined>();
    const [total, setTotal] = useState(0);
    const navigate = useNavigate();
    const openCheckoutDrawer = useCheckoutDrawer( (state: any) => state.open );
    const [currentStore, setCurrentStore] = useState();
    const userStoreMe = useUserStoreMe(storeId);

    const listProducts = () =>{
        let filter = {
            organization_store_id: storeId ? storeId : undefined,
            active_ecomm: true,
            search: search ? search : undefined,
            iventory_sub_category_id: sub_cat ? sub_cat : undefined,
            iventory_sub_category__iventory_category_id: cat ? cat : undefined,
            iventory_sub_category__iventory_category__iventory_department_id: dep ? dep : undefined,
        }

        let limit = MAX_PROD_PER_PAG;
        let current_pag_int = parseInt(pag ? pag : "1")
        let offset = MAX_PROD_PER_PAG*( current_pag_int > 1 ? (current_pag_int-1) : 0);

        EcommService.list_products(filter, limit, offset)
            .then((resProd: any)=>{        
                setProducts(resProd.data.data);
                setTotal(resProd.data.total);
                if((resProd.data.total/MAX_PROD_PER_PAG) < parseInt(pag ? pag : "") ){   
                    if(resProd.data.total/MAX_PROD_PER_PAG < 1){
                        navigate("/loja/"+ storeId + "?pag=1&" + (search ? "search=" + search: ""));
                    }               
                    else{
                        navigate("/loja/"+ storeId + "?pag="+Math.ceil(resProd.data.total/MAX_PROD_PER_PAG) + "&" + (search ? "search=" + search: ""));
                    }                    
                }
            })
            .catch((error: any)=>{
                if (error.response.status == 401){
                    
                }
                else{

                }                
            });
            ;
    }

    useEffect(()=>{
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        Authentication.get_stores()
            .then((resStore: any)=>{   
                resStore?.data?.data?.map((item: any)=>{
                    if(item.id = storeId){
                        console.log(item);
                        setCurrentStore(item);
                    }
                })                     
            })                             
        listProducts();
    }, []);

    useEffect(()=>{
        listProducts();
    }, [storeId]);
    
    useEffect(()=>{
        listProducts();
    }, [search]);

    useEffect(()=>{
        listProducts();
    }, [sub_cat]);

    useEffect(()=>{
        listProducts();
    }, [cat]);

    useEffect(()=>{
        listProducts();
    }, [dep]);
    
    useEffect(()=>{
        listProducts();
    }, [pag]);

    const onChangePagination = (pag: any) =>{        
        navigate("/loja/" + storeId + "?pag="+pag+"&"+(search ? "search=" + search: ""))
    }

    const addProduct = async (product: any) => {        
        EcommService.get_carrinho()
            .then(async (resCarrinho: any)=>{  
                let carrinho_id_founded;      
                resCarrinho?.data?.data.map((carrinho: any) => {                    
                    if(carrinho.organization_store_id == product.store.id){
                        carrinho_id_founded=carrinho.id;
                    }
                });
                if(carrinho_id_founded==null){
                    await EcommService.post_carrinho(product.store.id)
                        .then((resACarrinhoAdd: any)=>{
                            carrinho_id_founded = resACarrinhoAdd?.data?.id;
                        })
                        .catch((error: any)=>{
                            if (error.response.status == 401){
                                let placement: NotificationPlacement = "bottomLeft";
                                api.error({
                                    message: "Erro ao Adicionar Carrinho",
                                    description: <Context.Consumer>{()=>'Não Autorizado.'}</Context.Consumer>,
                                    placement,
                                });
                            }
                            else{
                                let placement: NotificationPlacement = "bottomLeft";
                                api.error({
                                    message: "Erro ao Adicionar Produto",
                                    description: <Context.Consumer>{()=>'Erro ao Adicionar Carrinho.'}</Context.Consumer>,
                                    placement,
                                });
                            }                
                        });
                }
                
                let param_prod = {
                    quantity: 1,
                    iventory_product_id: product.id,
                }
                EcommService.post_product(carrinho_id_founded, param_prod)
                    .then((resProd: any)=>{
                        let placement: NotificationPlacement = "bottomLeft";
                            api.success({
                                message: "Produto Adicionado",
                                description: <Context.Consumer>{()=>'Produto Adicionado no Carrinho.'}</Context.Consumer>,
                                placement,
                            });
                        openCheckoutDrawer();
                    })
                    .catch((error: any)=>{
                        if (error.response.status == 401){
                            let placement: NotificationPlacement = "bottomLeft";
                            api.error({
                                message: "Erro ao Adicionar Produto",
                                description: <Context.Consumer>{()=>'Não Autorizado.'}</Context.Consumer>,
                                placement,
                            });
                        }
                        else{
                            let placement: NotificationPlacement = "bottomLeft";
                            api.error({
                                message: "Erro ao Adicionar Produto",
                                description: <Context.Consumer>{()=>'Erro ao Adicionar Produto no Carrinho.'}</Context.Consumer>,
                                placement,
                            });
                        }                
                    });  
            })
            .catch((error: any)=>{
                if (error.response.status == 401){
                    let placement: NotificationPlacement = "bottomLeft";
                    api.error({
                        message: "Erro ao Adicionar Produto",
                        description: <Context.Consumer>{()=>'Não Autorizado.'}</Context.Consumer>,
                        placement,
                    });
                }
                else{
                    let placement: NotificationPlacement = "bottomLeft";
                    api.error({
                        message: "Erro ao Adicionar Produto",
                        description: <Context.Consumer>{()=>'Erro ao Buscar Carrinho.'}</Context.Consumer>,
                        placement,
                    });
                }                
            });
            ;
    }

    return(
        <Context.Provider value={{ name: 'Lojas Infor' }}>
        {contextHolder}
    <Layout style={{paddingTop: "24px"}}>                 
            {/* <Sider width={200} className="product-list-sider">
                Filtros
            </Sider> */}
            <Content style={{ padding: '0 24px' }}>          
                    {products && 
                        <Row  gutter={[8, 16]}>                    
                            {products.map((prod: IAPIProductRes) => {
                                return <Col xs={24} md={6}>
                                        <Card
                                            hoverable
                                            style={{ marginBottom: "8px"}}
                                            cover={<img alt="example" src={prod.photo_main ? prod.photo_main : "/erro"} style={{width: "100%", objectFit: "contain",  maxHeight: "220px"}} onError={(e: any) => {
                                                e.target.onerror = null; // prevents looping
                                                e.target.src="/no_image.jpg";
                                              }} />}
                                            >
                                            <Meta title={                                                
                                                <>
                                                <>
                                                    {formatter_money_br.format(prod.price)}                                                     
                                                </>
                                                {userStoreMe?.data?.client_level && userStoreMe?.data?.client_level > 1 && 
                                                    <>{prod?.discount_level?.map((disc_level: any)=>{
                                                        if(disc_level?.client_level <= userStoreMe?.data?.client_level){
                                                            return <>
                                                                <br/>
                                                                <Tag color="magenta">Nível {disc_level?.client_level} -{disc_level?.percent_discount}%</Tag>                                                                
                                                                <Text type="success">{formatter_money_br.format(prod.price * (100 - disc_level?.percent_discount)/100)}</Text>
                                                                </>
                                                        }
                                                    })}
                                                    </>                                                                                                    
                                                }
                                                <Button style={{float:"right"}} 
                                                    onClick={()=>addProduct(prod)}>+</Button>
                                                </>
                                            } description={
                                                <>{prod.code + " " + prod.name}</>
                                            } />
                                        </Card>
                                        </Col>
                            })}
                        </Row>
                    }
                    <Row style={{justifyContent: "center", margin: "20px 0"}}>
                        <Pagination current={pag ? parseInt(pag) : 1} total={total} onChange={onChangePagination} defaultPageSize={MAX_PROD_PER_PAG} showSizeChanger={false}/>
                    </Row>
                </Content>            
            </Layout> 
    </Context.Provider>

    )
}