import { Alert, Button, Col, Divider, Input, InputNumber, Modal, notification, Row, Space, Statistic, Tag, Typography } from "antd";
import {FireOutlined, CheckOutlined, MinusCircleOutlined } from '@ant-design/icons';
import casherSale from "../../services/CasherSale";
import { useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import React, { useState, useEffect } from "react";
import { NotificationPlacement } from "antd/es/notification/interface";
import { moneyToNumber, moneyMask, floatToMoneyFormat } from "../../utils/formatters";
import { useCurrentSale } from "../../store/useCurrentSale";
import { useCurrentSaleProducts } from "../../store/useCurrentSaleProduct";

const Context = React.createContext({ name: 'Lojas Infor' });

const {Text} = Typography;

interface IAddSaleProductModalProps {
    visible: boolean, handleCancel: Function, product?: any 
}

export const CasherUpdateSaleGeneralDiscountModal = (props: IAddSaleProductModalProps) => {
    const [api, contextHolder] = notification.useNotification();
    const { storeId, saleId } = useParams();    
    const queryClient = useQueryClient();
    const [discountValue, setDiscountValue] = useState(0.00);    
    const [discountPercent, setDiscountPercent] = useState(0);
    const [discountValueToReceive, setDiscountValueToReceive] = useState(0);
    const [discountPercentToReceive, setDiscountPercentToReceive] = useState(0);
    const [discountPercentGeneral, setDiscountPercentGeneral] = useState(0);    
    const [totalValueWithDiscount, setTotalValueWithDiscount] = useState(0.00);
    const [alreadyManualDisc, setAlreadyManualDisc] = useState(false);
    const [notes, setNotes] = useState("");
    const currentSale = useCurrentSale(storeId, saleId);
    const currentSaleProducts = useCurrentSaleProducts(saleId);

    
    const getPercentPartOfAll = (part_number: number, total_number: number) => {
        return (( part_number*100)/total_number );
    };

    useEffect(()=>{  
        setDiscountPercent(getPercentPartOfAll((currentSale?.data?.total_discount ? currentSale?.data?.total_discount : 0), (currentSale?.data?.sub_total_without_discount ? currentSale?.data?.sub_total_without_discount : 0)));        
        setDiscountValue((currentSale?.data?.total_discount ? currentSale?.data?.total_discount : 0))
        setTotalValueWithDiscount((currentSale?.data?.total_to_receive ? currentSale?.data?.total_to_receive : 0 ));
    }, [currentSale?.data]);

    useEffect(()=>{
        for (let i = 0; i < (currentSaleProducts?.data?.length ? currentSaleProducts?.data?.length : 0); i++) {            
            if(currentSaleProducts && currentSaleProducts?.data && currentSaleProducts?.data[i].is_canceled==false && currentSaleProducts?.data[i].manual_percentage_discount > 0){
                setAlreadyManualDisc(true);
                break;
            }
            else{
                setAlreadyManualDisc(false);                
            }
        }
    }, [currentSaleProducts?.data]);

    const openNotificationSuccess = (placement: NotificationPlacement, description: string) => {    
        api.success({
          message: "Sucesso",
          description: description,
          placement,
          duration: 2
        });
    }

    const openNotificationError = (placement: NotificationPlacement, description: string) => {    
        api.error({
          message: "Erro",
          description: description,
          placement,
          duration: 2
        });
    }

    const handleOk = (user: any) => {        
        const param = {
            manual_percentage_discount: parseFloat(discountPercentGeneral.toFixed(3)),
        }        
        
        casherSale.put_general_discount(saleId, param)
            .then((res: any) =>{                
                queryClient.invalidateQueries(['currentSale', storeId, saleId]);       
                queryClient.invalidateQueries(['currentSaleProducts', saleId]);
                queryClient.invalidateQueries(['currentSaleTransactions', saleId]);
                queryClient.invalidateQueries(['casherOpen', storeId]);        
                openNotificationSuccess("bottomRight", "Atualizado com Sucesso." );
                onCancel();
            })
            .catch((error:any)=>{
                queryClient.invalidateQueries(['currentSale', storeId, saleId]);       
                queryClient.invalidateQueries(['currentSaleProducts', saleId]);
                queryClient.invalidateQueries(['currentSaleTransactions', saleId]);
                queryClient.invalidateQueries(['casherOpen', storeId]);
                if(error.response?.data?.detail) {
                    openNotificationError("bottomRight", error.response?.data?.detail);
                }
                else{
                    openNotificationError("bottomRight", "Problema ao Atualizar Desconto Geral");
                }
            })
    };
    
    const onCancel = () => {        
        props.handleCancel();    
        setDiscountPercentToReceive(0);
        setDiscountValueToReceive(0.00);
        setDiscountPercentGeneral(0);
    };    

    // Ipdates manual percent, value and total
    const handleDiscountValueToReceive = (param: string) =>{  
        // moviment the numbe 
        let value = moneyToNumber(param);
        if(value > (currentSale?.data?.total_to_receive ? currentSale?.data?.total_to_receive : 0)){
            value=(currentSale?.data?.total_to_receive ? currentSale?.data?.total_to_receive : 0);                        
            openNotificationError("bottomRight", "Desconto Maior Que o Preço.");
        }
        else if(value < 0){
            openNotificationError("bottomRight", "Desconto Menor Que Zero.");
            value=0;
        }
       
        let percent_new_value_about_price = (
            (value*100)/(currentSale?.data?.total_to_receive ? currentSale?.data?.total_to_receive : 0)
        )
        
        handleDiscountPercentToReceive(percent_new_value_about_price);    
        setDiscountValueToReceive(value)
    }

    const handleDiscountPercentToReceive = (value: number) =>{  
        let disc_percent;
        if(value<0){
            disc_percent = 0;
            openNotificationError("bottomRight", "Percentual Precisa ser Maior que 0%.");
        }
        else if(value>100){
            disc_percent = 100 - props?.product?.automatic_percentage_discount;
            openNotificationError("bottomRight", "Percentual Precisa ser Menor que 100%.");
        }
        else if(!value){
            disc_percent = 0;
        }
        else{
            disc_percent = value;
        }
        setDiscountPercentToReceive(parseFloat(disc_percent.toFixed(2)));        
          
        let value_discount = (
            (disc_percent/100)*(currentSale?.data?.total_to_receive ? currentSale?.data?.total_to_receive : 0)
        );

        
        setDiscountValueToReceive(value_discount);        
        
        setTotalValueWithDiscount(            
                (currentSale?.data?.total_to_receive ? currentSale?.data?.total_to_receive : 0) 
                - value_discount
        );     
        setDiscountPercentGeneral(getPercentPartOfAll(value_discount, (currentSale?.data?.sub_total_without_discount ? currentSale?.data?.sub_total_without_discount : 0)));
    }

    
    return (
        <Context.Provider value={{name: "Geral"}}>
        {contextHolder}       
        <Modal
            open={props.visible}
            title="Atualizar Desconto Geral da Venda"
            onOk={handleOk}
            onCancel={onCancel}
            footer={[,
            <Button key="back" onClick={onCancel}>
                Voltar
            </Button>,            
            <Button type="primary" key="remove" onClick={handleOk}>
                Confirmar
            </Button>
            ]}
    >               
             {alreadyManualDisc==false ?
             <>
            <Row style={{ marginBottom: 20 }} justify="space-between">                
                <Statistic title="Sub Total C/ Desconto Aplicado:" value={floatToMoneyFormat(currentSale?.data?.total_to_receive, 2)} />                                    
            </Row> 
            <Divider />           
            <Row style={{ marginBottom: 20 }}>                
                <Col span={12}>
                    <Text>Desconto Manual(R$)</Text>                    
                    <Input size="large" style={{ width: '50%' }}  placeholder="R$ 0,00" 
                            value={floatToMoneyFormat(discountValueToReceive, 3)} 
                            onChange={(e: any)=>{handleDiscountValueToReceive(moneyMask(e.target.value, 3))}} 
                    />
                </Col>
                <Col span={12}>
                    <Text>Desconto Manual(%)</Text>                    
                    <InputNumber size="large" style={{ width: '50%' }}  
                            placeholder="0" max={100} 
                            min={0}
                            value={discountPercentToReceive} 
                            onChange={(e: any)=>{handleDiscountPercentToReceive(e)}} 
                    />
                </Col>                            
            </Row>
            <Row>
                <Col span={12}>      
                    <Row>
                        <Statistic title="Total Atualizado(R$):" 
                            value={floatToMoneyFormat(totalValueWithDiscount, 2)} />
                    </Row>
                </Col>
            </Row>
            <Row style={{marginTop: 30}}>
                <Statistic title="Desconto De:" value={discountPercent?.toFixed(2) + "%" + "[AUT] + " + discountPercentGeneral.toFixed(2) + "%[MAN]"} valueStyle={{ color: 'magenta' }} /> 
                <Space>
                    <Alert message={"Atenção, o desconto geral irá sobrescrever o desconto manual geral!"} type="warning" showIcon />                
                </Space>
                {totalValueWithDiscount < (currentSale?.data?.cost_total_value ? currentSale?.data?.cost_total_value : 0) &&
                    <Space>
                        <Alert message={"Novo Preço Menor Que Valor de Custo"} type="error" showIcon />                
                    </Space>
                }
                {(totalValueWithDiscount < ((currentSale?.data?.cost_total_value ? currentSale?.data?.cost_total_value : 0)/0.9)) &&
                    <Space>
                        <Alert message={"Atenção, Politica de Desconto"} type="warning" showIcon />                
                    </Space>
                }
                <Input size="large" onChange={(e:any)=>setNotes(e.target.value)} value={notes} placeholder="Descrição"/>
            </Row>         
            </>   
            :            
            <Text>Desconto manual já adicionado em um ou mais produtos dessa venda. 
                <Alert message={"Gostaria de remover todos os descontos manuais?"} type="error" showIcon />
            </Text>
            }
        </Modal>
        
        </Context.Provider>
    );
}