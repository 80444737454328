import { useQuery } from "react-query";
import CasherService from "../services/Casher";
import { IAPICasherSaleRes } from "../services/CasherSale/casherSale.interfaces";

const fetchCurrentSale = (storeId: string | undefined): Promise<IAPICasherSaleRes> => {
    return CasherService.get_open(storeId)
            .then( (res: any) => {
                return res.data;
            })
            .catch( (error: any) => {
                return null
            })
};

const useCasherOpen = (storeId: string | undefined) => {
    return useQuery(["casherOpen", storeId], ()=>fetchCurrentSale(storeId));
}

export {useCasherOpen}