import { CloseOutlined, GiftOutlined, SwapOutlined, GlobalOutlined, PlusOutlined, ShoppingOutlined, LockOutlined, UnlockOutlined, EllipsisOutlined, PauseOutlined, CheckOutlined } from '@ant-design/icons';
import { Col, Layout, Row, Divider, Button, Modal, Typography, Dropdown, Space, Checkbox, notification, Spin, Alert } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CasherService from '../../services/Casher';
import casherSale from '../../services/CasherSale';
import "./styles.scss";
import { CasherFooterBar } from '../../components/CasherFooterBar';
import { useQueryClient } from 'react-query';
import { useCurrentSale } from '../../store/useCurrentSale';
import { useCasherOpen } from '../../store/useCasherOpen';
import { barcodeListener } from '../../utils/barcodeListener';
import { CasherAddSaleProductModal } from '../../components/CasherAddSaleProductModal';
import useBarcode from '../../store/useBarcode';
import { CasherProductsTable } from '../../components/CasherProductsTable';
import { CasherUpdateAdvancedModal } from '../../components/CasherUpdateAdvancedModal';
import { CasherRemoveAdvancedModal } from '../../components/CasherRemoveAdvancedModal';
import { CasherTransactionList } from '../../components/CasherTransactionList';
import { CasherUpdateBuyerModal } from '../../components/CasherUpdateBuyerModal';
import { CasherUpdateLevelModal } from '../../components/CasherUpdateLevelModal';
import type { MenuProps } from 'antd';
import { CasherAddSaleModal } from '../../components/CahserAddSaleModal';
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { NotificationPlacement } from "antd/es/notification/interface";
import { CasherUpdateStatePausaModal } from '../../components/CasherUpdateStatePausaModal';
import { CasherUpdateStateCanceladoModal } from '../../components/CasherUpdateStateCanceladoModal';
import { StoreHeader } from '../../components/StoreHeader';
import { useLocalTerminal } from '../../store/useLocalTerminal';
import { CasherUpdateSaleGeneralDiscountModal } from '../../components/CasherUpdateSaleGeneralDiscountModal';
import { CasherSearchSaleModal } from '../../components/CasherSearchSaleModal';

const Context = React.createContext({ name: 'Lojas Infor' });
const { Sider, Content, Header } = Layout;
const { Text, Title } = Typography;
  
const CasherPage = (props: any) => {
    const barcode = useBarcode( (state: any) => state.barcode );
    const addBarcode = useBarcode( (state: any) => state.addBarcode );
    const clearBarcode = useBarcode( (state: any) => state.clearBarcode );
  
    const [terminal, setTerminal] = useState({id:null, name: null});
    const [loadingOpenCasher, setLoadingOpenCasher] = useState(false);
    const { storeId } = useParams();
    const { saleId } = useParams();
    const navigate = useNavigate();
    const dataFetchedRef = useRef(false);
    
    const [isVisibleAddSaleModal, setIsVisibleAddSaleModal] = useState(false);
    const [isVisibleUpdateAdvancedModal, setIsVisibleUpdateAdvancedModal] = useState(false);
    const [isVisibleUpdateBuyerModal, setIsVisibleUpdateBuyerModal] = useState(false);
    const [isVisibleAddSaleProductModal, setIsVisibleAddSaleProductModal] = useState(false);
    const [isVisibleRemoveAdvancedModal, setIsVisibleRemoveAdvancedModal] = useState(false);  
    const [isVisibleUpdateLevelModal, setIsVisibleUpdateLevelModal] = useState(false);  
    const [isVisiblePausaModal, setIsVisiblePausaModal] = useState(false);  
    const [isVisibleCancelarModal, setIsVisibleCancelarModal] = useState(false);
    const [isVisibleGeneralDiscountModal, setIsVisibleGeneralDiscountModal] = useState(false);
    const [isVisibleSearchSaleModal, setIsVisibleSearchSaleModal] = useState(false);

    const [productAddQtd, setProductAddQtd] = useState(1);
    const [modalMoreInforOpen, setModalMoreInforOpen] = useState(false);
    const currentSale = useCurrentSale(storeId, saleId);
    const casherOpen = useCasherOpen(storeId);
    const localTerminal = useLocalTerminal(storeId);

    const queryClient = useQueryClient(); 
    const [api, contextHolder] = notification.useNotification();  

    const loadCurrentSale = () => {
        queryClient.invalidateQueries(['currentSale', storeId, saleId]);
        queryClient.invalidateQueries(['currentSaleProducts', saleId]);
    }

    const handleCancelCasherAddSaleProductModal = () => {
        console.log("Handle Cancel")
        setIsVisibleAddSaleProductModal(false);
        setProductAddQtd(1);        
        clearBarcode();
    }

    const handleCancelUpdateAdvancedModal = () => {
        setIsVisibleUpdateAdvancedModal(false);
        clearBarcode();
    }

    useEffect(() => {
        document.title = 'Caixa - Lojas Infor';
    }, []);
    
    useEffect(() => {
        if (dataFetchedRef.current) return;        
        dataFetchedRef.current = true;

        barcodeListener(handleBarcodeFounded);
        clearBarcode();
      
        if(saleId == undefined){            
            let storeIdA = storeId ? storeId : '';
            casherSale.get_carrinho(storeIdA)
            .then((resSale: any) => {
                navigate("/lojas/" + storeId + "/caixa/venda/" + resSale.data.data.id);
            })
            .catch( (error: any) => {
                // setCurrentSaleCarrinho({id:null});
            });
        }
        else if(saleId) {
            loadCurrentSale();
        }        
    }, []);
    
    const handleToOpenCasher = async () =>{                
        
        CasherService.get_open(storeId)
            .then((response: any)=>{
                queryClient.invalidateQueries(["casherOpen", storeId]);
            })
            .catch( (error: any) => {
                CasherService.to_open({
                    payments_terminal_id: localTerminal?.data?.id ? localTerminal?.data?.id : 0
                })
                   .then((resCasherOpen: any) => {
                        openNotificationSuccess("bottomRight", "Caixa Aberto com Sucesso.");
                        queryClient.invalidateQueries(["casherOpen", storeId]);
                   })
                   .catch((error:any)=>{
                        if(error?.response?.data?.detail) {
                            openNotificationError("bottomRight", error.response?.data?.detail);
                        }
                        else{
                            openNotificationError("bottomRight", "Problema ao Abrir Caixa.");
                        }
                   })
            })
            .finally(()=>{
                setLoadingOpenCasher(false);
            })    
        
    }

    const handleBarcodeFounded = (barcodeFounded: string) =>{        
        addBarcode(barcodeFounded);
        if(isVisibleAddSaleProductModal == false && isVisibleUpdateAdvancedModal == false){
            setIsVisibleAddSaleProductModal(true);
        }
    }       
    
    const openNotificationSuccess = (placement: NotificationPlacement, description: string) => {    
        api.success({
          message: "Sucesso",
          description: description,
          placement,
          duration: 2
        });
    }

    const openNotificationError = (placement: NotificationPlacement, description: string) => {    
        api.error({
          message: "Erro",
          description: description,
          placement,
          duration: 2
        });
    }

    const onChangeEcommerce = (e: CheckboxChangeEvent, paramType: string) => {
        let param;
        
        if (paramType=="ecomm"){
            param = {
                ecomm: e.target.checked,
            }
        }
        else if(paramType=="exchange"){
            param = {
                exchange: e.target.checked,
            }
        }
        else{
            param = {
                gift: e.target.checked,
            }
        }
        
        casherSale.put(saleId, param)
            .then((res: any) =>{                
                queryClient.invalidateQueries(['currentSale', storeId, saleId]);
                queryClient.invalidateQueries(['currentSaleProducts', saleId]);           
                openNotificationSuccess("bottomRight", "Atualizado com Sucesso." );                
            })
            .catch((error:any)=>{
                if(error.response?.data?.detail) {
                    openNotificationError("bottomRight", error.response?.data?.detail);
                }
                else{
                    openNotificationError("bottomRight", "Problema ao Atualizar Nível");
                }
            })
    };
    
    const items: MenuProps['items'] = [
        {
          key: '1',
          label: (
            <Button type="link">
                <Checkbox onChange={(e: CheckboxChangeEvent)=>onChangeEcommerce(e, "ecomm")} checked={currentSale?.data?.ecomm ? true : false}><GlobalOutlined /> Ecommerce</Checkbox>
            </Button>
          ),
        },
        {
          key: '2',
          label: (
            <Button type="link">
                <Checkbox onChange={(e: CheckboxChangeEvent)=>onChangeEcommerce(e, "exchange")} checked={currentSale?.data?.exchange ? true : false}><SwapOutlined /> Troca</Checkbox>
            </Button>
          ),
        },
        {
          key: '3',
          label: (
            <Button type="link">
                <Checkbox onChange={(e: CheckboxChangeEvent)=>onChangeEcommerce(e, "gift")} checked={currentSale?.data?.gift ? true : false}><GiftOutlined /> Brinde</Checkbox>
            </Button>
          ),
        },
        {
            key: '4',
            label: (
            <Button type="link" onClick={()=>setIsVisiblePausaModal(true)} disabled={currentSale?.data?.state=="Carrinho de Compras"?false:true}>
                <PauseOutlined /> Pausar
            </Button>
            ),
        },
        {
            key: '5',
            label: (
            <Button type="link" onClick={()=>setIsVisibleCancelarModal(true)} style={currentSale?.data?.state!="Cancelado"?{color: "red"}:{}} disabled={currentSale?.data?.state=="Cancelado"?true:false}>
                <CloseOutlined /> Cancelar
            </Button>
            ),
        },        
        {
            key: '6',
            label: (
              <>
                {!currentSale?.data?.advanced_authorization_by_auth_user &&
                    <Button type="link" onClick={()=>setIsVisibleUpdateAdvancedModal(true)}>
                        <LockOutlined /> Permissão Avançada
                    </Button>
                }
                {currentSale?.data?.advanced_authorization_by_auth_user &&
                    <Button type="link" onClick={()=>setIsVisibleRemoveAdvancedModal(true)}>
                        <UnlockOutlined style={{color: "green"}} />
                    </Button>
                }
              </>
            ),
          },
        
      ];
                                                    
    if(casherOpen.isLoading){
        return(
            <Layout><Spin /></Layout>
        )
    }
    
    if (casherOpen != undefined && casherOpen?.data?.id == null) {
        return(
            <Context.Provider value={{name: "Geral"}}>
            {contextHolder}
            <Layout>
            <StoreHeader />
                <Row className="main-box">
                    <Layout>
                        <Content className="casher-product-list">                        
                            <Title>Caixa Fechado</Title>                               
                            {( localTerminal?.data && localTerminal?.data?.id )
                                ? 
                                <>
                                { (localTerminal?.data && localTerminal?.data?.organization_store_id == parseInt(storeId ? storeId : '')) 
                                 ?<>
                                    <Title level={3}>Terminal: {localTerminal?.data?.name}</Title>
                                    <Button type="primary" loading={loadingOpenCasher} onClick={handleToOpenCasher}>
                                        Abrir Caixa
                                    </Button>     
                                    </>
                                  : <Alert type='error' message="Terminal Configurado Em Outra Loja." showIcon/>                           
                                }   
                                </>
                                : <Alert type='error' message="Terminal Não Encontrado, Contate o Suporte." showIcon/>                           
                            }
                        </Content>
                    </Layout>
                </Row>
            </Layout>
            </Context.Provider>
        )
    }
    else {               
        return(
            <Context.Provider value={{name: "Geral"}}>
            {contextHolder}
            <StoreHeader />
            <Layout>
                {barcode}
                {currentSale.isLoading ?
                    <Spin />
                :
                <Row className="main-box">
                    <Col span={24}>
                        <Layout className='content-sale'>
                            <Content className="casher-product-list">  
                                {currentSale?.data?.id == null 
                                ? 
                                    <>
                                        <Title>Caixa Disponível!</Title>
                                        <Button type='primary' onClick={()=>{setIsVisibleAddSaleModal(true)}}>Nova Venda</Button>            
                                        <Button type='link' onClick={()=>{setIsVisibleSearchSaleModal(true)}}>Buscar Venda</Button>
                                    </>      
                                :
                                <Row>                                    
                                    <Col span={24}>
                                        {currentSale?.data?.state != "Carrinho de Compras" &&
                                            <>
                                                <Button style={{marginTop: "10px"}} type='primary' onClick={()=>{setIsVisibleAddSaleModal(true)}}>Nova Venda</Button>
                                                <Button type='link' onClick={()=>{setIsVisibleSearchSaleModal(true)}}>Buscar Venda</Button>
                                                <Button type='link' onClick={()=>{navigate("/lojas/"+storeId+"/caixa")}}>Início</Button>
                                            </>
                                        }
                                        <Title level={3}>Produtos:</Title>
                                        {(currentSale?.data?.state && currentSale?.data?.state != "Concluído" && currentSale?.data?.state != "Cancelado") &&
                                        <Row>
                                            <Col span={2}>
                                                <Button type="primary" onClick={()=>{setIsVisibleAddSaleProductModal(true); setProductAddQtd(1)}}>     
                                                    <b><ShoppingOutlined style={{ fontSize: '16px' }}   /> <PlusOutlined /> <Text style={{color:"#fff"}}>1</Text> </b>
                                                </Button>
                                            </Col>                                            
                                            <Col span={1}>
                                                <Button onClick={()=>{setIsVisibleAddSaleProductModal(true); setProductAddQtd(5)}}>     
                                                    <b><PlusOutlined />5</b>                                             
                                                </Button>
                                            </Col>
                                            <Divider type='vertical'/>
                                            <Col span={1}>
                                                <Button onClick={()=>{setIsVisibleAddSaleProductModal(true); setProductAddQtd(10)}}>     
                                                <b><PlusOutlined />10</b>                                                  
                                                </Button>
                                            </Col>
                                        </Row>
                                        }
                                        <Row style={{marginBottom: "200px"}}>
                                            <CasherProductsTable />
                                        </Row>
                                    </Col>
                                </Row> 
                                }
                            </Content>
                            {currentSale?.data &&
                            <Sider style={{marginBottom: "200px"}}>
                                <Row className="sale-infor-side">                                                                                                                                                                                                                             
                                    <Row>
                                        <Text style={{cursor: "pointer"}}>                                                 
                                            <Space>
                                                <b>Venda #{currentSale?.data?.id} </b>                                                    
                                                <Button type='link' onClick={()=>{setIsVisibleUpdateLevelModal(true)}} disabled={(currentSale?.data?.state == "Concluído" || currentSale?.data?.state == "Cancelado")}>Nível {currentSale?.data?.client_level}</Button>
                                            </Space>                                                
                                        </Text>
                                    </Row>
                                    <Row>
                                        <Col flex={1}>
                                        {currentSale?.data?.state == "Concluído" &&
                                            <Text type="success"><CheckOutlined />{currentSale.data?.state}</Text>
                                        }
                                        {currentSale?.data?.state == "Cancelado" &&
                                            <Text type="danger"><CloseOutlined />{currentSale.data?.state}</Text>
                                        }
                                        
                                        {(currentSale?.data?.state != "Concluído"&& currentSale?.data?.state != "Cancelado") &&
                                            <Text>{currentSale.data?.state}</Text>
                                        }
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Text>                                            
                                            {currentSale?.data?.ecomm &&
                                                <GlobalOutlined />
                                            } 
                                            {currentSale?.data?.exchange &&
                                                <SwapOutlined />
                                            }
                                            {currentSale?.data?.gift &&
                                                <GiftOutlined />
                                            }                                                
                                            <Dropdown menu={{ items }} placement="bottomLeft">
                                                <Button type='link'>
                                                    <EllipsisOutlined />
                                                </Button>
                                            </Dropdown>
                                        </Text>
                                        </Row>
                                    <Divider />                                            
                                    {currentSale?.data?.buyer_by_auth_user
                                        ? <>
                                            <Button type='link' onClick={()=>setIsVisibleUpdateBuyerModal(true)}>{currentSale?.data?.buyer_by_auth_user?.first_name} [{currentSale?.data?.buyer_by_auth_user?.auth_user_information_related?.whatsapp_phone.slice(-6)}]</Button>
                                            <Divider/>
                                        </>
                                        : <>
                                            {(currentSale?.data?.state != "Concluído"&& currentSale?.data?.state != "Cancelado") &&
                                                <Text>
                                                    <Button type='default' onClick={()=>setIsVisibleUpdateBuyerModal(true)}>Identificar Cliente</Button>
                                                    <Divider/>
                                                </Text>
                                            }
                                        </>
                                    }                                                                                                                                    
                                </Row>
                                <CasherTransactionList />                                
                            </Sider>                                                                 
                            }
                            <CasherFooterBar />                            
                        </Layout>
                    </Col>
                </Row>
                }
                <Modal
                    open={modalMoreInforOpen}
                    title="Mais Informações"
                    onCancel={()=>setModalMoreInforOpen(false)}
                    >
                    <Row>
                        Entrega / Correios:
                        Ecommerce | Presente | Troca                                
                    </Row>
                </Modal>

                <CasherAddSaleProductModal visible={isVisibleAddSaleProductModal} handleCancel={handleCancelCasherAddSaleProductModal} productAddQtd={productAddQtd} />
                <CasherUpdateAdvancedModal visible={isVisibleUpdateAdvancedModal} handleCancel={handleCancelUpdateAdvancedModal}/>
                <CasherRemoveAdvancedModal visible={isVisibleRemoveAdvancedModal} handleCancel={()=>{setIsVisibleRemoveAdvancedModal(false)}}/>
                <CasherUpdateBuyerModal visible={isVisibleUpdateBuyerModal} handleCancel={()=>{setIsVisibleUpdateBuyerModal(false)}}/>
                <CasherUpdateLevelModal visible={isVisibleUpdateLevelModal} handleCancel={()=>{setIsVisibleUpdateLevelModal(false)}} />
                <CasherAddSaleModal visible={isVisibleAddSaleModal} handleCancel={()=>{setIsVisibleAddSaleModal(false)}} />
                <CasherUpdateStatePausaModal visible={isVisiblePausaModal} handleCancel={()=>{setIsVisiblePausaModal(false)}} />
                <CasherUpdateStateCanceladoModal visible={isVisibleCancelarModal} handleCancel={()=>{setIsVisibleCancelarModal(false)}} />                
                <CasherSearchSaleModal visible={isVisibleSearchSaleModal} handleCancel={()=>{setIsVisibleSearchSaleModal(false)}} />
            </Layout>
            </Context.Provider>
        ); 
    }
}

export default CasherPage;

/*
CAIXA PRESENCIAL
1 - Busca o cliente
2 - Inicia a Venda
3 - Busca o produto
4 - Adiciona o produto
5 - Finaliza a Venda
6 - Adiciona Recebeimento
7 - Conclui 


VENDEDOR ONLINE:
1 - Separa o pedido
2 - Inicia a Venda
3 - Busca o produto
4 - Adiciona o produto
5 - Finaliza a Venda
6 - Adiciona Recebimento(CAIXA)
7 - Atualiza A Caminho(entrega/correios/transportadora)
8 - Concluí[Entregar/Retirar]


ECOMMERCE:
1 - ...Cliente Finaliza
2 - Consultor de vendas Atribui responsável pela separação
3 - Consultor atualiza falta de produto
4 - Consultor Finaliza separação
5 - Consultor informa ao cliente o valor atualizado
6 - Consultor informa recebimento ao CAIXA
7 - Consultor Atualiza A Caminho(entrega/correios/transportadora)
8 - Consultor Concluí[Entregar/Retirar]


REGRAS VENDAS:
1 - Para cancelar produto e venda precisa do crachar e da senha gerente
2 - Para aplicar desconto somente com crachar e senha gerente
3 - Para cancelar pagamento precisa do crachar e senha do gerente
4 - Comissão gerada após o pagamento
5 - Em caso de erro ou defeitos comissão cancelada automáticamente


REGRA ECOMMERCE:
1 - Só atualiza estado concluído após o pagamento
2 - Registro de entrega com assinatura de Caixa, Consultor e Entregador


POS: 
1 - Acompanhamento completo de recebimentos
2 - Vendedores sempre disponíveis


CONTRA 
1 - Em caso de desistência pode demorar um pouco mais

OBS: Imprimir em impressora Termica

*/