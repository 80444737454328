import { Table, Typography, Tag, Divider, Button, Tooltip } from "antd";
import {FireOutlined, DeleteOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { useParams } from "react-router-dom";
import { useCurrentSaleProducts } from "../../store/useCurrentSaleProduct";
import { formatter_money_br_4_dig } from "../../utils/formatters";
import { useCurrentSale } from '../../store/useCurrentSale';
import { useEffect, useState } from "react";
import { CasherRemoveProductModal } from "../CasherRemoveProductModal";
import { CasherUpdateSaleProductDiscountModal } from "../CasherUpdateSaleProductDiscountModal";
import { useStore } from "../../store/useStore";


const { Text } = Typography;

export const CasherProductsTable = () =>{
    const { storeId } = useParams();
    const { saleId } = useParams();
    const currentSale = useCurrentSale(storeId, saleId);
    const currentSaleProducts = useCurrentSaleProducts(saleId);
    const [prodToDelete, setProdToDelete] = useState();
    const [productToDiscount, setProductToDiscount] = useState();    
    const [isVisibleDeleteModal, setisVisibleDeleteModal] = useState(false);
    const [isVisibleUpdateDiscount, setIsVisibleUpdateDiscount] = useState(false); 
    const store = useStore(storeId);    


    const handleDeleteProduct = (prod: any) =>{
        setProdToDelete(prod);
        setisVisibleDeleteModal(true);
    }   

    const handleDiscountProduct = (prod: any) =>{
        setProductToDiscount(prod);
        setIsVisibleUpdateDiscount(true);
    }   

    const columns = [
        {
            title: 'Nome',
            dataIndex: 'iventory_product',
            key: 'iventory_product',
            render: (obj: any, row: any) => 
            <Text delete={row.is_canceled}>
                <img alt="example" src={row?.iventory_product?.photo_main_mini ? row?.iventory_product?.photo_main_mini : "/erro"} style={{maxWidth: "90px", objectFit: "contain",  maxHeight: "220px", marginRight: "5px", borderRadius: "5px"}} onError={(e: any) => {
                                                            e.target.onerror = null; // prevents looping
                                                            e.target.src="/no_image.jpg";
                                                        }} />
                {row.iventory_product?.code} {obj?.name} [{row?.iventory_product?.iventory_brand?.name}]
            </Text>      
        },
        {
            title: 'Medida',
            dataIndex: 'iventory_product',
            key: 'iventory_product',
            render: (obj: any, row: any) => 
            <Text delete={row.is_canceled}>{row.iventory_product?.unit}</Text>      
        },    
        {
        title: 'Preço',
        dataIndex: 'price',
        key: 'price',
        render: (obj: any, row: any) =>    
            <Text delete={row.is_canceled}>
                {formatter_money_br_4_dig.format(obj)}
            </Text>      
        },
        {
            title: 'Preço C/ Desconto',
            dataIndex: 'price',
            key: 'price',
            render: (obj: any, row: any) => {
                let discount = row.automatic_percentage_discount > 0 ? row.automatic_percentage_discount :row.manual_percentage_discount;
                return <Text delete={row.is_canceled}>
                        {formatter_money_br_4_dig.format(row.price_with_discount)}                        
                    </Text>      
            }
        },
        {
            title: 'QTD',
            dataIndex: 'quantity',
            key: 'quantity',
            render: (obj: any, row: any) => 
            <Text delete={row.is_canceled}>x{obj}</Text>      
        },
        {
            title: 'Sub Total',
            dataIndex: 'sub_total',
            key: 'sub_total',
            render: (obj: any, row: any) => 
                <>
                    <Text delete={row.is_canceled}>{formatter_money_br_4_dig.format(obj)}</Text> 
                    <br/>    
                    {row?.discount_total_value && row?.discount_total_value > 0 &&
                        <Tooltip placement="topLeft" color="magenta" title={"Desconto De: " + (row.automatic_percentage_discount+row.manual_percentage_discount) + "% = " + row.automatic_percentage_discount + "% automatico. / " +  row.manual_percentage_discount + " % manual"}>
                            <Tag color={row.is_canceled ? "default" : "magenta"} icon={<MinusCircleOutlined  />}>  
                                <Text delete={row.is_canceled}>{formatter_money_br_4_dig.format(row.discount_total_value)}</Text>                            
                            </Tag>     
                        </Tooltip>                     
                    }
                </>
        },
        {
            title: 'Ação',
            dataIndex: 'sub_total',
            key: 'sub_total',
            render: (obj: any, row: any) => 
                <>
                    {/* Show Discount button if level 1 or if advanced autho */}
                    {(currentSale?.data?.client_level && 
                        (currentSale?.data?.advanced_authorization_by_auth_user 
                        || ( currentSale?.data?.client_level == 1 && store?.data?.pre_approved_percent_discount_max_n1 && store?.data?.pre_approved_percent_discount_max_n1 > 0 )
                        || ( currentSale?.data?.client_level == 2 && store?.data?.pre_approved_percent_discount_max_n2 && store?.data?.pre_approved_percent_discount_max_n2 > 0 )
                        )
                      ) &&
                            <>
                            <Button icon={<FireOutlined />} onClick={()=>handleDiscountProduct(row)} style={row.is_canceled == false ? {color:"magenta"} : {}}  disabled={row.is_canceled} />
                            <Divider type='vertical'/>
                            </>
                    }
                    {currentSale?.data?.advanced_authorization_by_auth_user && row.is_canceled == false &&
                        <Button icon={<DeleteOutlined />} onClick={()=>{handleDeleteProduct(row)}} />
                    }
                </>
        },
    ]

    return (
        <>
        <Table locale={{emptyText: "Sem Produto"}} dataSource={currentSaleProducts?.data}  columns={columns} pagination={false} style={{width: "100%"}}/>
        <CasherRemoveProductModal visible={isVisibleDeleteModal} handleCancel={()=>setisVisibleDeleteModal(false)} product={prodToDelete} />
        <CasherUpdateSaleProductDiscountModal visible={isVisibleUpdateDiscount} handleCancel={()=>{setIsVisibleUpdateDiscount(false)}} product={productToDiscount} />
        </>
    );
}