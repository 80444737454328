import { Button, Col, Divider, Drawer, InputNumber, List, Modal, notification, Row } from "antd";
import React, { useEffect, useState } from "react";
import useCheckoutDrawer from "../../store/useCheckoutDrawer";
import EcommService from "../../services/Ecomm";
import { NotificationPlacement } from "antd/es/notification/interface";
import { formatter_money_br } from "../../utils/formatters";
import { ShoppingCartOutlined, MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useEcommCarrinho } from "../../store/useEcommCarrinho";

const Context = React.createContext({ name: 'Lojas Infor' });

export const EcommCheckoutDrawer = () => {   
    const closeCheckoutDrawer = useCheckoutDrawer( (state: any) => state.close );    
    const useCheckoutD = useCheckoutDrawer( (state: any) => state.checkoutDrawer );
    const [api, contextHolder] = notification.useNotification();
    const ecommCarrinho = useEcommCarrinho();
    const [modal, contextHolderModal] = Modal.useModal();

    useEffect(()=>{
      ecommCarrinho.refetch();
    }, [useCheckoutD])

    const handleRemoveProduct = (product: any) => {
      let param = {
        quantity: 1
      }
      EcommService.remove_product_qtd(product.pos_sale_id, product.id, param)
        .then((res: any)=>{
            let placement: NotificationPlacement = "bottomLeft";
            api.success({
                message: "Atualizado",
                description: <Context.Consumer>{()=>'Produto Removido.'}</Context.Consumer>,
                placement,
            });
        })
        .catch((error: any)=>{
            if (error.response.status == 401){
                let placement: NotificationPlacement = "bottomLeft";
                api.error({
                    message: "Remover Produto",
                    description: <Context.Consumer>{()=>'Não Autorizado.'}</Context.Consumer>,
                    placement,
                });
            }
            else{
                let placement: NotificationPlacement = "bottomLeft";
                api.error({
                    message: "Remover Produto",
                    description: <Context.Consumer>{()=>'Erro ao remover produto.'}</Context.Consumer>,
                    placement,
                });
            }                
        })
        .finally(()=>{
          ecommCarrinho.refetch();
        })
        ;
    }

    const handleAddProduct = (product: any) => {     
      let param_prod = {
        quantity: 1,
        iventory_product_id: product.iventory_product.id,
      } 
      EcommService.post_product(product.pos_sale_id, param_prod)
          .then((resProd: any)=>{
              let placement: NotificationPlacement = "bottomLeft";
                  api.success({
                      message: "Produto Adicionado",
                      description: <Context.Consumer>{()=>'Produto Adicionado no Carrinho.'}</Context.Consumer>,
                      placement,
                  });                      
          })
          .catch((error: any)=>{
              if (error.response.status == 401){
                  let placement: NotificationPlacement = "bottomLeft";
                  api.error({
                      message: "Erro ao Adicionar Produto",
                      description: <Context.Consumer>{()=>'Não Autorizado.'}</Context.Consumer>,
                      placement,
                  });
              }
              else{
                  let placement: NotificationPlacement = "bottomLeft";
                  api.error({
                      message: "Erro ao Adicionar Produto",
                      description: <Context.Consumer>{()=>'Erro ao Adicionar Produto no Carrinho.'}</Context.Consumer>,
                      placement,
                  });
              }                
          })          
          .finally(()=>{
            ecommCarrinho.refetch();
          });
  }

  const handleConcluir = (sale_id: number) =>{
    
    EcommService.apreparar_product(sale_id)
          .then((resProd: any)=>{
            let secondsToGo = 10;
  
            const instance = modal.success({
              title: "Concluído Com Sucesso",
              content: "Entraremos em Contato Informando Sobre a Separação dos Produtos",
            });
        
            const timer = setInterval(() => {
              secondsToGo -= 1;
            }, 1000);
        
            setTimeout(() => {
              clearInterval(timer);
              instance.destroy();
            }, secondsToGo * 1000);                    
          })
          .catch((error: any)=>{
              if (error.response.status == 401){
                  let placement: NotificationPlacement = "bottomLeft";
                  api.error({
                      message: "Concluir",
                      description: <Context.Consumer>{()=>'Não Autorizado.'}</Context.Consumer>,
                      placement,
                  });
              }
              else{
                  let placement: NotificationPlacement = "bottomLeft";
                  api.error({
                      message: "Erro",
                      description: <Context.Consumer>{()=>'Erro ao Concluir Compra.'}</Context.Consumer>,
                      placement,
                  });
              }                
          })          
          .finally(()=>{
            ecommCarrinho.refetch();
          });
    }

    return (
      <Context.Provider value={{ name: 'Lojas Infor' }}>
      {contextHolder}
      {contextHolderModal}
          <Drawer title={<><ShoppingCartOutlined /> Carrinho de Compras</>} placement="right" onClose={closeCheckoutDrawer} open={useCheckoutD.open}>
            {ecommCarrinho?.data?.map((carrinho: any) => {
              console.log(carrinho);
              return <>                
                <Divider orientation="left">{carrinho?.organization_store?.name} / Sub Total <b style={{color: "green"}}>{formatter_money_br.format(carrinho?.total_to_receive)}</b></Divider>
                <List
                  size="small"
                  header={<div>Produtos</div>}
                  footer={<div><Button type="primary" onClick={()=>handleConcluir(carrinho.id)}>Concluir Pedido</Button> </div>}
                  bordered
                  dataSource={carrinho?.products}
                  renderItem={(item: any) =>                     
                    <List.Item style={{display: item.is_canceled==true ? "none" : ""}}>                      
                        <Row style={{alignContent: "space-between", alignItems: "flex-end"}}>
                          <Col span={4}>
                            <img alt="example" src={item.iventory_product.photo_main_mini ? item.iventory_product.photo_main_mini : "/erro"} style={{width: "100%", objectFit: "contain",  maxHeight: "220px", borderRadius: "5px"}} onError={(e: any) => {
                                                e.target.onerror = null; // prevents looping
                                                e.target.src="/no_image_mini.jpg";
                                              }} />
                          </Col>
                          <Col span={20} style={{alignSelf: "flex-start", textAlign: "right"}}>
                            {item.iventory_product.code + " " + item.iventory_product.name}                                                                        
                          </Col>
                          <Col span={18}>
                            Preço/{item?.iventory_product?.unit?.slice(0, 2)}: {formatter_money_br.format(item.price_with_discount)}
                            <br />
                            QTD: <b>
                                  <MinusCircleOutlined style={{cursor: "pointer"}} onClick={()=>{handleRemoveProduct(item)}}/> 
                                    {item.quantity}
                                  <PlusCircleOutlined style={{cursor: "pointer"}} onClick={()=>handleAddProduct(item)} /></b>
                          </Col>
                          <Col span={6} style={{textAlign: "right"}}>
                            Sub Total <b>{formatter_money_br.format(item.sub_total)}</b>   
                          </Col>
                        </Row>                   
                    </List.Item>
                  }
                />
              </>
            })}
          </Drawer>
        </Context.Provider>
      );
}