import { Alert, Button, Collapse, Input, List, Modal, notification, Typography } from "antd";
import { DeleteOutlined, PrinterOutlined, FileTextOutlined } from '@ant-design/icons';
import { useCurrentSaleTransaction } from "../../store/useCurrentSaleTransaction";
import { useParams } from "react-router-dom";
import React, { useState } from "react";
import { CasherAddSaleTransactionModal } from "../CasherAddSaleTransactionModal";
import { useQueryClient } from "react-query";
import { IAPICasherSaleRes } from "../../services/CasherSale/casherSale.interfaces";
import { formatter_money_br } from "../../utils/formatters";
import { NotificationPlacement } from "antd/es/notification/interface";
import CasherSale from "../../services/CasherSale";
import { CasherUpdateStateConcluidoModal } from "../CasherUpdateStateConcluidoModal";
import { CasherSalePrinterModal } from "../CasherSalePrinterModal";

const { Panel } = Collapse;
const Context = React.createContext({ name: 'Lojas Infor' });

interface ITrans {
    id?: number,
    method?: string,
    value?: number
}

interface IpropsTransaction{
    origin?: "casher" | "sale"
}

const {Text} = Typography;

export const CasherTransactionList = (props: IpropsTransaction) => {
    const [api, contextHolder] = notification.useNotification();
    const { storeId, saleId } = useParams();    
    const currentSale = useQueryClient().getQueryData<IAPICasherSaleRes>(["currentSale", storeId, saleId]);
    const currentSaleTransaction = useCurrentSaleTransaction(saleId);
    const [isVisibleAddModal, setIsVisibleAddModal] = useState(false);
    const [isVisibleModalDelete, setIsVisibleModalDelete] = useState(false);
    const [transToDelete, setTransToDelete] = useState<ITrans|null>();
    const [descriptionDelete, setDescriptionDelete] = useState("");
    const queryClient = useQueryClient();
    const [isVisibleUpdateConcluidoModal, setIsVisibleUpdateConcluidoModal] = useState(false);  
    const [isVisiblePrinterModal, setIsVisiblePrinterModal] = useState(false);  
          
    const openNotificationSuccess = (placement: NotificationPlacement, description: string) => {    
        api.success({
          message: "Sucesso",
          description: description,
          placement,
          duration: 2
        });
    }

    const openNotificationError = (placement: NotificationPlacement, description: string) => {    
        api.error({
          message: "Erro",
          description: description,
          placement,
          duration: 2
        });
    }

    const handleCancelAddModal = () =>{
        setIsVisibleAddModal(false);
    }

    const handleOnClickDelete = (trans: any) => {
        setIsVisibleModalDelete(true);
        setTransToDelete(trans)
    }
    
    const handleDeleteOk = () => {
        let param = {
            description: descriptionDelete,
        };

        CasherSale.cancel_transaction(transToDelete?.id, param)
            .then((res: any) =>{                
                queryClient.invalidateQueries(['currentSale', storeId, saleId]);       
                queryClient.invalidateQueries(['currentSaleProducts', saleId]);
                queryClient.invalidateQueries(['currentSaleTransactions', saleId]);
                queryClient.invalidateQueries(['casherOpen', storeId]);
                openNotificationSuccess("bottomRight", "Transação Cancelada com Sucesso." );
                onDeleteCancel();
            })
            .catch((error:any)=>{
                if(error.response?.data?.detail) {
                    openNotificationError("bottomRight", error.response?.data?.detail);
                }
                else{
                    openNotificationError("bottomRight", "Problema ao Cancelar Transação");
                }
            })
    }

    const onDeleteCancel = () =>{
        setIsVisibleModalDelete(false);
        setDescriptionDelete("");
    }

    const handleCancelUpdateConcluidoModal = () =>{
        setIsVisibleUpdateConcluidoModal(false);
    }

    return (
        <>        
            <b>Pagamentos:</b>
            <Alert
                message={
                    (currentSale?.total_wait_receive && currentSale?.total_wait_receive > 0) 
                        ? "Aguardando Receber:" : "Tudo Pago!"}
                description={
                    <>
                        {(currentSale?.total_wait_receive 
                            && currentSale?.total_wait_receive > 0                            
                            ) 
                            ?<>
                                {formatter_money_br.format(currentSale?.total_wait_receive ? currentSale?.total_wait_receive : 0)}
                                {currentSale?.advanced_authorization_by_auth_user&&
                                    <Button style={{background:"#52c41a"}} type="primary" block onClick={()=>setIsVisibleUpdateConcluidoModal(true)}>
                                        Concluír
                                    </Button>
                                }
                                </>
                            :<>
                                {currentSale?.state != "Concluído" && currentSale?.state != "Cancelado" 
                                    ?
                                        <Button style={{background:"#52c41a"}} type="primary" block onClick={()=>setIsVisibleUpdateConcluidoModal(true)}>
                                            Concluír
                                        </Button>
                                    :   
                                        <></>                                        
                                }                                
                            </>
                        }
                        {currentSale?.state != "Carrinho de Compras" &&
                            <>
                            <Button style={{marginTop: "7px"}} type="default" block onClick={()=>setIsVisiblePrinterModal(true)} icon={<PrinterOutlined />}>
                                Imprimir
                            </Button>
                            <Button style={{marginTop: "7px"}} type="default" target="_blank" href={"/lojas/"+storeId+"/venda/"+saleId+"/imprimir"} icon={<FileTextOutlined />} />
                            </>
                        }
                    </>
                }
                type={currentSale?.total_wait_receive && currentSale?.total_wait_receive > 0 ? "warning" : "success"} 
                showIcon
            />
            <br />
            {(currentSale?.total_wait_receive && currentSale?.total_wait_receive > 0) &&
                <>
                {!props.origin || props.origin== "casher"
                    ?<Button type="primary" block onClick={()=>setIsVisibleAddModal(true)}>
                    Adicionar Pagamento
                    </Button>
                    : <></>
                }
                </>
            }
            <List
                itemLayout="horizontal"
                dataSource={currentSaleTransaction?.data}
                locale={{emptyText: "Sem Pagamento"}}
                renderItem={(item: any) => { 
                    if (item.canceled == false) return (
                        <List.Item>
                            <List.Item.Meta                            
                                key={item?.id}
                                title={item?.method}
                                description={formatter_money_br.format(item?.value)}
                            />
                            <div>
                                {currentSale?.advanced_authorization_by_auth_user && 
                                    <DeleteOutlined onClick={()=>{handleOnClickDelete(item)}}/> 
                                }
                            </div>                                            
                        </List.Item>
                    )}}
            />                                                            

            <CasherAddSaleTransactionModal visible={isVisibleAddModal} handleCancel={handleCancelAddModal}/>
            <CasherUpdateStateConcluidoModal visible={isVisibleUpdateConcluidoModal} handleCancel={handleCancelUpdateConcluidoModal}/>
            <CasherSalePrinterModal visible={isVisiblePrinterModal} handleCancel={()=>setIsVisiblePrinterModal(false)} />
            <Context.Provider value={{name: "Geral"}}>
            {contextHolder}
            <Modal
                open={isVisibleModalDelete}
                title="Cancelar Pagamento"
                onOk={handleDeleteOk}
                onCancel={onDeleteCancel}
                footer={[
                    <Button type="link" key="back" onClick={onDeleteCancel}>
                        Voltar
                    </Button>,
                    <Button type="default" key="Remover da Venda" onClick={handleDeleteOk} disabled={descriptionDelete ? false : true}>
                        Cancelar Pagamento
                    </Button>,
                ]} >
                Cancelar pagamento em {transToDelete?.method} de {formatter_money_br.format(transToDelete?.value ? transToDelete?.value : 0)}?           
                <Text><br />Descrição:</Text>
                <Input value={descriptionDelete} onChange={(e:any)=>setDescriptionDelete(e?.target?.value)} placeholder="Motivo do Cancelamento" required autoComplete="off"/>
            </Modal>            
            </Context.Provider>

        </>
    );
}