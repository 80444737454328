import { Button, Col, notification, Row, Typography } from "antd";
import {FireOutlined} from '@ant-design/icons';
import { Footer } from "antd/lib/layout/layout";
import React, { useState } from "react";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";
import Casher from "../../services/Casher";
import { IAPICasherOpenRes } from "../../services/Casher/casher.interfaces";
import { IAPICasherSaleRes } from "../../services/CasherSale/casherSale.interfaces";
import { dateFormatter, floatToMoneyFormat, formatter_money_br } from "../../utils/formatters";
import { CasherUpdateSalerModal } from "../CasherUpdateSalerModal";
import { NotificationPlacement } from "antd/es/notification/interface";
import { useLocalTerminal } from "../../store/useLocalTerminal";
import { CasherUpdateSaleGeneralDiscountModal } from "../CasherUpdateSaleGeneralDiscountModal";
import { useStore } from "../../store/useStore";

const { Title, Text } = Typography;

const Context = React.createContext({ name: 'Lojas Infor' });

export const CasherFooterBar = () => {
    const { storeId, saleId } = useParams();
    const terminalStore = useLocalTerminal(storeId);
    const currentSale = useQueryClient().getQueryData<IAPICasherSaleRes>(["currentSale", storeId, saleId]);
    const casherOpen = useQueryClient().getQueryData<IAPICasherOpenRes>(["casherOpen", storeId]); 
    const [isVisibleUpdateSalerModal, setIsVisibleUpdateSalerModal] = useState(false);
    const [api, contextHolder] = notification.useNotification();
    const queryClient = useQueryClient();
    const [isVisibleGeneralDiscountModal, setIsVisibleGeneralDiscountModal] = useState(false);
    const store = useStore(storeId);    

    const handleCancel = () =>{
        setIsVisibleUpdateSalerModal(false);
    }

    const openNotificationSuccess = (placement: NotificationPlacement, description: string) => {    
        api.success({
          message: "Sucesso",
          description: description,
          placement,
          duration: 2
        });
    }

    const openNotificationError = (placement: NotificationPlacement, description: string) => {    
        api.error({
          message: "Erro",
          description: description,
          placement,
          duration: 2
        });
    }

    const printCloseCasher = (casher: any) => {        
        let param = {
            lines: [
                {
                    config: {
                        double_width: true,
                        double_height: true,
                        bold: true,
                        align: "center",
                    },
                    text: store?.data?.name ? store?.data?.name : "Nome Loja",
                    cut: false
                },
                {
                    config: {    
                        align: "center",
                        bold: true,
                    },
                    text: casher?.aberto ? "Caixa Aberto" : "Caixa Fechado",
                    cut: false
                },
                {
                    config: {    
                        align: "left",
                    },
                    text: "COD. Terminal:" + (casher?.payments_terminal?.id ? casher?.payments_terminal?.id : "-") +
                        " | " + (casher?.payments_terminal?.name ? casher?.payments_terminal?.name : "-"),
                    cut: false
                },
                {
                    config: {    
                        align: "left",
                    },
                    text: "COD. Caixa:" + (casher?.id ? casher?.id : "-"),
                    cut: false
                },                
                {
                    config: {    
                        align: "left",
                    },
                    text: "Aberto Por:" + (casher?.open_by_auth_user?.first_name ? casher?.open_by_auth_user?.first_name : "Não Identificado"),
                    cut: false
                },
                {
                    config: {    
                        align: "left",
                    },
                    text: "Aberto em:" + (casher?.inserted_at ? dateFormatter(casher?.inserted_at) : "-"),
                    cut: false
                },
                {
                    config: {    
                        align: "left",
                    },
                    text: "Fechado Por:" + (casher?.close_by_auth_user?.first_name ? casher?.close_by_auth_user?.first_name : "Não Identificado"),
                    cut: false
                },
                {
                    config: {    
                        align: "left",
                    },
                    text: "Fechado em:" + (casher?.closed_at ? dateFormatter(casher?.closed_at) : "-"),
                    cut: false
                },
                {
                    config: {    
                        align: "left",
                    },
                    text: "\nDinheiro:" + (casher?.received_cash ? floatToMoneyFormat(casher?.received_cash, 2) : "R$ 0,00"),
                    cut: false
                },
                {
                    config: {    
                        align: "left",
                    },
                    text: "PIX:" + (casher?.received_pix ? floatToMoneyFormat(casher?.received_pix, 2) : "R$ 0,00"),
                    cut: false
                },
                {
                    config: {    
                        align: "left",
                    },
                    text: "Cartão de Débito:" + (casher?.received_card_debit ? floatToMoneyFormat(casher?.received_card_debit, 2) : "R$ 0,00"),
                    cut: false
                },
                {
                    config: {    
                        align: "left",
                    },
                    text: "Cartão de Crédito 1x:" + (casher?.received_card_credit_1x ? floatToMoneyFormat(casher?.received_card_credit_1x, 2) : "R$ 0,00"),
                    cut: false
                },
                {
                    config: {    
                        align: "left",
                    },
                    text: "Cartão de Crédito Parcelado:" + (casher?.received_card_credit_installments ? floatToMoneyFormat(casher?.received_card_credit_installments, 2) : "R$ 0,00"),
                    cut: false
                },
                {
                    config: {    
                        align: "right",
                    },
                    text: "Total Recebido:",
                    cut: false
                },
                {
                    config: {    
                        align: "right",
                        bold: true,
                        double_width: true,
                        double_height: true,                        
                    },
                    text: (casher?.received_total_value ? floatToMoneyFormat(casher?.received_total_value, 2) : "R$ 0,00") + "\n",
                    cut: true
                },
                
            ]
        }      
        
        Casher.to_print(param)
            .then((res: any) =>{                        
                // openNotificationSuccess("bottomRight", "Atualizado com Sucesso." );                
            })
            .catch((error:any)=>{            
                if(error.response?.data?.detail) {
                    openNotificationError("bottomRight", error.response?.data?.detail);
                }
                else{
                    openNotificationError("bottomRight", "Problema ao Imprimir");
                }
            })
    };

    const handleCloseCasher = () =>{
        if (!casherOpen?.id){
            return;
        }
        Casher.to_close(casherOpen?.id)
            .then((res: any) =>{                      
                openNotificationSuccess("bottomRight", "Caixa Fechado Com Sucesso." );
                queryClient.invalidateQueries(["casherOpen", storeId]);
                if(res?.data?.id){
                    printCloseCasher(res.data);
                }
            })
            .catch((error:any)=>{
                if(error.response?.data?.detail) {
                    openNotificationError("bottomRight", error.response?.data?.detail);
                }
                else{
                    openNotificationError("bottomRight", "Problema ao Fechar o Caixa");
                }
            })
    }

    return(
        <Context.Provider value={{name: "Geral"}}>
        {contextHolder}
        <Footer className="casher-footer">                                                                      
            <Row>
                {casherOpen &&
                <Col span={5}>
                    <Title level={5}>
                        {terminalStore?.data?.name}
                    </Title>
                    <Text>
                        Aberto Por: {casherOpen?.open_by_auth_user?.first_name} <br />
                        Aberto Em: {dateFormatter(casherOpen?.inserted_at)}                                                                
                    </Text>  
                    {currentSale?.saler_by_auth_user
                        ? <Text onClick={()=>setIsVisibleUpdateSalerModal(true)}><br/>Vendedor: {currentSale?.saler_by_auth_user?.first_name}</Text> 
                        : <Text> {currentSale?.advanced_authorization_by_auth_user ? <Button type="default" onClick={()=>setIsVisibleUpdateSalerModal(true)}>Selecionar Vendedor</Button> : <>Vendedor Não Identificado</>}</Text>
                    }
                    {(!currentSale || (currentSale?.state!="Carrinho de Compras")) &&
                        <Button type="default" onClick={handleCloseCasher}>Fechar Caixa</Button>                                                       
                    }
                </Col>
                }
                {currentSale &&
                <>
                    <Col span={3} offset={2}>
                        <Title level={5}>
                            Sub Total:                    
                        </Title>
                        <Text>
                            {currentSale?.sub_total_without_discount &&
                                <>{formatter_money_br.format(currentSale?.sub_total_without_discount)}</>
                            }
                        </Text>
                        <Text type="secondary"> <br /> {parseInt(currentSale?.total_quantity ? currentSale?.total_quantity + "" : "0" )} Itens</Text>
                    </Col>
                    <Col span={3}>
                        <Title level={5}>
                            Frete:                    
                        </Title>
                        <Text>
                            {currentSale?.shipping_price_to_buyer &&
                                <>{formatter_money_br.format(currentSale?.shipping_price_to_buyer)}</>
                            }
                        </Text>
                    </Col> 
                    <Col span={3}>
                        <Title level={5}>
                            Taxas:                    
                        </Title>
                        <Text>
                            {currentSale?.taxes &&
                                <>{formatter_money_br.format(currentSale?.taxes)}</>
                            }
                        </Text>
                    </Col>                            
                    <Col span={3}>
                        <Title level={5}>
                            Descontos:                   
                        </Title>
                        
                        <Text type="danger">
                            {currentSale?.total_discount &&
                                <>{formatter_money_br.format(currentSale?.total_discount)}</>
                            }
                        </Text>
                        {(currentSale?.client_level && 
                            (currentSale?.advanced_authorization_by_auth_user 
                            || ( currentSale?.client_level == 1 && store?.data?.pre_approved_percent_discount_max_n1 && store?.data?.pre_approved_percent_discount_max_n1 > 0 )
                            || ( currentSale?.client_level == 2 && store?.data?.pre_approved_percent_discount_max_n2 && store?.data?.pre_approved_percent_discount_max_n2 > 0)
                            )
                        ) &&  
                            <Button icon={<FireOutlined />} onClick={()=>{setIsVisibleGeneralDiscountModal(true)}} style={currentSale?.state != "Cancelado" && currentSale?.state != "Concluído" ? {color:"magenta"} : {}}  disabled={currentSale?.state == "Cancelado" || currentSale?.state == "Concluído"} /> 
                        }                       
                    </Col>
                    <Col span={5} className="casher-total-to-pay">
                        <Text type="success">
                            <b>Total à Pagar:</b>               
                            <Title level={3}>
                                <b>
                                    {currentSale?.total_to_receive &&
                                        <>{formatter_money_br.format(currentSale?.total_to_receive)}</>
                                    }
                                </b>
                            </Title>
                        </Text>
                    </Col>
                </>
                }
            </Row>
            <CasherUpdateSalerModal visible={isVisibleUpdateSalerModal} handleCancel={handleCancel} />
            <CasherUpdateSaleGeneralDiscountModal visible={isVisibleGeneralDiscountModal} handleCancel={()=>{setIsVisibleGeneralDiscountModal(false)}} product={{}} />            
        </Footer>
        </Context.Provider>
    );
}