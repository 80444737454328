import create from 'zustand';
import { persist } from 'zustand/middleware';
import { getCookie } from "../utils/getCookie";

const useUserLoggedStore = create(
    persist(
        (set, get) => ({
            user: { email: null, first_name: null},
            token: getCookie('csrftoken'),
            addUser: (params: any) => set((state: any) => ({ user: params })),
            addToken: (params: any) => set((state: any) => ({ token: params })),
            clearUser: () => set((state: any) => ({ user: {email: null, first_name: null} })),
            clearToken: () => set((state: any) => ({ token: getCookie('csrftoken') })),
        }),
        {
            name: 'user-logged-storage', 
        }
    )
// })
)

export default useUserLoggedStore;