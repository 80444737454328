import api from "../APICommon";
import type {IAPICasherSaleRes, IAPICasherSaleReq, IAPICasherSaleByPassReq, IAPICasherSaleFilter} from "./casherSale.interfaces";

class CasherSale{
    get(store_id: string | undefined, sale_id: string | undefined) {        
        return api().get<IAPICasherSaleRes>("pos/store/" + store_id + "/sale/" + sale_id )
    }
    get_carrinho(store_id: string) {        
        return api().get<IAPICasherSaleRes>("pos/store/" + store_id + "/me/sale/carrinho")
    }
    get_products(sale_id: string | undefined) {        
        return api().get<IAPICasherSaleRes>("pos/sale/" + sale_id + "/products")
    }
    post(store_id: string, params: IAPICasherSaleReq){
        return api().post<IAPICasherSaleRes>("pos/store/" + store_id + "/sale", params)
    }
    list(store_id: string, filter: any, limit?: number, offset?: number, order?: string){
        const json = JSON.stringify(filter);
        return api().get<IAPICasherSaleRes>("pos/store/" + store_id + "/sale/list", 
        {
            params: {
                filter: json, 
                limit: limit ? limit : undefined,
                offset: offset ? offset : undefined,
                order: order ? order : undefined,
            }
        }
        )
    }
    put(sale_id: string | undefined, params: IAPICasherSaleReq){
        return api().put<IAPICasherSaleRes>("pos/sale/" + sale_id, params)
    }
    put_general_discount(sale_id: string | undefined, params: IAPICasherSaleReq){
        return api().put<IAPICasherSaleRes>("pos/sale/" + sale_id + "/general/discount", params)
    }
    put_state(sale_id: string | undefined, params: IAPICasherSaleReq){
        return api().put<IAPICasherSaleRes>("pos/sale/" + sale_id + '/state', params)
    }
    post_product(sale_id: string | undefined, params: any){
        return api().post<IAPICasherSaleRes>("pos/sale/" + sale_id + "/products", params)
    }
    search_product(store_id: string | undefined, filter: any, limit?: number, offset?: number, order?: string) {
        const json = JSON.stringify(filter);
        return api().get<IAPICasherSaleRes>("iventory/store/" + store_id + "/products", 
            {
                params: {
                    filter: json, 
                    limit: limit ? limit : undefined,
                    offset: offset ? offset : undefined,
                    order: order ? order : undefined,
                }
            }
        )
    }
    put_by_pass(sale_id: string | undefined, params: IAPICasherSaleByPassReq){
        return api().put<IAPICasherSaleRes>("pos/sale/" + sale_id + "/by_pass", params)
    }
    delete_by_pass(sale_id: string | undefined){
        return api().delete<IAPICasherSaleRes>("pos/sale/" + sale_id + "/by_pass")
    }
    put_product(sale_id: string | undefined, product_id: number | undefined, params: any){
        return api().put<IAPICasherSaleRes>("pos/sale/" + sale_id + "/products/" + product_id, params)
    }
    delete_product(sale_id: string | undefined, product_id: number | undefined){
        return api().delete<IAPICasherSaleRes>("pos/sale/" + sale_id + "/products/" + product_id)
    }
    get_transactions(sale_id: string | undefined) {        
        return api().get<any>("payments/sale/" + sale_id + "/transactions")
    }
    cancel_transaction(transaction_id: number | undefined, params: any) {        
        return api().put<any>("payments/transactions/" + transaction_id + "/cancel", params)
    }
    post_transaction(params: any) {        
        return api().post<any>("payments/transactions" , params)
    }
}

export default new CasherSale();