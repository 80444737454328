import { Button, Divider, Input, Modal, notification, Select, Space } from "antd";
import UserService from "../../services/User";
import React, { useState } from "react";
import { NotificationPlacement } from "antd/es/notification/interface";
import { verifyError } from "../../utils/errorsVerifyInput";

const Context = React.createContext({ name: 'Lojas Infor' });

interface IAddSaleProductModalProps {
    visible: boolean, handleCancel: Function, setUser: Function
}

export const ClientCreateModal = (props: IAddSaleProductModalProps) => {
    const [api, contextHolder] = notification.useNotification();    
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [cpf, setCpf] = useState("");
    const [cpfOrCnpj, setCpfOrCnpj] = useState("CPF");
    const [cnpj, setCnpj] = useState("");
    const [razaoSocial, setRazaoSocial] = useState("");
    const [inscricaoEstadual, setInscricaoEstadual] = useState("");
    const [surname, setSurname] = useState("");
    const [gender, setGender] = useState("Masculino");
    const [birthday, setBirthday] = useState("");
    const [whatsappPhone, setWhatsappPhone] = useState("");
    const [password, setPassword] = useState("");
    const [errorsReturned, setErrorsReturned] = useState<any>()

    
    const openNotificationSuccess = (placement: NotificationPlacement, description: string) => {    
        api.success({
          message: "Sucesso",
          description: description,
          placement,
          duration: 2
        });
    }

    const openNotificationError = (placement: NotificationPlacement, description: string) => {    
        api.error({
          message: "Erro",
          description: description,
          placement,
          duration: 2
        });
    }

    const handleOk = (user: any) => {
        let birthdayFormated;
        if (birthday){
            let birthdaySplit = birthday.split("/");
            if (birthdaySplit.length=3){
                birthdayFormated = birthdaySplit[2] + "-" + birthdaySplit[1] + "-" + birthdaySplit[0];
            }            
        }
        
        const param = {
            email: email,
            first_name: firstName,
            last_name: lastName,
            cpf: cpf,
            cnpj: cnpj ? cnpj : undefined,
            razao_social: razaoSocial ? razaoSocial : undefined,
            inscricao_estadual: inscricaoEstadual ? inscricaoEstadual : undefined,
            surname: surname ? surname : undefined,
            gender: gender ? gender : undefined,
            birthday: birthdayFormated,
            whatsapp_phone: whatsappPhone,
            password: password,
        }        
        
        UserService.post(param)
            .then((res: any) =>{                                          
                openNotificationSuccess("bottomRight", "Cliente Criado com Sucesso." );
                onCancel();
            })
            .catch((error:any)=>{                                
                if(error.response?.data?.detail) {
                    openNotificationError("bottomRight", error.response?.data?.detail);
                }
                else if(typeof error.response?.data == "object"){                    
                    setErrorsReturned(error.response?.data);
                    for (const [key, value] of Object.entries(error.response?.data)) {
                        openNotificationError("bottomRight", key + " - " + value);
                    }
                }
                else{
                    openNotificationError("bottomRight", "Problema ao Atualizar Nível");
                }
            })
    };
    
    const onCancel = () => {        
        props.handleCancel();    
        setEmail("");
        setFirstName("");
        setLastName("");
        setCpf("");
        setCpfOrCnpj("");
        setCnpj("");
        setRazaoSocial("");
        setInscricaoEstadual("");
        setSurname("");
        setGender("Masculino");
        setBirthday("");
        setWhatsappPhone("");
        setPassword("");
        errorsReturned({});
    };        

    return (
        <Context.Provider value={{name: "Geral"}}>
        {contextHolder}
        <Modal
            open={props.visible}
            title="Cadastrar Cliente"
            onOk={handleOk}
            onCancel={onCancel}
            footer={[,
            <Button key="back" onClick={onCancel}>
                Voltar
            </Button>,            
            <Button type="primary" key="remove" onClick={handleOk}>
                Confirmar
            </Button>
            ]}
    >           
            <Input.Group>
                <Input size="large" value={firstName} onChange={(e: any)=>setFirstName(e.target.value)} style={{width: "50%"}} placeholder="Nome" status={errorsReturned ? verifyError("first_name", errorsReturned):  ""} autoComplete="off"/>
                <Input size="large" value={lastName} onChange={(e: any)=>setLastName(e.target.value)} style={{width: "50%"}} placeholder="Sobrenome" status={errorsReturned ? verifyError("last_name", errorsReturned):  ""} autoComplete="off"/>

            </Input.Group>   
            <Input.Group compact style={{marginTop: "10px"}}>
                <Input size="large" value={surname} onChange={(e: any)=>setSurname(e.target.value)} style={{width: "33.3%"}} placeholder="Apelido" status={errorsReturned ? verifyError("surname", errorsReturned):  ""} autoComplete="off"/>
                <Select
                    size="large"
                    defaultValue={gender}
                    style={{ width: "33.3%" }}
                    onChange={(value: string)=>setGender(value)}     
                    value={gender}               
                    options={[
                        {
                            value: 'Masculino',
                            label: 'Masculino',
                        },
                        {
                            value: 'Feminino',
                            label: 'Feminino',
                        },
                        {
                            value: 'Outros',
                            label: 'Outros',
                        }                        
                    ]}
                    status={errorsReturned ? verifyError("gender", errorsReturned):  ""}
                    />
                <Input size="large" style={{width: "33.3%"}} value={birthday} onChange={(e: any) => setBirthday(e.target.value)} placeholder="Data De Nasc." status={errorsReturned ? verifyError("birthday", errorsReturned):  ""} autoComplete="off"/>
            </Input.Group> 
            <Input.Group compact style={{marginTop:"10px"}}>  
                <Input size="large" style={{width: "50%"}} required={true} type="email" value={email} onChange={(e: any)=>setEmail(e.target.value)} placeholder="Email" status={errorsReturned ? verifyError("email", errorsReturned):  ""} autoComplete="off"/>
                <Input size="large" style={{width: "50%"}} required={true} value={whatsappPhone} onChange={(e: any)=>setWhatsappPhone(e.target.value)} placeholder="Whatsapp" status={errorsReturned ? verifyError("whatsapp_phone", errorsReturned):  ""} autoComplete="off"/>
            </Input.Group>  
            <Input.Group compact style={{marginTop:"10px"}}>   
                <Select
                    size="large"
                    defaultValue={"CPF"}
                    style={{ width: "30%" }}
                    onChange={(value: string)=>setCpfOrCnpj(value)}     
                    value={cpfOrCnpj}               
                    options={[
                        {
                            value: 'CPF',
                            label: 'CPF',
                        },
                        {
                            value: 'CNPJ',
                            label: 'CNPJ',
                        }
                    ]}
                    />
                {cpfOrCnpj=="CPF"
                    ? <Input size="large" value={cpf} onChange={(e: any)=>setCpf(e.target.value)} style={{width: "70%"}} placeholder="CPF" status={errorsReturned ? verifyError("cpf", errorsReturned):  ""} autoComplete="off"/>
                    : <Input size="large" value={cnpj} onChange={(e: any)=>setCnpj(e.target.value)} style={{width: "70%"}} placeholder="CNPJ" status={errorsReturned ? verifyError("cnpj", errorsReturned):  ""} autoComplete="off"/>
                }                
            </Input.Group>  
            {cpfOrCnpj=="CNPJ" &&  
                <>
                <Divider>Detalhes CNPJ</Divider>
                <Input.Group compact style={{marginTop:"10px"}}>   
                    <Input size="large" value={razaoSocial} onChange={(e: any)=>setRazaoSocial(e.target.value)} style={{width: "50%"}} placeholder="Razão Social" status={errorsReturned ? verifyError("razao_social", errorsReturned):  ""} autoComplete="off"/>
                    <Input size="large" value={inscricaoEstadual} onChange={(e: any)=>setInscricaoEstadual(e.target.value)} style={{width: "50%"}} placeholder="IE" status={errorsReturned ? verifyError("inscricao_estadual", errorsReturned):  ""} autoComplete="off"/>
                </Input.Group>
                </>
            }
            <Input.Group compact style={{marginTop:"10px"}}>  
                <Input type="password" placeholder="Senha" value={password} onChange={(e:any)=>setPassword(e.target.value)} status={errorsReturned ? verifyError("password", errorsReturned) : ""} autoComplete="off"/>
            </Input.Group>
        </Modal>
        </Context.Provider>
    );
}