import axios from "axios";
import { getCookie } from "../../utils/getCookie";

export default () => {
  return axios.create({
    //baseURL: "http://localhost/api/v1/",
    //baseURL: "http://146.190.55.153/api/v1/",
    baseURL: "https://lojasinfor.com.br/api/v1/",
    headers: {      
      'X-CSRFToken': getCookie('csrftoken')
    }
  });
}