const formatter_money_br = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    maximumFractionDigits: 4
  });

const formatter_money_br_3_dig = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    maximumFractionDigits: 3
  });

const formatter_money_br_4_dig = new Intl.NumberFormat('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    maximumFractionDigits: 4
  });

// Return String Money formated to Input text
const moneyMask = (value: string | void, max_digits: number | void) => {
  const max_dig_inter = max_digits ? max_digits : 2;
  if(!value){
      if(max_digits==2){
        return "R$ 0,00";
      }
      else{
        return "R$ 0,000";
      }
  }
  // Splitter value in two parts (R$ 50,80) valueInt = R$ 50 / valueDecimal = 80
  let [valueInt, valueDecimal] = value.split(",");     
  // Remove Not numerics character
  valueInt = valueInt.replace(".", '');
  valueInt = valueInt.replace(",", '');
  valueInt = valueInt.replace(/[^0-9.]/g, "");
  // IF intput not contain "," like R$ 50 transformt to R$ 00,50
  if (!valueDecimal){
      valueDecimal = valueInt.slice(-max_dig_inter);
      valueInt = valueInt.slice(0, valueInt.length-max_dig_inter);
  }
  // IF intput has more that 0 decimal places like R$ 50,255 transformt to R$ 502,55
  else if(valueDecimal.length >= max_dig_inter){
      let concatened = valueInt + valueDecimal;
      valueDecimal = concatened.slice(-max_dig_inter);
      valueInt = concatened.slice(0, concatened.length-max_dig_inter);
  }
  // IF iput has 1 decimal like R$ 50,1 transform to R$ 5,01
  else{            
      valueDecimal = valueInt.slice(-1) + valueDecimal;
      valueInt = valueInt.slice(0, valueInt.length-1);
  }  

  let formated = formatter_money_br_3_dig.format(parseFloat(""+valueInt+"."+valueDecimal));
  return _addZerosBeforeMoneyFormat(formated, max_dig_inter)
}

const _addZerosBeforeMoneyFormat = (formated: string, max_dig_inter: number) => {
  let [part1, part2] = formated.split(",");
  if ( part2 && part2.length < max_dig_inter){
    let add_zero_end = "";
    for (let i=0; i < max_dig_inter-part2.length; i++){
      add_zero_end += "0";
    }
    return formated+add_zero_end;
  }
  else{

    return formated;
  }
}

const floatToMoneyFormat = (value: number | void, max_digits: number) => {
  let formated;
  if(max_digits<3){
    formated = formatter_money_br.format(value ? value : 0);
  }
  else{
    formated = formatter_money_br_3_dig.format(value ? value : 0);
  }
  return _addZerosBeforeMoneyFormat(formated, max_digits);
}

const moneyToNumber = (value: string | null) =>{
  if(!value){
    return 0.00;
  }
  value = value.replace(".", '');
  value = value.replace(",", '.');
  value = value.replace(/[^0-9.]/g, "");
  return parseFloat(value);
}

const dateFormatter = (date: string | undefined) => {
    if (date) {
        let date_new = new Date(date)
        return date_new.toLocaleDateString() + " " + date_new.toLocaleTimeString();
    }
    else{
        return "";
    }
}

export {formatter_money_br, formatter_money_br_3_dig, formatter_money_br_4_dig, moneyMask, moneyToNumber, dateFormatter, floatToMoneyFormat}  