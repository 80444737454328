import { Button, Input, Modal,notification } from "antd";
import { LoadingOutlined, BarcodeOutlined } from '@ant-design/icons';
import casherSale from "../../services/CasherSale";
import { useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import React, { useEffect, useState } from "react";
import { NotificationPlacement } from "antd/es/notification/interface";
import useBarcode from "../../store/useBarcode";

const Context = React.createContext({ name: 'Lojas Infor' });

interface IAddSaleProductModalProps {
    visible: boolean, handleCancel: Function
}


export const CasherUpdateAdvancedModal = (props: IAddSaleProductModalProps) => {
    const barcode = useBarcode( (state: any) => state.barcode );
    const [api, contextHolder] = notification.useNotification();
    const { saleId } = useParams();    
    const queryClient = useQueryClient();
    const [loading, setLoading] = useState(false);    
    const [codeByPass, setCodeByPass] = useState<string>("");
    const [pinByPass, setPinByPass] = useState<string>("");
    
    const openNotificationSuccess = (placement: NotificationPlacement, description: string) => {    
        api.success({
          message: "Sucesso",
          description: description,
          placement,
          duration: 2
        });
    }

    const openNotificationError = (placement: NotificationPlacement, description: string) => {    
        api.error({
          message: "Erro",
          description: description,
          placement,
          duration: 2
        });
    }

    const handleOk = () => {                
        let param = {
            code_by_pass: codeByPass,
            pin_by_pass: pinByPass
        };
        casherSale.put_by_pass(saleId, param)
            .then((res: any) =>{                
                queryClient.invalidateQueries('currentSale');                
                openNotificationSuccess("bottomRight", "Autorizado com Sucesso." );
                onCancel();
            })
            .catch((error:any)=>{
                openNotificationError("bottomRight", "Problema ao autorizar");
            })
    };
    
    const onCancel = () => {        
        props.handleCancel();    
        setCodeByPass("");    
        setPinByPass("");
    };
    

    return (
        <Context.Provider value={{name: "Geral"}}>
        {contextHolder}
        <Modal
            visible={props.visible}
            title="Ativar Permissão Avançada"
            onOk={handleOk}
            onCancel={onCancel}
            footer={[
                <Button key="back" onClick={onCancel}>
                    Voltar
                </Button>,
                <Button type="primary" key="Confirmar" onClick={handleOk}>
                    Confirmar
                </Button>,
            ]}
    >                      
            {loading &&
                <LoadingOutlined />
            }
            {!loading &&
                <>
                    <Input autoComplete="Código" size="large" style={{ width: '50%' }}  placeholder="Código" value={codeByPass} onChange={(e)=>setCodeByPass(e.target.value)} prefix={<BarcodeOutlined />} type="password" />
                    <Input autoComplete="PIN" size="large" style={{ width: '50%' }}  placeholder="PIN" value={pinByPass} onChange={(e)=>{setPinByPass(e.target.value)}} type="password" />
                </>
            },

        </Modal>
        </Context.Provider>
    );
}