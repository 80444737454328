import {  useEffect, useRef, useState } from 'react';
import { Card, Col, Layout, Row, Typography } from 'antd';
import { EcommHeader } from '../../components/EcommHeader';
import EcommService from "../../services/Ecomm";
import { IAPIStoreRes } from '../../services/Ecomm/ecomm.interfaces';
import { EcommCheckoutDrawer } from '../../components/EcommCheckoutDrawer';
import { useNavigate } from 'react-router-dom';

const { Header, Footer, Content } = Layout;
const { Title } = Typography;
const { Meta } = Card;

const EcommMain = () => {        
    const dataFetchedRef = useRef(false);
    const [stores, setStores] = useState<IAPIStoreRes[] | undefined>();
    const navigate = useNavigate();
    
    useEffect(()=>{
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;

        EcommService.get_stores()
            .then((resStore: any)=>{        
                setStores(resStore.data);
            })
            .catch((error: any)=>{
                if (error.response.status == 401){
                    
                }
                else{

                }                
            });
            ;
    }, []);

    useEffect(() => {
        document.title = 'Ecommerce - Lojas Infor';
    }, []);

    return(       
        <>
        <EcommHeader />
        <Layout>                 
            <Content style={{minHeight: "80vh", padding: "24px"}}>                
                {stores && 
                    <Row gutter={16}>                    
                        {stores.map((store: IAPIStoreRes) => {
                            return <Col xs={24} md={6}>
                                    <Card
                                        hoverable
                                        style={{ background: store.background_css, color: store.color_css }}
                                        // cover={<img alt="example" src="https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png" />}
                                        onClick={()=>navigate("/loja/" + store.id)}
                                        >
                                        <Meta title={<div style={{color: store.color_css}}>{store.name}</div>} />
                                    </Card>
                                    </Col>
                        })}
                    </Row>
                }                
            </Content>
            <EcommCheckoutDrawer />
            <Footer style={{textAlign: "center"}}>Todos os Direitos Reservados</Footer>
        </Layout>   
        </>     
    ); 
}
  
export default EcommMain;