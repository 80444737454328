import create from 'zustand';
import { persist } from 'zustand/middleware';

const useCheckoutDrawer = create(
    persist(
        (set, get) => ({
            checkoutDrawer: {open: false},
            open: (params: any) => set((state: any) => ({ checkoutDrawer: {open: true} })),            
            close: () => set((state: any) => ({checkoutDrawer: {open: false}})),
        }),
        {
            name: 'checkout-drawer', 
        }
    )
// })
)

export default useCheckoutDrawer;