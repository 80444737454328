import api from "../APICommon";
import type {IAPIUserRes, IAPIUserReq, IAPIUserStoreReq, IAPIUserStoreRes} from "./user.interfaces";

class UserService{
    list(store_id: string | undefined, params: any) {        
        const json = JSON.stringify(params);
        return api().get<IAPIUserRes>("/organization/store/" + store_id + "/user", {params: {filter: json}})
    }
    post(params: IAPIUserReq){
        return api().post<IAPIUserRes>("/ecomm/user", params)
    }
    post_store(params: IAPIUserStoreReq){
        return api().post<IAPIUserStoreRes>("/organization/store", params);
    }    
}

export default new UserService();