import { Button, Divider, Input, List, Modal, notification, Select } from "antd";
import { LoadingOutlined, CaretRightOutlined } from '@ant-design/icons';
import casherSale from "../../services/CasherSale";
import { useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import React, { useEffect, useRef, useState } from "react";
import { NotificationPlacement } from "antd/es/notification/interface";
import UserService from "../../services/User";

const Context = React.createContext({ name: 'Lojas Infor' });

interface IAddSaleProductModalProps {
    visible: boolean, handleCancel: Function
}

export const CasherUpdateSalerModal = (props: IAddSaleProductModalProps) => {
    const [api, contextHolder] = notification.useNotification();
    const { storeId, saleId } = useParams();    
    const queryClient = useQueryClient();
    const [loading, setLoading] = useState(false);    
    const [salesFounds, setSalesFounds] = useState([]);
    const [searchParam, setSearchParam] = useState<string>("");    
    const refInputSearch =  useRef<any | null>(null);
    const [searchType, setSearchType] = useState("cod");

    useEffect(()=>{
        if(searchParam.length > 0){
            searchUser();
        }
    }, [searchType])
    
    const openNotificationSuccess = (placement: NotificationPlacement, description: string) => {    
        api.success({
          message: "Sucesso",
          description: description,
          placement,
          duration: 2
        });
    }

    const openNotificationError = (placement: NotificationPlacement, description: string) => {    
        api.error({
          message: "Erro",
          description: description,
          placement,
          duration: 2
        });
    }

    const handleOk = (user: any) => {        
        let param;
        if (user?.id) {
            param  = {
                saler_by_auth_user_id: user.id,
            }
        }
        else{
            param = {
                saler_by_auth_user_id: null,
            }
        }
        
        casherSale.put(saleId, param)
            .then((res: any) =>{                
                queryClient.invalidateQueries(['currentSale', storeId, saleId]);
                queryClient.invalidateQueries(['currentSaleProducts', saleId]);           
                openNotificationSuccess("bottomRight", "Atualizado com Sucesso." );
                onCancel();
            })
            .catch((error:any)=>{                
                if(error.response?.data?.detail) {
                    openNotificationError("bottomRight", error.response?.data?.detail);
                }
                else{
                    openNotificationError("bottomRight", "Problema ao Atualizar Vendedor");
                }
            })
    };
    
    const onCancel = () => {        
        props.handleCancel();      
        setSalesFounds([]);
        setSearchParam("");
        setSearchType("cod");
    };
    
    const handleSearch = (e: any) => {
        setSearchParam(e.target.value);            
    }

    useEffect(()=>{
        if (searchParam.length > 0) {
            searchUser();
        }
    }, [searchParam]);

    const searchUser = () => {
        if(searchParam.length > 0) {
            setLoading(true); 
                        
            let filter: any;
            if (searchType == "cod"){
                filter = {
                    auth_user_store_related_user__role: "Vendedor",
                    id: searchParam
                }
            }
            else{
                filter = {
                    auth_user_store_related_user__role: "Vendedor",
                    first_name__unaccent__lower__icontains: searchParam
                }
            }           
            
            UserService.list(storeId, filter)
            .then((res: any) => {
                setSalesFounds(res.data.data);
            })
            .catch( (error: any) => {
                // setLoading(false);
            })
            .finally(() =>{
                setLoading(false);                    
            });
        }
    }

    const handleChange = (value: string) =>{        
        setSearchType(value);        
    }

    return (
        <Context.Provider value={{name: "Geral"}}>
        {contextHolder}
        <Modal
            visible={props.visible}
            title="Buscar Vendedor"
            onOk={handleOk}
            onCancel={onCancel}
            footer={[
            <Button type="link" key="remove" onClick={handleOk}>
                Remover Vendedor
            </Button>,
            <Button key="back" onClick={onCancel}>
                Voltar
            </Button>,
            ]}
    >     
            <Input.Group compact>                  
                <Select
                    size="large"
                    defaultValue="cod"
                    style={{ width: "30%" }}
                    onChange={handleChange}     
                    value={searchType}               
                    options={[
                        {
                        value: 'cod',
                        label: 'COD',
                        },
                        {
                        value: 'first_name',
                        label: 'Nome',
                        },
                    ]}
                    />
                <Input ref={refInputSearch} size="large" style={{ width: '50%' }}  placeholder="Somente Números" value={searchParam} onChange={handleSearch} autoComplete="off" />               
            </Input.Group>

            <Divider>Resultado:</Divider>                    
            {loading
                ? <LoadingOutlined />
                : <List
                    size="small"
                    header={<div>Vendedor: </div>}
                    bordered
                    dataSource={salesFounds}
                    locale={{ emptyText: <p>Não Encontrado</p> }}
                    renderItem={(item: any) => {
                        return <List.Item>
                            {item.id} | {item.first_name}
                            <Button style={{float: "right", marginTop: "-6px"}} onClick={()=>handleOk(item)}>
                            Confirmar<CaretRightOutlined />
                            </Button>
                        </List.Item>
                    }
                    }
                />
            }
        </Modal>
        </Context.Provider>
    );
}