import React, { useEffect, useRef, useState } from 'react';
import useUserLoggedStore from '../../store/user';
import { Col, Layout, Row, Divider, Typography, List, notification, Input, Select, Table, Tag, Space, Button, Drawer } from 'antd';
import { NotificationPlacement } from "antd/es/notification/interface";
import { StoreHeader } from '../../components/StoreHeader';
import { useNavigate, useParams } from 'react-router-dom';
import CasherSale from "../../services/CasherSale"
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import type { FilterValue, SorterResult } from 'antd/es/table/interface';
import { dateFormatter, floatToMoneyFormat } from '../../utils/formatters';
import useProductsPriceTag from '../../store/useProductsPriceTag';
import { PrinterOutlined } from '@ant-design/icons';

const { Header, Footer, Content } = Layout;
const { Title, Text } = Typography;
const Context = React.createContext({ name: 'Lojas Infor' });
const { Column, ColumnGroup } = Table;


interface DataType {
    id: number,
    code?: string,
    iventory_brand?: {name: string},
    name?: string,
    unit: string,
    price: number,
    photo_main?: string
}

interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: string;
    sortOrder?: string;
    filters?: Record<string, FilterValue>;
  }

const ProductsPage = () => {        
    // const dataFetchedRef = useRef(false);
    const { storeId } = useParams();
    const [products, setProducts] = useState([]);
    const clearUser = useUserLoggedStore( (state: any) => state.clearUser );    
    const [api, contextHolder] = notification.useNotification();  
    const [searchType, setSearchType] = useState("Todos");
    const [searchParam, setSearchParam] = useState<string>("");    
    const [loading, setLoading] = useState(false); 
    const [data, setData] = useState<DataType[]>();    
    const [tableParams, setTableParams] = useState<TableParams>({
        pagination: {
        current: 1,
        pageSize: 15,
        pageSizeOptions: ["15", "50", "100"],
        showSizeChanger: true,
        locale: { items_per_page: " / Página" }
        },
    });   
    const clearProduct = useProductsPriceTag( (state: any) => state.clearProduct )
    const addProduct = useProductsPriceTag( (state: any) => state.addProduct )
    const useProducts = useProductsPriceTag( (state: any) => state.products )
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Produtos - Lojas Infor';
    }, []);

    useEffect(()=>{
        if(searchParam.length > 0){
            searchProducts();
        }
    }, [searchType])
    
    const openNotificationSuccess = (placement: NotificationPlacement, description: string) => {    
        api.success({
          message: "Sucesso",
          description: description,
          placement,
          duration: 2
        });
    }

    const openNotificationError = (placement: NotificationPlacement, description: string) => {    
        api.error({
          message: "Erro",
          description: description,
          placement,
          duration: 2
        });
    }

    const handleOk = (sale?: any) => {        
        // openNotificationSuccess("bottomRight", "Direcinando Para Venda.")
        // if(props?.origin && props.origin=="sale"){
        //     navigate("/lojas/"+storeId+"/venda/"+sale.id);
        // }
        // else{
        //     navigate("/lojas/"+storeId+"/caixa/venda/"+sale.id);    
        // }
        
        // props.handleCancel();
    };
    
    const handleSearch = async (e: any) => {
        setSearchParam(e.target.value);
    }

    useEffect(()=>{
        if (searchParam.length > 0) {
            searchProducts();
        }
    }, [searchParam]);

    const searchProducts = (limit?: number, offset?: number, order?: string) => {
        if(searchParam.length > 0  && loading == false) {        
            setLoading(true); 
            
            let filter: any;
            if (searchType == "Todos"){
                filter = {
                    search: searchParam
                }
            }
            else if (searchType == "Código de Barras"){
                filter = {
                    code_bar__unaccent__icontains: searchParam
                }
            }
            else if(searchType == "Nome"){
                filter = {
                    name__unaccent__icontains: searchParam
                }
            }
            else if(searchType == "ID"){
                filter = {
                    id: searchParam
                }
            }
            else{
                filter = {
                    code__unaccent__icontains: searchParam
                }
            }

            // console.log(tab)leParams?.pagination?.current
            // console.log(tableParams?.pagination?.pageSize)

            // limit = pageSize

            // offset = pageSize * current
            // else if{
            //     filter = {
            //         buyer_by_auth_user__whatsapp_phone: searchParam
            //     }
            // }
            // else{
            //     filter = {
            //         auth_user_information_related__cpf: searchParam
            //     }
            // }                       
            offset = ((tableParams?.pagination?.pageSize ? tableParams?.pagination?.pageSize : 15) * (tableParams?.pagination?.current ? tableParams?.pagination?.current-1 : 0));
            limit = tableParams?.pagination?.pageSize
            
            CasherSale.search_product(storeId ? storeId : "0", filter, limit, offset, order)
            .then((res: any) => {
                setProducts(res.data.data);
                setData(res.data.data);
                setTableParams({
                    ...tableParams,
                    pagination: {
                    ...tableParams.pagination,
                    total: res.data.total,
                    // 200 is mock data, you should read it from server
                    // total: data.totalCount,
                },
                });
            })
            .catch( (error: any) => {
                setProducts([])
                // setLoading(false);
            })
            .finally(() =>{
                setLoading(false);                    
            });
        }
    }

    const handleChange = (value: string) =>{        
        setSearchType(value);        
    }

    useEffect(() => {
        searchProducts();
      }, [JSON.stringify(tableParams)]);

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<DataType> | SorterResult<DataType>[],
      ) => {

        setTableParams({
          pagination,
          ...sorter,
        });
    
        // `dataSource` is useless since `pageSize` changed
        if (pagination.pageSize !== tableParams.pagination?.pageSize) {
          setData([]);
        }
      };

    return(     
        <Context.Provider value={{name: "Geral"}}>
            {contextHolder}
            <Layout>
            <StoreHeader />                              
            <Content style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
              background: "#fff",
            }}>
                <Button onClick={()=>setOpen(true)} icon={<PrinterOutlined />}>Imprimir {useProducts.length}</Button>
                <Title>Produtos</Title>
                <Input.Group compact style={{marginBottom: "15px"}}>                  
                    <Select
                        size="large"
                        defaultValue="Todos"
                        style={{ width: "30%" }}
                        onChange={handleChange}     
                        value={searchType}               
                        options={[
                            {
                                value: 'Todos',
                                label: 'Todos',
                            },
                            {
                                value: 'Código',
                                label: 'Código',
                            },
                            {
                                value: 'Nome',
                                label: 'Nome',
                            },
                            {
                                value: 'Código de Barras',
                                label: 'Código de Barras',
                            },
                            {
                                value: 'ID',
                                label: 'ID',
                            }
                        ]}
                        />
                    <Input size="large" style={{ width: '50%' }}  placeholder="Somente Números" value={searchParam} onChange={handleSearch} disabled={searchType=="Minhas Vendas"} autoComplete="off"/>               
                </Input.Group>
                <Table
                    // columns={columns}
                    rowKey={(record) => record.id}
                    dataSource={data}
                    pagination={tableParams.pagination}
                    loading={loading}
                    onChange={handleTableChange}                                      
                    expandable={{
                        expandedRowRender: (record: any) =>                             
                            <Text style={{ margin: 0 }}>
                                {record.discount_coupon && record.discount_coupon.length > 0 &&
                                    <>
                                    <b>Descontos Cupom:</b>
                                    <br/>
                                    {record.discount_coupon.map((coupon: any)=>{
                                        return <><Text color="magenta">
                                            {coupon.code + " " + coupon.percent_discount}% = {floatToMoneyFormat((record.price-(record.price*(coupon.percent_discount/100))), 2)}
                                        </Text> Até {dateFormatter(coupon.active_until)} </>
                                    })   
                                    }
                                    <br/>
                                    </>
                                }
                                {record.discount_level && record.discount_level.length > 0 &&
                                    <>
                                    <b>Descontos Nível:</b>
                                    <br/>
                                    {record.discount_level.map((level: any)=>{
                                        return <><Text color="magenta">
                                            Nível {level.client_level + " - " + level.percent_discount}% = {floatToMoneyFormat(record.price-(record.price*(level.percent_discount/100)), 2)}
                                        </Text> </>
                                    })   
                                    }
                                    <br/>
                                    </>
                                }
                                 {record.discount_qtd && record.discount_qtd.length > 0 &&
                                    <>
                                    <b>Descontos Quantidade Mínima:</b>
                                    <br/>
                                    {record.discount_qtd.map((qtd: any)=>{
                                        return <><Text color="magenta">
                                            QTD Minima {
                                            qtd.quantity_min + " - " + qtd.percent_discount}% = {floatToMoneyFormat(record.price-(record.price*(qtd.percent_discount/100)), 2)}
                                        </Text> </>
                                    })   
                                    }
                                    </>
                                }

                            </Text>,
                        rowExpandable: (record: any) => record.name !== 'Sem Desconto',
                      }}
                    >
                        <Column
                            title="ID"
                            key="id"
                            render={(_: any, record: DataType) => ( 
                                <>
                                <img alt="example" src={record.photo_main ? record.photo_main : "/erro"} style={{width: "20%", objectFit: "contain",  maxHeight: "220px", marginRight: "5px", borderRadius: "5px"}} onError={(e: any) => {
                                                e.target.onerror = null; // prevents looping
                                                e.target.src="/no_image.jpg";
                                              }} />
                                <p>{record.id}</p>    
                                </>                            
                            )}
                        />
                        <Column
                            title="Código"
                            key="code"
                            render={(_: any, record: DataType) => (                                
                                <p>{record.code}</p>                                
                            )}
                        />
                        <Column
                            title="Nome"
                            key="name"
                            render={(_: any, record: DataType) => (                                
                                <p>{record.name}</p>                                
                            )}
                        />
                        <Column
                            title="Marca"
                            key="iventory_brand"
                            render={(_: any, record: DataType) => (                                
                                <p>{record.iventory_brand?.name}</p>                                
                            )}
                        />
                        <Column
                            title="Unidade"
                            key="unit"
                            render={(_: any, record: DataType) => (                                
                                <p>{record.unit}</p>                                
                            )}
                        />
                        <Column
                            title="Preço"
                            key="price"
                            render={(_: any, record: DataType) => (                                
                                <p>{floatToMoneyFormat(record.price, 2)}</p>                                
                            )}
                        />
                        <Column
                            title="Action"
                            key="action"
                            render={(_: any, record: DataType) => (
                                <Space size="middle">
                                <Button icon={<PrinterOutlined />} onClick={()=>{addProduct([record].concat(useProducts))}}></Button>
                                </Space>
                            )}
                        />
                    </Table>
                    <Drawer title="Produtos Selecionados" placement="right" onClose={()=>setOpen(false)} open={open}>
                        {useProducts.map((prod: any)=>{
                            return <p>{prod.code} | {prod.name}</p>
                        })}
                        <Button type="link" onClick={()=>clearProduct()}>Remover Todos</Button>
                        <Button type="primary" onClick={()=>window.open("/lojas/"+storeId+"/produtos/imprimir", '_blank', 'noreferrer')}>Confirmar</Button>
                    </Drawer>
            </Content>
            <Footer style={{textAlign: "center"}}>Todos os Direitos Reservados</Footer>
        </Layout>   
        </Context.Provider>  
    ); 
}
  
export default ProductsPage;