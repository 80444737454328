import api from "../APICommon";
import { IAPIMenuRes, IAPIStoreRes, IAPIProductRes, IAPIProductReq } from "./ecomm.interfaces";

class EcommService{
    get_menu(){
        return api().get<IAPIMenuRes[]>("ecomm/menu")
    }
    get_stores_menu(storeId: string | undefined){
        return api().get<IAPIMenuRes[]>("ecomm/store/"+ storeId +"/menu")
    }    
    get_stores(){
        return api().get<IAPIStoreRes[]>("ecomm/stores")
    }
    list_products(filter: any, limit?: number, offset?: number, order?: string){
        const json = JSON.stringify(filter);
        return api().get<IAPIProductRes>("ecomm/products", 
            {
                params: {
                    filter: json, 
                    limit: limit ? limit : undefined,
                    offset: offset ? offset : undefined,
                    order: order ? order : undefined,
                }
            }
        )
    }
    get_carrinho(){
        return api().get<IAPIStoreRes[]>("ecomm/sales/carrinho")
    }   
    post_carrinho(store_id: string | undefined){
        return api().post<IAPIStoreRes>("ecomm/stores/" + store_id + "/sales")
    } 
    post_product(sale_id: number | undefined, param: IAPIProductReq){
        return api().post<IAPIStoreRes>("ecomm/stores/sales/" + sale_id + "/products", param)
    }
    remove_product_qtd(sale_id: number | undefined, product_id: number | undefined , param: IAPIProductReq){
        return api().delete<IAPIStoreRes>("ecomm/stores/sales/" + sale_id + "/products/" + product_id, {data: param})
    } 
    apreparar_product(sale_id: number | undefined){
        return api().put<IAPIStoreRes>("ecomm/stores/sales/" + sale_id + "/state/apreparar")
    }         
}

export default new EcommService();