const verifyError = (key_param: string, errorsReturned: any) =>{
    if(!errorsReturned){
        return ""
    }
    for (const [key, value] of Object.entries(errorsReturned)) {            
        if(key == key_param){
            return "error";
        }
    }
}

export {verifyError};