import { useQuery } from "react-query";
import casherSale from "../services/CasherSale";
import { IAPICasherSaleRes } from "../services/CasherSale/casherSale.interfaces";

const fetchCurrentSale = (storeId: string | undefined, saleId: string | undefined): Promise<IAPICasherSaleRes> => {
    return casherSale.get(storeId, saleId)
            .then( (res: any) => {
                return res.data.data;
            })
            .catch( (error: any) => {
                return null
            })
};

const useCurrentSale = (storeId: string | undefined, saleId: string | undefined) => {
    return useQuery(["currentSale", storeId, saleId], ()=>fetchCurrentSale(storeId, saleId));
}

export {useCurrentSale}