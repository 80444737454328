import { useQuery } from "react-query";
import casherSale from "../services/CasherSale";
import { IAPICasherSaleProductsRes } from "../services/CasherSale/casherSale.interfaces";

const fetchCurrentSaleProducts = (saleId: string | undefined): Promise<IAPICasherSaleProductsRes[]> => {
    return casherSale.get_transactions(saleId)
            .then( (res: any) => {                
                return res.data.data;
            })
            .catch( (error: any) => {
                return {}
            })
};

const useCurrentSaleTransaction = (saleId: string | undefined) => {
    return useQuery(["currentSaleTransactions", saleId], ()=> fetchCurrentSaleProducts(saleId));
}

export {useCurrentSaleTransaction}