import React, { useEffect, useState } from 'react';
import useUserLoggedStore from '../../store/user';
import Authentication from "../../services/Authentication";
import type {IAPILoginReq} from "../../services/Authentication/login.interfaces"
import { Button, Checkbox, Col, Form, Input, Layout, Row, notification, Divider, Typography } from 'antd';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { NotificationPlacement } from "antd/es/notification/interface";
import { useNavigate } from "react-router-dom";

const { Header, Footer, Sider, Content } = Layout;
const { Title } = Typography;

const Context = React.createContext({ name: 'Lojas Infor' });

// function setToken(userToken) {
//     sessionStorage.setItem('token', JSON.stringify(userToken));
// }

// function getToken() {
//     const tokenString = sessionStorage.getItem('token');
//     const userToken = JSON.parse(tokenString);
//     return userToken?.token
// }

const LoginPage = () => {
    const user = useUserLoggedStore( (state: any) => state.user );
    const addUser = useUserLoggedStore( (state: any) => state.addUser );
    const clearUser = useUserLoggedStore( (state: any) => state.clearUser );
    
    const [logged, setLogged] = useState({email: ""})

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Login - Lojas Infor';
    }, []);

    useEffect(()=>{ 
        // clearUser()
        // console.log(user)
        setLogged(user)
    }, [user])

    const handleSubmit = async (e: any) => {
        const req_data: IAPILoginReq = {
            whatsapp_phone: username,
            cpf: username,
            cnpj: username,
            password: password
        }
        Authentication.post(req_data)
        .then(function (response: any) {
            addUser(response.data);
            let placement: NotificationPlacement = "bottomRight";
            api.success({
                message: "Sucesso",
                description: <Context.Consumer>{()=>'Bem Vindo, '+ user.first_name + '!'}</Context.Consumer>,
                placement,
              });            
            navigate("/");
          })
          .catch(function (error: any) {
            let placement: NotificationPlacement = "bottomRight";
            api.error({
                message: "Erro ao realizar Login",
                description: <Context.Consumer>{()=>'Não Autorizado!'}</Context.Consumer>,
                placement,
              });
          });
    }

    const handleClear = () => {
        clearUser();
    }

    return(
        <Context.Provider value={{ name: 'Lojas Infor' }}>
        {contextHolder}
        <Header style={{background: "#000", display: "flex"}}>
            <Title style={{color: "#fff", padding: "0px 15px"}}>Grupo Infor</Title>
        </Header>
        <Layout>                 
            <Content style={{minHeight: "80vh"}}>
                <Row justify="space-around" align="middle"><Col span={8} >
                <Divider orientation="left">Login</Divider>
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{ remember: true }}
                    // onFinish={onFinish}
                    onFinish={handleSubmit}
                    >
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: 'Please input your Username!' }]}
                    >
                        <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" onChange={(e:any)=>{setUsername(e.target.value)}} autoComplete="off"/>
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={[{ required: true, message: 'Please input your Password!' }]}
                    >
                        <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Password"
                        onChange={(e:any)=>{setPassword(e.target.value)}}
                        />
                    </Form.Item>
                    <Form.Item>
                        <Form.Item name="remember" valuePropName="checked" noStyle>
                        <Checkbox>Lembrar</Checkbox>
                        </Form.Item>

                        <a className="login-form-forgot" href="#" onClick={handleClear}>
                        Esqueceu a Senha?
                        </a>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            Entrar
                        </Button>
                        Ou <a href="">Cadastre-se Agora!</a>
                    </Form.Item>
                </Form>                
                </Col></Row>
            </Content>
            <Footer style={{textAlign: "center"}}>Todos os Direitos Reservados</Footer>
        </Layout>        
    </Context.Provider>
    ); 
}
  
export default LoginPage;