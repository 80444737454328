import { useQuery } from "react-query";
import EcommService from "../services/Ecomm";
import { IAPISaleRes } from "../services/Ecomm/ecomm.interfaces";

const fetchEcommCarrinho = (): Promise<IAPISaleRes[]> => {
    return EcommService.get_carrinho()
        .then((res: any)=>{
            return res.data.data;
        })
        .catch((error: any)=>{
            return null;               
        });
};

const useEcommCarrinho = () => {
    return useQuery(["ecommCarrinho"], ()=>fetchEcommCarrinho());
}

export {useEcommCarrinho}