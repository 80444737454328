import api from "../APICommon";
import type {IAPILoginReq, IAPILoginRes, IAPIStoreRes} from "./login.interfaces";

class Authentication{
    post( param: IAPILoginReq) {        
        return api().post<IAPILoginRes>("auth/login", param)
    }
    logout(){
        return api().put<IAPILoginRes>("auth/logout")
    }
    get_stores(){
        return api().get<IAPIStoreRes>("auth/me/stores")
    }
}

export default new Authentication();