import api from "../APICommon";
import apiLocal from "../APILocal";
import type {IAPICasherOpenRes, IAPIMacRes, IAPITerminalFilter, IAPICTerminalRes, IAPICasherOpenReq, IAPIPrinterReq, IAPIPrinterRes} from "./casher.interfaces";

class CasherService{
    get_open(storeId: string | undefined ) {        
        return api().get<IAPICasherOpenRes>("/store/" + storeId + "/payments/casher/open")
    }
    to_open(param: IAPICasherOpenReq) {        
        return api().post<IAPICasherOpenRes>("/payments/casher", param)
    }
    to_close(casher_id: number) {
        return api().put<IAPICasherOpenRes>("/payments/casher/" + casher_id + "/close" )
    }
    get_terminal(params: IAPITerminalFilter) {
        const json = JSON.stringify(params);
        return api().get<IAPICTerminalRes>("/payments/terminal/bymacsn", {params: {filter: json}})
    }
    get_mac() {        
        return apiLocal().get<IAPIMacRes>("/mac")
    }
    check_printer() {        
        return apiLocal().get<IAPIMacRes>("/printer/check")
    }
    to_print(param: IAPIPrinterReq) {        
        return apiLocal().post<IAPIPrinterRes>("/printer", param)
    }
}

export default new CasherService();