import { Button, Divider, Input, InputNumber, List, Modal, Typography, notification, Select, Row, Col, Tag } from "antd";
import { LoadingOutlined, PlusCircleOutlined, BarcodeOutlined } from '@ant-design/icons';
import casherSale from "../../services/CasherSale";
import { useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import React, { useEffect, useRef, useState } from "react";
import useBarcode from "../../store/useBarcode";
import { formatter_money_br } from "../../utils/formatters";
import { useCurrentSale } from "../../store/useCurrentSale";
import { NotificationPlacement } from "antd/es/notification/interface";

const Context = React.createContext({ name: 'Lojas Infor' });
const {Text} = Typography;

interface IAddSaleProductModalProps {
    visible: boolean, handleCancel: Function, productAddQtd:number
}

interface IFilter {
    code_bar__unaccent__icontains?: string,
    name__unaccent__lower__icontains?: string,
    code__unaccent__icontains?: string,
    search?: string,
    canceled?: boolean
}

export const CasherAddSaleProductModal = (props: IAddSaleProductModalProps) => {

    const barcode = useBarcode( (state: any) => state.barcode );
    const clearBarcode = useBarcode( (state: any) => state.clearBarcode );

    const [api, contextHolder] = notification.useNotification();
    const { storeId, saleId } = useParams();    
    const queryClient = useQueryClient();
    const [loading, setLoading] = useState(false);    
    const [productsFounds, setProductsFounds] = useState([]);
    const [searchIntern, setSearchIntern] = useState<string>("");
    const [qtdIntern, setQtdIntern] = useState<number>(1);
    const refInputQtd = useRef<HTMLInputElement>(null);
    const refInputSearch =  useRef<any | null>(null);
    const [searchType, setSearchType] = useState("search");
    const [manualDiscount, setManualDiscount] = useState(0.0);
    const currentSale = useCurrentSale(storeId, saleId);
    const [firstReadBarcode , setFirstReadBarcode] = useState("");

    useEffect(()=>{
        setQtdIntern(props.productAddQtd);
    }, [props.productAddQtd]);

    useEffect(()=>{              
        if (props.visible == true && barcode.length > 3){         
            setQtdIntern(1);
            // setSearchIntern(barcode);                        
            setSearchType("barcode");
            setFirstReadBarcode(barcode);
            // setSearchIntern(e.target.value);
            // clearBarcode();
            // searchProduct();
        }
        refInputQtd?.current?.focus();
    }, [props.visible]);

    useEffect(()=>{
        if(searchIntern.length > 1 || firstReadBarcode.length > 1){
            // clearBarcode();            
            setFirstReadBarcode("");
            searchProduct();
        }
    }, [searchType, firstReadBarcode]);
    
    const openNotificationSuccess = (placement: NotificationPlacement, description: string) => {    
        api.success({
          message: "Sucesso",
          description: description,
          placement,
          duration: 2
        });
    }

    const openNotificationError = (placement: NotificationPlacement, description: string) => {    
        api.error({
          message: "Erro",
          description: description,
          placement,
          duration: 2
        });
    }

    const handleOk = (iven_prod: any) => {   
        // manual_percentage_discount                
        let param = {
            iventory_product_id: iven_prod.id,
            quantity: (firstReadBarcode?.length > 1) ? 1 : qtdIntern,
            manual_percentage_discount: manualDiscount,
        };
        casherSale.post_product(saleId, param)
            .then((res: any) =>{                
                queryClient.invalidateQueries(['currentSale', storeId, saleId]);
                queryClient.invalidateQueries(['currentSaleProducts', saleId]);           
                openNotificationSuccess("bottomLeft", res.data?.quantity + "x " + " Adicionado com Sucesso." );
                onCancel();
            })
            .catch((error:any)=>{
                if(error.response?.data?.detail) {
                    openNotificationError("bottomLeft", error.response?.data?.detail);
                }
                else{
                    openNotificationError("bottomLeft", "Problema ao Adicionar Produto");
                }                
            })
    };
    
    const onCancel = () => {        
        props.handleCancel();      
        setManualDiscount(0);
        setManualDiscount(0.0);
        setSearchIntern("");
        setSearchType("search");
        setQtdIntern(1);
        clearBarcode();
        setFirstReadBarcode("");
        setProductsFounds([]);
    };
    
    const handleSearchProduct = async (e: any) => {
        setSearchIntern(e.target.value);
    }

    const searchProduct = () => {
        if(searchIntern.length > 1 || firstReadBarcode.length > 1) {
            setLoading(true); 
            let currentFilter = firstReadBarcode.length > 1 ? firstReadBarcode : searchIntern
            let filter: IFilter;
            if ( firstReadBarcode.length > 1){
                filter = {
                    code_bar__unaccent__icontains: firstReadBarcode
                }
            }
            else if (searchType == "search"){
                filter = {
                    search: searchIntern
                }
            }
            else if (searchType == "barcode"){
                filter = {
                    code_bar__unaccent__icontains: searchIntern
                }
            }
            else if(searchType == "name"){
                filter = {
                    name__unaccent__lower__icontains: searchIntern
                }
            }
            else{
                filter = {
                    code__unaccent__icontains: searchIntern
                }
            }           

            filter.canceled = false;
            
            casherSale.search_product(storeId, filter)
                .then((res: any) => {
                    if(res.data.total == 1 && firstReadBarcode.length > 10){
                        handleOk(res.data.data[0]);
                        onCancel();
                        return;
                    }
                    if ( currentFilter == barcode || currentFilter == searchIntern){
                        setProductsFounds(res.data.data);
                    }
                })
                .catch( (error: any) => {
                    // setLoading(false);
                })
                .finally(() =>{
                    setLoading(false);                    
                });
        }
    }

    const handleChange = (value: string) =>{        
        setSearchType(value);        
    }

    return (
        <Context.Provider value={{name: "Geral"}}>
        {contextHolder}
        <Modal
            open={props.visible}
            title="Adicionar Produto"
            onOk={handleOk}
            onCancel={onCancel}
            footer={[
            <Button key="back" onClick={onCancel}>
                Voltar
            </Button>,
            ]}
    >     
            <Input.Group compact>
                <InputNumber ref={refInputQtd} size="large" style={{ width: '20%' }}  placeholder="QTD" min={1} max={1000} value={qtdIntern} 
                            onChange={(e:any)=>{setQtdIntern(e)}} 
                            onKeyPress={(event)=>{if(event.key === 'Enter'){refInputSearch?.current?.focus()}}}
                />
                
                <Input ref={refInputSearch} size="large" style={{ width: '50%' }}  placeholder="Pesquisar" value={searchIntern} prefix={<BarcodeOutlined />} onChange={handleSearchProduct} onKeyPress={event => {
                if (event.key === 'Enter') {
                    setFirstReadBarcode("");
                    searchProduct();
                }
              }} autoComplete="off" />
                <Select
                    size="large"
                    defaultValue="search"
                    style={{ width: "30%" }}
                    onChange={handleChange}     
                    value={searchType}               
                    options={[   
                        {
                            value: 'search',
                            label: 'Todos',
                        },                     
                        {
                            value: 'barcode',
                            label: 'Código de Barras',
                        },
                        {
                            value: 'codigo',
                            label: 'Código',
                        },
                        {
                            value: 'name',
                            label: 'Nome',
                        },
                    ]}
                    />
            </Input.Group>
            {currentSale?.data?.advanced_authorization_by_auth_user &&            
            <Input.Group>
            <br />
            <Text>Desconto %: </Text>                    
            <InputNumber size="large" style={{ width: '20%' }}  placeholder="QTD" min={0} max={100} value={manualDiscount} 
                            onChange={(e:any)=>{setManualDiscount(e)}} 
                            onKeyPress={(event)=>{if(event.key === 'Enter'){refInputSearch?.current?.focus()}}}
                />       
            </Input.Group>     
            }
            <Divider>Produtos:</Divider>                    
            {loading ?
                <LoadingOutlined />
            :
                <List
                    size="small"
                    header={<div>Produtos</div>}
                    bordered
                    dataSource={productsFounds}
                    locale={{ emptyText: <p>Produto Não Encontrado</p> }}
                    renderItem={(item: any) => {
                        let priceWithDiscount = parseFloat(item.price) - (item.price*(manualDiscount/100));
                    return <List.Item>
                            <Row>
                                <Col span={8}>
                                    <img alt="example" src={item?.photo_main_mini ? item?.photo_main_mini : "/erro"} style={{width: "100%", objectFit: "contain",  maxHeight: "220px"}} onError={(e: any) => {
                                                            e.target.onerror = null; // prevents looping
                                                            e.target.src="/no_image.jpg";
                                                        }} />
                                </Col>
                                <Col span={16} style={{alignSelf: "flex-start", textAlign: "right"}}>
                                    {item?.code} | {item?.name} [{item?.iventory_brand?.name}]                                    
                                    <br/> 
                                    <b>{formatter_money_br.format(item.price)}</b>
                                    {manualDiscount > 0 ?
                                        <Text mark> -{manualDiscount}% = {formatter_money_br.format(priceWithDiscount)}                        
                                        | x{qtdIntern} Sub. Total {formatter_money_br.format(priceWithDiscount * qtdIntern)}</Text>
                                    :
                                        <Text> x{qtdIntern} = {formatter_money_br.format(item.price * qtdIntern)}</Text>                             
                                    }
                                    <br/>
                                    {currentSale?.data?.client_level && currentSale?.data?.client_level  > 1 &&
                                        <>
                                            {item?.discount_level?.map((disc: any)=>{
                                                if ( currentSale?.data?.client_level && 
                                                    disc?.client_level <= currentSale?.data?.client_level){
                                                    return <Tag color="magenta"> N{disc.client_level} | {disc.percent_discount}% | 
                                                                {manualDiscount > 0 
                                                                ? " " + (formatter_money_br.format(priceWithDiscount*((100-disc.percent_discount)/100)) + " x" + qtdIntern + " = " + formatter_money_br.format(qtdIntern * priceWithDiscount*((100-disc.percent_discount)/100))) 
                                                                : " " + (formatter_money_br.format(item.price*((100-disc.percent_discount)/100)) + " x" + qtdIntern + " = " + formatter_money_br.format(qtdIntern * item.price*((100-disc.percent_discount)/100))) 
                                                                }
                                                            </Tag>
                                                }                                                 
                                            })}
                                        </>
                                    }

                                    <br/>
                                    <Button style={{marginTop: "6px", background: !item?.active_store ? "red" : ""}} onClick={()=>handleOk(item)}>
                                        <PlusCircleOutlined  /> Adicionar
                                        <small>{!item?.active_store && " Produto Indisponível" }</small>
                                    </Button>
                                </Col>                                                   
                            </Row>
                        
                    </List.Item>
                    }
                    }
                />
            }
        </Modal>
        </Context.Provider>
    );
}