import { useQuery } from "react-query";
import StoreService from "../services/Organization";
import { IAPIStoreRes } from "../services/Organization/organization.interfaces";

const fetchStore = (storeId: string | undefined): Promise<IAPIStoreRes> => {
    return StoreService.get_store(storeId)
            .then( (res: any) => {
                return res.data;
            })
            .catch( (error: any) => {
                return null
            })
};

const useStore = (storeId: string | undefined) => {
    return useQuery(["Store", storeId], ()=>fetchStore(storeId));
}

export {useStore}