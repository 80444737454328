import { Button, Dropdown, Input, Layout, Menu, MenuProps, Space, Spin, Typography } from "antd";
import { useNavigate, useParams } from "react-router-dom";
import { useStore } from "../../store/useStore";
import { SettingOutlined, CaretDownOutlined, PoweroffOutlined, ShoppingCartOutlined, AppstoreOutlined, FileTextOutlined, PercentageOutlined, BarcodeOutlined} from '@ant-design/icons';
import EcommService from "../../services/Ecomm";
import Authentication from "../../services/Authentication";
import { useUserStoreMe } from "../../store/useUserStoreMe";
import useUserLoggedStore from "../../store/user";
import { useEffect, useRef, useState } from "react";
import { IAPIMenuRes } from "../../services/Ecomm/ecomm.interfaces";
import useCheckoutDrawer from "../../store/useCheckoutDrawer";
const { Sider, Content, Header } = Layout;
const {Title} = Typography;
const { Search } = Input;

export const EcommStoreHeader = () => {
    const dataFetchedRef = useRef(false);
    const { storeId } = useParams();    
    const store = useStore(storeId);    
    const navigate = useNavigate();
    const userStoreMe = useUserStoreMe(storeId);
    const userLogged = useUserLoggedStore( (state: any) => state.user );
    const [current, setCurrent] = useState('deps');
    const [menuResApi, setMenuResApi] = useState<IAPIMenuRes[] | undefined>(undefined);
    const [menuAnt, setMenuAnt] = useState<any>();
    const openCheckoutDrawer = useCheckoutDrawer( (state: any) => state.open );
    const clearUser = useUserLoggedStore( (state: any) => state.clearUser );       
 

    useEffect(()=>{
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;

        EcommService.get_stores_menu(storeId)
            .then((resStore: any)=>{        
                setMenuResApi(resStore.data.data);
            })
            .catch((error: any)=>{
                if (error.response.status == 401){
                    
                }
                else{

                }                
            });
            ;
    }, []);

    useEffect(()=>{
        if (!menuResApi) return;
        let new_menu: MenuProps['items'] = [            
            {
                label: <>Departamentos <CaretDownOutlined /></>,
                key: 'deps',              
                children: menuResApi.map((deps: any) => {
                    return {
                        key: deps.id+deps.name,
                        label: <a href={"/loja/" + storeId + "?dep=" + deps.id}>{deps.name}</a>,                                               
                        children: deps?.categorys?.map((cat: any)=>{
                            return{
                                key: cat.id+cat.name,
                                label: <a href={"/loja/" + storeId + "?dep=" + deps.id + "&cat=" + cat.id}>{cat.name}</a>,
                                children: cat?.sub_categorys?.map((sub_cat: any)=>{
                                    return {
                                        key: sub_cat.id+sub_cat.name,
                                        label: <a href={"/loja/" + storeId + "?dep=" + deps.id + "&cat=" + cat.id + "&sub_cat=" + sub_cat.id}>{sub_cat.name}</a>,
                                    }
                                })
                            }
                        })
                    }
                })
            },
            {
                label: 'Lojas',
                key: 'lojas',
                onClick: ()=>navigate("/")          
            },
        ]
        setMenuAnt(new_menu)
    }, [menuResApi])



    const handleLoggout = () => {
        Authentication.logout()
        .then(function (response: any) {
          clearUser();
          navigate("/login");
        })
        .catch(function (error: any) {          
        });
    }

    let items: MenuProps['items'] = [
      {
        key: '1',
        label: (
          <Button type="link" onClick={()=>navigate("/login")}><AppstoreOutlined />Login</Button>
        ),
      },
      {
        key: '2',
        label: (
          <Button type="link" onClick={()=>navigate("/cadastro")}><AppstoreOutlined />Cadastro</Button>
        ),
      }
    ];

    if(userLogged?.first_name){
      items = [
        // {
        //   key: '1',
        //   label: (
        //     <Button type="link" onClick={()=>navigate("/perfil")}><AppstoreOutlined />Perfil</Button>
        //   ),
        // },
        {
          key: '2',
          label: (
            <><Button type="link" onClick={handleLoggout}><PoweroffOutlined /> Sair</Button></>
          ),
        }
      ];
    }

    // Perfil store user
    if (userStoreMe && userStoreMe?.data && userStoreMe?.data?.role == "Vendedor"){
      items = [
          {
            key: '1',
            label: (
              <Button type="link" onClick={()=>navigate("/lojas")}><AppstoreOutlined />Minhas Lojas</Button>
            ),
          },
          {
            key: '2',
            label: (
              <><Button type="link" onClick={()=>navigate("/lojas/"+ storeId + "/venda")}><PercentageOutlined /> Venda</Button></>
            ),
          },
          {
            key: '3',
            label: (
              <><Button type="link" onClick={()=>navigate("/lojas/" + storeId + "/produtos")}><FileTextOutlined /> Produtos</Button></>
            ),
          },
          {
            key: '4',
            label: (
              <><Button type="link" onClick={handleLoggout}><PoweroffOutlined /> Sair</Button></>
            ),
          }
      ];
    }

    if (userStoreMe && userStoreMe?.data && userStoreMe?.data?.role == "Caixa"){
      items = [
          {
            key: '1',
            label: (
              <Button type="link" onClick={()=>navigate("/lojas")}><AppstoreOutlined />Minhas Lojas</Button>
            ),
          },
          {
            key: '2',
            label: (
              <><Button type="link" onClick={()=>navigate("/lojas/"+ storeId + "/caixa")}><BarcodeOutlined /> Caixa</Button></>
            ),
          },
          {
            key: '3',
            label: (
              <><Button type="link" onClick={()=>navigate("/lojas/" + storeId + "/produtos")}><FileTextOutlined /> Produtos</Button></>
            ),
          },     
          {
            key: '4',
            label: (
              <><Button type="link" onClick={handleLoggout}><PoweroffOutlined /> Sair</Button></>
            ),
          }
      ];
    }

    if (userStoreMe && userStoreMe?.data && userStoreMe?.data?.role == "Gerente"){
      items = [
        {
            key: '1',
            label: (
              <Button type="link" onClick={()=>navigate("/lojas")}><AppstoreOutlined />Minhas Lojas</Button>
            ),
          },
          {
            key: '1',
            label: (
              <Button type="link" onClick={()=>navigate("/lojas")}><AppstoreOutlined />Minhas Lojas</Button>
            ),
          },
          {
            key: '2',
            label: (
              <><Button type="link" onClick={()=>navigate("/lojas/"+ storeId + "/venda")}><PercentageOutlined /> Venda</Button></>
            ),
          },
          {
            key: '3',
            label: (
              <><Button type="link" onClick={()=>navigate("/lojas/"+ storeId + "/caixa")}><BarcodeOutlined /> Caixa</Button></>
            ),
          },
          {
            key: '4',
            label: (
              <><Button type="link" onClick={()=>navigate("/lojas/" + storeId + "/produtos")}><FileTextOutlined /> Produtos</Button></>
            ),
          },
          {
            key: '5',
            label: (
              <><Button type="link" onClick={()=>navigate("/lojas/" + storeId + "/admin/vendas")}><FileTextOutlined /> Admin Vendas</Button></>
            ),
          },        
          {
            key: '6',
            label: (
              <><Button type="link" onClick={handleLoggout}><PoweroffOutlined /> Sair</Button></>
            ),
          }
      ];
    }

    const itemsMainManu: MenuProps['items'] = [
        {
          label: 'Lojas',
          key: 'lojas'          
        },
        {
          label: <>Departamentos <CaretDownOutlined /></>,
          key: 'deps',          
          children: [
            {
              type: 'group',
              label: 'Item 1',
              children: [
                {
                  label: 'Option 1',
                  key: 'setting:1',
                },
                {
                  label: 'Option 2',
                  key: 'setting:2',
                },
              ],
            },
            {
              type: 'group',
              label: 'Item 2',
              children: [
                {
                  label: 'Option 3',
                  key: 'setting:3',
                },
                {
                  label: 'Option 4',
                  key: 'setting:4',
                },
              ],
            },
          ],
        },
        {
          label: "Ofertas do Dia",
          key: 'oferta',
        },
    ];

    return (
        <>
        <Header style={
            {
                background: store?.data?.background_css ? store?.data?.background_css : "#fff",
                color: store?.data?.color_css ? store?.data?.color_css : "#000",
                padding: "0px 15px",
                display:"flex",
                justifyContent: "space-between",
            }
        }>  
            <Space>
                <h1>{store?.data?.name}</h1>                                                
            </Space>
            <span style={{width: "60%", display: "flex", alignItems: "center"}}>
                <Search placeholder="Pesquisa na Infor" onSearch={(e: any)=>{navigate("/loja/" + storeId + "?search=" + e)}} enterButton />
            </span>
            <Space style={{justifyContent: "space-between"}}>                                                          
                <Dropdown menu={{ items }} placement="bottomLeft">
                <p>Olá, {userLogged?.first_name ? userLogged?.first_name : "Entre ou Cadastre-se"}</p>                
                </Dropdown>
                {userStoreMe?.data?.client_level ? " / Nível " + userStoreMe?.data?.client_level : ""}
                <div style={{fontSize: "35px", marginLeft: "10px", color: store?.data?.color_css ? store?.data?.color_css : "red"}}>
                    <ShoppingCartOutlined onClick={openCheckoutDrawer}/>            
                </div>
            </Space>
            
        </Header>  
        <Menu onClick={()=>{}} selectedKeys={[current]} mode="horizontal" items={menuAnt} />
        </>    
    );
}