import { Button, Col, DatePicker, Input, Modal,notification, Row, Select, Statistic, TimePicker, Typography } from "antd";
import casherSale from "../../services/CasherSale";
import { useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import React, { useEffect, useState } from "react";
import { NotificationPlacement } from "antd/es/notification/interface";
import { useCasherOpen } from "../../store/useCasherOpen";
import { formatter_money_br, moneyMask, moneyToNumber } from "../../utils/formatters";
import { IAPICasherSaleRes } from "../../services/CasherSale/casherSale.interfaces";
import {FireOutlined} from '@ant-design/icons';
import { useStore } from "../../store/useStore";
import { CasherUpdateSaleGeneralDiscountModal } from "../CasherUpdateSaleGeneralDiscountModal";
import { CasherUpdateStateConcluidoModal } from "../CasherUpdateStateConcluidoModal";

const Context = React.createContext({ name: 'Lojas Infor' });
const {Text, Title} = Typography;

interface IAddSaleProductModalProps {
    visible: boolean, handleCancel: Function
}

const methodsOptions = [
    {
        value: "Dinheiro",
        label: "Dinheiro",
    },
    {
        value: "Crédito à Vista",
        label: "Crédito à Vista",
    },
    {
        value: "Crédito Parcelado",
        label: "Crédito Parcelado",
    },
    {
        value: "Débito",
        label: "Débito",
    },
    {
        value: "Pix",
        label: "Pix",
    },
];


export const CasherAddSaleTransactionModal = (props: IAddSaleProductModalProps) => {    
    const [api, contextHolder] = notification.useNotification();
    const { storeId, saleId } = useParams();    
    const queryClient = useQueryClient();
    const [valuePay, setValuePay] = useState("R$ 0,00");
    const [methodPay, setMethodPay] = useState("Dinheiro");
    const casherOpen = useCasherOpen(storeId);
    const [pixCode, setPixCode] = useState("");
    const [pixAtDate, setPixAtDate] = useState<any | null>();
    const [pixAtTime, setPixAtTime] = useState<any | null>();
    const [cardNSU, setCardNSU] = useState("");
    const [description, setDescription] = useState("");
    const [cardValueTaxsClient, setCardValueTaxsClient] = useState("");
    const [cardName, setCardName] = useState("");
    const [cardNumber, setCardNumber] = useState("");
    const [cardInstallment, setCardInstallment] = useState("");
    const [cardTid, setCardTid] = useState("");
    const [cardAuto, setCardAuto] = useState("");
    const currentSale = useQueryClient().getQueryData<IAPICasherSaleRes>(["currentSale", storeId, saleId]);
    const [isVisibleGeneralDiscountModal, setIsVisibleGeneralDiscountModal] = useState(false);
    const [isVisibleUpdateConcluidoModal, setIsVisibleUpdateConcluidoModal] = useState(false);    
    const store = useStore(storeId);    

    const openNotificationSuccess = (placement: NotificationPlacement, description: string) => {    
        api.success({
          message: "Sucesso",
          description: description,
          placement,
          duration: 2
        });
    }

    const openNotificationError = (placement: NotificationPlacement, description: string) => {    
        api.error({
          message: "Erro",
          description: description,
          placement,
          duration: 2
        });
    }

    const handleOk = () => { 
        let isPayAll = false; 
        if(!saleId){
            openNotificationError("bottomRight", "Venda Não Identificada, Por Favor Tente Novamente.");
            return;
        }
        if( !casherOpen == undefined || !casherOpen.data || !casherOpen?.data?.id ){
            openNotificationError("bottomRight", "Venda Não Identificada, Por Favor Tente Novamente.");
            return;
        }        
        
        let param;

        if (methodPay == "Pix"){
            if(!pixAtDate){
                openNotificationError("bottomRight", "Informe a Data do Pix.");
                return;
            }
            let pix_at = pixAtDate.format("YYYY") + "-" + 
                    pixAtDate.format("MM") + "-" + 
                    pixAtDate.format("DD") + " " + 
                    pixAtTime.format("HH") + ":" +  
                    pixAtTime.format("mm") + ":" + 
                    pixAtTime.format("ss");

            param = {
                pos_sale_id: parseInt(saleId),
                value: moneyToNumber(valuePay),
                method: methodPay,
                payments_casher_id: casherOpen.data.id,
                pix_at: pix_at,
                pix_cod: pixCode,
                description: description,
            }            
        }
        // IF Dinheiro and value more that wait receive, pay just total wait receive
        else if(methodPay == "Dinheiro" && currentSale?.total_wait_receive && moneyToNumber(valuePay) > currentSale?.total_wait_receive){            
            param = {
                pos_sale_id: parseInt(saleId),
                value: parseFloat(currentSale?.total_wait_receive+""),
                method: methodPay,
                payments_casher_id: casherOpen.data.id,
                card_nsu: cardNSU.length > 0 ? cardNSU : undefined,
                description: description.length > 0 ? description : undefined,
            };      

        }
        else {
            param = {
                pos_sale_id: parseInt(saleId),
                value: moneyToNumber(valuePay),
                method: methodPay,
                payments_casher_id: casherOpen.data.id,
                card_nsu: cardNSU.length > 0 ? cardNSU : undefined,
                description: description.length > 0 ? description : undefined,                
                card_value_taxs_client: cardValueTaxsClient.length > 0 ? moneyToNumber(cardValueTaxsClient) : undefined,
                card_name: cardName.length > 0 ? cardName : undefined,
                card_number: cardNumber.length > 0 ? cardNumber : undefined,
                card_installment: cardInstallment.length > 0 ? cardInstallment : undefined,
                card_tid: cardTid.length > 0 ? cardTid : undefined,
                card_auto: cardAuto.length > 0 ? cardAuto : undefined,
            }
        }

        if(currentSale?.total_wait_receive && currentSale?.total_wait_receive-moneyToNumber(valuePay) <= 0){
            isPayAll = true;
        }

        casherSale.post_transaction(param)
            .then((res: any) =>{                
                queryClient.invalidateQueries(['currentSale', storeId, saleId]);       
                queryClient.invalidateQueries(['currentSaleProducts', saleId]);
                queryClient.invalidateQueries(['currentSaleTransactions', saleId]);
                queryClient.invalidateQueries(['casherOpen', storeId]);
                openNotificationSuccess("bottomRight", "Pagamento Adicionado com Sucesso." );                                          
                if(isPayAll){
                    setIsVisibleUpdateConcluidoModal(true);
                }
                else{
                    onCancel(); 
                }
            })
            .catch((error:any)=>{
                if(error.response?.data?.detail){                    
                    openNotificationError("bottomRight", error.response?.data?.detail);
                }
                else{
                    openNotificationError("bottomRight", "Problema ao Adicionar Pagamento.");
                }                
            })
    };
    
    const onCancel = () => {        
        props.handleCancel();  
        setValuePay("R$ 0,00");
        setCardNSU("");
        setPixAtDate("");
        setPixAtTime("");
        setPixCode("");
        setPixCode("");
        setDescription("");
        setCardValueTaxsClient("");
        setCardAuto("");
        setCardName("");
        setCardNumber("");
        setCardInstallment("");
        setMethodPay("Dinheiro");
    };


    const handleMoney = (eventInput: any) => {
        let money_masked = moneyMask(eventInput?.target?.value);
        setValuePay(money_masked);
    }

    return (
        <Context.Provider value={{name: "Geral"}}>
        {contextHolder}
        <Modal
            open={props.visible}
            title="Adicionar Pagamento"
            onOk={handleOk}
            onCancel={onCancel}
            footer={[
                <>
                 {(currentSale?.client_level && 
                    (currentSale?.advanced_authorization_by_auth_user 
                    || ( currentSale?.client_level == 1 && store?.data?.pre_approved_percent_discount_max_n1 && store?.data?.pre_approved_percent_discount_max_n1 > 0 )
                    || ( currentSale?.client_level == 2 && store?.data?.pre_approved_percent_discount_max_n2 && store?.data?.pre_approved_percent_discount_max_n2 > 0)
                    ) &&
                   methodPay=="Dinheiro"
                ) &&  
                    <Button icon={<FireOutlined />} onClick={()=>{setIsVisibleGeneralDiscountModal(true)}} style={currentSale?.state != "Cancelado" && currentSale?.state != "Concluído" ? {color:"magenta"} : {}}  disabled={currentSale?.state == "Cancelado" || currentSale?.state == "Concluído"} /> 
                }
                </>,
                <Button key="back" onClick={onCancel}>
                    Voltar
                </Button>,
                <Button type="primary" key="Remover da Venda" onClick={handleOk}>
                    Confirmar
                </Button>,                
            ]}>
            <Row>
                <Col span={8}>
                    <Statistic title="Aguardando Pagamento" value={formatter_money_br.format(currentSale?.total_wait_receive ? currentSale?.total_wait_receive : 0)} />
                </Col>
                {(methodPay == "Dinheiro" && currentSale && (currentSale.total_wait_receive ? currentSale.total_wait_receive : 0) - moneyToNumber(valuePay) < 0) &&
                <Col span={8} offset={8}>
                    <Statistic title="Troco" value={formatter_money_br.format((currentSale?.total_wait_receive ? currentSale?.total_wait_receive - moneyToNumber(valuePay) : 0))} valueStyle={{ color: '#cf1322' }} />
                </Col>
                }
            </Row>
            <Input.Group compact>                           
                <Input size="large" style={{ width: '50%' }}  placeholder="Pesquisar" value={valuePay} onChange={(e: any)=>handleMoney(e)} autoComplete="off" />
                <Select
                    size="large"
                    defaultValue="Dinheiro"
                    style={{ width: "30%" }}
                    onChange={(value)=>setMethodPay(value)}     
                    value={methodPay}               
                    options={methodsOptions}
                    />
            </Input.Group>
            <br/>
            {["Crédito à Vista", "Crédito Parcelado", "Débito"].includes(methodPay) &&
                <>
                <Text>Dados do Comprovante do Pagamento:</Text>
                <Input.Group compact>
                    <Input size="large" style={{ width: '30%' }} value={cardNSU} onChange={(e)=>setCardNSU(e?.target?.value)} placeholder="NSU" autoComplete="off"/>
                    <Input size="large" style={{ width: '30%' }} value={cardTid} onChange={(e)=>setCardTid(e?.target?.value)} placeholder="TID" autoComplete="off"/>
                    <Input size="large" style={{ width: '30%' }} value={cardAuto} onChange={(e)=>setCardAuto(e?.target?.value)} placeholder="AUT" autoComplete="off"/>
                </Input.Group>
                <br/>
                <Text>Cartão:</Text>
                <Input.Group compact>                                                                   
                    <Input size="large" style={{ width: '80%' }} value={cardName} onChange={(e)=>setCardName(e?.target?.value)} placeholder="Nome no Cartão" autoComplete="off"/>
                    <Input size="middle" style={{ width: '50%' }} value={cardNumber} onChange={(e)=>setCardNumber(e?.target?.value)} placeholder="Final do Cartão" autoComplete="off"/>
                    <Input size="middle" style={{ width: '30%' }} value={cardInstallment} onChange={(e)=>setCardInstallment(e?.target?.value)} placeholder="Parcelas x" autoComplete="off"/>                    
                </Input.Group>
                <br/>
                <Text>Cobrança:</Text>
                <Input.Group>
                    <Input value={cardValueTaxsClient} onChange={(e)=>setCardValueTaxsClient(moneyMask(e?.target?.value))} placeholder="Taxa Cliente" autoComplete="off"/>
                </Input.Group>
                </>
            }
            {methodPay == "Pix" &&
                <Input.Group compact>  
                    <Input value={pixCode} onChange={(e)=>{setPixCode(e?.target?.value)}} placeholder="Código PIX" size="large" style={{ width: '50%' }} autoComplete="off"/><br/>
                    <br />
                    <DatePicker value={pixAtDate} onChange={(value) => setPixAtDate(value)} format={"DD/MM/YYYY"} />
                    <TimePicker value={pixAtTime} onChange={(value) => setPixAtTime(value)} />
                </Input.Group>
            }
            <br />
            <Input value={description} onChange={(e)=>{setDescription(e?.target?.value)}} placeholder="Detalhes do Pag." size="large" style={{ width: '50%' }} autoComplete="off"/><br/>
        </Modal>
        <CasherUpdateSaleGeneralDiscountModal visible={isVisibleGeneralDiscountModal} handleCancel={()=>{setIsVisibleGeneralDiscountModal(false)}} product={{}} />
        <CasherUpdateStateConcluidoModal visible={isVisibleUpdateConcluidoModal} handleCancel={()=>{setIsVisibleUpdateConcluidoModal(false); onCancel();}}/>
        </Context.Provider>
    );
}