const barcodeListener = (handleFounded: Function) => {
    let code = "";        
    let reading = false;

    document.addEventListener('keypress', e => {
    // usually scanners throw an 'Enter' key at the end of read    
    if (e.keyCode === 13) {
            if(code.length > 10) {
                /// code ready to use    
                handleFounded(code);
                code = "";
            }
        } else {
            code += e.key; //while this is not an 'enter' it stores the every key            
        }

        //run a timeout of 200ms at the first read and clear everything
        if(!reading) {
            reading = true;
            setTimeout(() => {
                code = "";
                reading = false;
            }, 200);  //200 works fine for me but you can adjust it
        };    
    });
}

export {barcodeListener}