import { Alert, Button, Col, Divider, Input, InputNumber, Modal, notification, Row, Space, Statistic, Tag, Typography } from "antd";
import {FireOutlined, CheckOutlined, MinusCircleOutlined } from '@ant-design/icons';
import casherSale from "../../services/CasherSale";
import { useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import React, { useState, useEffect } from "react";
import { NotificationPlacement } from "antd/es/notification/interface";
import { formatter_money_br, moneyToNumber, moneyMask, floatToMoneyFormat, formatter_money_br_3_dig } from "../../utils/formatters";

const Context = React.createContext({ name: 'Lojas Infor' });

const {Text, Title} = Typography;

interface IAddSaleProductModalProps {
    visible: boolean, handleCancel: Function, product: any
}

export const CasherUpdateSaleProductDiscountModal = (props: IAddSaleProductModalProps) => {
    const [api, contextHolder] = notification.useNotification();
    const { storeId, saleId } = useParams();    
    const queryClient = useQueryClient();
    const [discountValue, setDiscountValue] = useState(0.00);
    const [discountPercent, setDiscountPercent] = useState(0);
    const [totalValueWithDiscount, setTotalValueWithDiscount] = useState(0.00);
    const [newPriceWithDiscount, setNewPriceWithDiscount] = useState(0.00);    
    const [notes, setNotes] = useState("");
    
    const getPriceWithDiscount = (manual_discount: number) => {
        return (props?.product?.price - 
            (props?.product?.price*
                ((props?.product?.automatic_percentage_discount + manual_discount)/100)
            )
    )};

    const getPriceWithAutoDisc = () => {
        if (props?.product?.automatic_percentage_discount) {
            return (props?.product?.price * 
                ((100-props?.product?.automatic_percentage_discount)/100)
            );
        }
        else{
            return props?.product?.price;
        }
    }

    useEffect(()=>{      
        if(props?.product?.manual_percentage_discount) {
            setDiscountValue(
                    (props?.product?.price*
                        (props?.product?.manual_percentage_discount/100)
                    )            
            );
            setDiscountPercent(props?.product?.manual_percentage_discount);
        }
        else{
            setDiscountValue(0);
            setDiscountPercent(0);
        }
        setNewPriceWithDiscount(
            getPriceWithDiscount(props?.product?.manual_percentage_discount)
        )
        
        setTotalValueWithDiscount(props.product?.sub_total);
    }, [props.product])

    const openNotificationSuccess = (placement: NotificationPlacement, description: string) => {    
        api.success({
          message: "Sucesso",
          description: description,
          placement,
          duration: 2
        });
    }

    const openNotificationError = (placement: NotificationPlacement, description: string) => {    
        api.error({
          message: "Erro",
          description: description,
          placement,
          duration: 2
        });
    }

    const handleOk = (user: any) => {        
        const param = {
            manual_percentage_discount: discountPercent,
            notes: notes.length > 0 ? notes : undefined,
        }        
        
        casherSale.put_product(saleId, props.product?.id, param)
            .then((res: any) =>{                
                queryClient.invalidateQueries(['currentSale', storeId, saleId]);
                queryClient.invalidateQueries(['currentSaleProducts', saleId]);           
                openNotificationSuccess("bottomRight", "Atualizado com Sucesso." );
                onCancel();
            })
            .catch((error:any)=>{
                queryClient.invalidateQueries(['currentSale', storeId, saleId]);       
                queryClient.invalidateQueries(['currentSaleProducts', saleId]);
                queryClient.invalidateQueries(['currentSaleTransactions', saleId]);
                queryClient.invalidateQueries(['casherOpen', storeId]);
                if(error.response?.data?.detail) {
                    openNotificationError("bottomRight", error.response?.data?.detail);
                }
                else{
                    openNotificationError("bottomRight", "Problema ao Atualizar Nível");
                }
            })
    };
    
    const onCancel = () => {        
        props.handleCancel();    

        if(props?.product?.manual_percentage_discount) {
            setDiscountValue(
                    (props?.product?.price*
                        (props?.product?.manual_percentage_discount/100)
                    )            
            );
            setDiscountPercent(props?.product?.manual_percentage_discount);
        }
        else{
            setDiscountValue(0);
            setDiscountPercent(0);
        }
        setNewPriceWithDiscount(
            getPriceWithDiscount(props?.product?.manual_percentage_discount)
        )
        
        setTotalValueWithDiscount(props.product?.sub_total);
        setNotes("");
    };    

    // Ipdates manual percent, value and total
    const handleDiscountValue = (param: string) =>{  
        // moviment the numbe 
        let value = moneyToNumber(param);
        if( (props?.product?.automatic_percentage_discount > 0 && value+getPriceWithAutoDisc() > props?.product?.price) || value > getPriceWithAutoDisc() ){
            value=props?.product?.price - getPriceWithAutoDisc();                        
            openNotificationError("bottomRight", "Desconto Maior Que o Preço.");
        }
        else if(value < 0){
            openNotificationError("bottomRight", "Desconto Menor Que Zero.");
            value=0;
        }
       
        let percent_new_value_about_price = (
            (value*100)/props?.product?.price
        );

        let discount_manual = percent_new_value_about_price;
        
        handleDiscountPercent(discount_manual);    
        setDiscountValue(value)
    }

    const handleDiscountPercent = (value: number) =>{  
        let disc_percent;
        if(value<0){
            disc_percent = 0;
            openNotificationError("bottomRight", "Percentual Precisa ser Maior que 0%.");
        }
        else if(value>100){
            disc_percent = 100 - props?.product?.automatic_percentage_discount;
            openNotificationError("bottomRight", "Percentual Precisa ser Menor que 100%.");
        }
        else if(!value){
            disc_percent = 0;
        }
        else{
            disc_percent = parseFloat(value.toFixed(2));
        }

        setDiscountPercent(disc_percent);
          
        let value_discount = (
            (disc_percent/100)*props?.product?.price
        );

        
        setDiscountValue(value_discount);        
        
        setNewPriceWithDiscount(            
                (getPriceWithDiscount(disc_percent))            
        );     

        setTotalValueWithDiscount(
                (getPriceWithDiscount(disc_percent) * props?.product?.quantity)
        );
    }

    const handleNewPriceWithDiscount = (param: string) =>{                   
        let new_value_number = moneyToNumber(param);
        
        if(new_value_number > getPriceWithAutoDisc()){
            new_value_number = getPriceWithAutoDisc();            
            openNotificationError("bottomRight", "Preço Atualizado Maior Que o Valor Com Desconto Automático.");
        }
        if(new_value_number < 0){
            new_value_number = 0;
            openNotificationError("bottomRight", "Preço Atualizado Precisa Ser Maior ou Igual a Zero.");
        }

        let discount_general = (
            (new_value_number*100)/props?.product?.price
        );

        let discount_manual=0;
        if(props?.product?.automatic_percentage_discount){
            discount_manual = Math.abs( discount_general - props?.product?.automatic_percentage_discount );
        }
        else{
            discount_manual = 100 - discount_general;
        }

        handleDiscountPercent(Math.abs(discount_manual));

        setNewPriceWithDiscount(
            new_value_number
        );
            
        // setDiscountValue(
        //     formatter_money_br.format(
        //         (discount_manual/100)*props?.product?.price
        //     )
        // );
        // setTotalValueWithDiscount(
        //     formatter_money_br.format(
        //         getPriceWithDiscount(discount_manual) * props?.product?.quantity
        //     )            
        // );
    }

    
    return (
        <Context.Provider value={{name: "Geral"}}>
        {contextHolder}
        <Modal
            open={props.visible}
            title="Desconto no Produto"
            onOk={handleOk}
            onCancel={onCancel}
            footer={[,
            <Button key="back" onClick={onCancel}>
                Voltar
            </Button>,            
            <Button type="primary" key="remove" onClick={handleOk}>
                Confirmar
            </Button>
            ]}
    >       
            <Row>
                <Text>{props?.product?.iventory_product?.name} <b>x{props?.product?.quantity}</b></Text>
            </Row>
            <Row style={{ marginBottom: 20 }} justify="space-between">                
                <Statistic title="Preço:" value={formatter_money_br.format(props?.product?.price)} />                    
                <Statistic title="Desc Auto:" 
                    value={
                        formatter_money_br.format(
                        (props?.product?.price * 
                            (props?.product?.automatic_percentage_discount/100)
                        )
                    )}
                    />                    
                                            
                <Statistic title="Preço C/ Desconto Auto.:" value={formatter_money_br.format(
                        getPriceWithAutoDisc()
                    )} valueStyle={{ color: 'green' }} />                    
            </Row> 
            <Divider />           
            <Row style={{ marginBottom: 20 }}>                
                <Col span={12}>
                    <Text>Desconto Manual(R$)</Text>
                    <Input size="large" style={{ width: '50%' }}  placeholder="R$ 0,00" 
                            value={floatToMoneyFormat(discountValue, 3)} 
                            onChange={(e: any)=>{handleDiscountValue(moneyMask(e.target.value, 3))}} 
                            autoComplete="off"
                    />
                </Col>
                <Col span={12}>
                    <Text>Desc. Manual(%) [max: {100-props?.product?.automatic_percentage_discount}%]</Text>
                    <InputNumber size="large" style={{ width: '50%' }}  
                            placeholder="0" max={100-props?.product?.automatic_percentage_discount} 
                            min={0}
                            value={discountPercent} 
                            onChange={(e: any)=>{handleDiscountPercent(e)}} 
                    />
                </Col>                            
            </Row>
            <Row>
                <Col span={12}>                    
                    <Row>
                        <Text>Novo Preço C/ Desc.(R$)</Text>
                    </Row>
                    <Row>
                        <Input size="large" style={{ width: '50%' }}  placeholder="R$ 0,00" 
                                value={floatToMoneyFormat(newPriceWithDiscount, 3)} 
                                onChange={(e: any)=>handleNewPriceWithDiscount(moneyMask(e.target.value, 3))} 
                                disabled={true}
                                autoComplete="off"
                        />
                    </Row>
                </Col>
                <Col span={12}>      
                    <Row>
                        <Statistic title="Sub Total(R$):" value={floatToMoneyFormat(totalValueWithDiscount, 2)} />
                    </Row>
                </Col>
            </Row>
            <Row style={{marginTop: 30}}>
                <Statistic title="Adicionar Desconto Manual De:" value={discountPercent?.toFixed(2) + "%"} valueStyle={{ color: 'magenta' }} /> 
                {newPriceWithDiscount < props.product?.cost_value &&
                    <Space>
                        <Alert message={"Novo Preço Menor Que Valor de Custo"} type="error" showIcon />                
                    </Space>
                }
                {(newPriceWithDiscount < (props.product?.cost_value/0.9)) &&
                    <Space>
                        <Alert message={"Atenção, Politica de Desconto"} type="warning" showIcon />                
                    </Space>
                }
                <Input size="large" onChange={(e:any)=>setNotes(e.target.value)} value={notes} placeholder="Descrição" autoComplete="off"/>
            </Row>            
        </Modal>
        </Context.Provider>
    );
}