import { CloseOutlined, GiftOutlined, SwapOutlined, GlobalOutlined, PlusOutlined, ShoppingOutlined, LockOutlined, UnlockOutlined, EllipsisOutlined, PauseOutlined, CheckOutlined } from '@ant-design/icons';
import { Col, Layout, Row, Divider, Button, Modal, Typography, Dropdown, Space, Checkbox, notification, Spin, Alert } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useCurrentSale } from '../../store/useCurrentSale';
import useBarcode from '../../store/useBarcode';
import { CasherProductsTable } from '../../components/CasherProductsTable';
import { StoreHeader } from '../../components/StoreHeader';
import { useStore } from '../../store/useStore';
import "./styles.scss";
import { useCurrentSaleProducts } from '../../store/useCurrentSaleProduct';
import { dateFormatter, formatter_money_br } from '../../utils/formatters';

const Context = React.createContext({ name: 'Lojas Infor' });
const { Sider, Content, Header } = Layout;
const { Text, Title } = Typography;
  
const SalePrintPaper = (props: any) => {
    const barcode = useBarcode( (state: any) => state.barcode );
    const { storeId } = useParams();
    const { saleId } = useParams();
    const navigate = useNavigate();
    const currentSale = useCurrentSale(storeId, saleId);    
    const [api, contextHolder] = notification.useNotification();  
    const store = useStore(storeId);   
    const currentSaleProducts = useCurrentSaleProducts(saleId);
 
    useEffect(() => {
        document.title = 'Imp. Venda - Lojas Infor';
    }, []);
                 
    return(
        <Context.Provider value={{name: "Geral"}}>
        {contextHolder} 
        <table border={1} id="table-sale-print">
            <tr>
                <table border={1} style={{border: "none"}}>
                    <tr style={{position: "relative", border: 0}}>
                        <th style={{fontSize: "25px", border: 0, 
                                    background: store?.data?.background_css ? store?.data?.background_css : "#fff",
                                    color: store?.data?.color_css ? store?.data?.color_css : "#000"
                                    }}>{store?.data?.name}</th>
                        <th><small>Total S/ Desconto: {formatter_money_br.format(currentSale?.data?.sub_total_without_discount ? currentSale?.data?.sub_total_without_discount : 0)} <br/>Descontos: {formatter_money_br.format(currentSale?.data?.total_discount ? currentSale?.data?.total_discount : 0)}</small></th>
                        <th style={{fontSize: "30px", verticalAlign: "top", position: "absolute", right: 0, border: 0, color: "#f00"}}>{formatter_money_br.format(currentSale?.data?.total_to_receive ? currentSale?.data?.total_to_receive : 0)}</th>
                    </tr>
                    <tr>
                        <th><small>Venda:</small> #{currentSale?.data?.id}</th>
                        <th><small>Nível:</small> {currentSale?.data?.client_level}</th>
                        <th></th>                        
                    </tr>
                    <tr>
                        <th><small>Vendedor:</small> {currentSale?.data?.saler_by_auth_user?.first_name}</th>                        
                        <th><small>Cliente:</small> {currentSale?.data?.buyer_by_auth_user?.first_name}</th>
                        <th><small>Tel:</small> {currentSale?.data?.buyer_by_auth_user?.auth_user_information_related?.whatsapp_phone}</th>
                    </tr>
                    <tr>
                        <th><small>Separado Por:</small> ____________________________</th>
                        <th><small>Separado Em:</small> ____/____/_____ às ___:___</th>
                        <th></th>
                    </tr>
                    <tr>
                        {dateFormatter(currentSale?.data?.inserted_at)}
                    </tr>
                </table> 
            </tr>  
            <tr>
                <table border={1} style={{width: "100%"}}>            
                    <thead>
                        <tr>
                            <th>FT.</th>
                            <th>COD.</th>
                            <th>Produto</th>
                            <th>Preço</th>
                            <th>QTD</th>
                            <th>Sub Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentSaleProducts?.data?.map((prod: any)=>{
                            if (prod.is_canceled){
                                return;
                            }
                            return <tr>
                                <th>
                                    <img alt="example" src={prod?.iventory_product?.photo_main_mini ? prod?.iventory_product?.photo_main_mini : "/erro"} style={{maxWidth: "90px", objectFit: "contain",  maxHeight: "220px", marginRight: "5px", borderRadius: "5px"}} onError={(e: any) => {
                                                                        e.target.onerror = null; // prevents looping
                                                                        e.target.src="/no_image.jpg";
                                                                    }} />
                                </th>
                                <th>
                                    {prod?.iventory_product?.code}
                                </th>
                                <th>{prod?.iventory_product?.name}</th>
                                <th>{formatter_money_br.format(prod.price_with_discount)}</th>
                                <th>x{prod.quantity} {prod.iventory_product?.unit?.slice(0,2)}</th>
                                <th>{formatter_money_br.format(prod.sub_total)}</th>
                            </tr>
                        })}
                        
                    </tbody>
                </table>
            </tr>
        </table>

        </Context.Provider>
    );     
}

export default SalePrintPaper;
