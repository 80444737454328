import { Alert, Button, Input, Modal, notification, Select } from "antd";
import CasherService from "../../services/Casher";
import { useParams } from "react-router-dom";
import React, { useEffect, useRef, useState } from "react";
import { NotificationPlacement } from "antd/es/notification/interface";
import { useCurrentSale } from "../../store/useCurrentSale";
import { useCurrentSaleProducts } from "../../store/useCurrentSaleProduct";
import { useStore } from "../../store/useStore";
import { ReloadOutlined } from '@ant-design/icons';
import { IAPIPrinterRes } from "../../services/Casher/casher.interfaces";
import { dateFormatter, floatToMoneyFormat } from "../../utils/formatters";
import { useCurrentSaleTransaction } from "../../store/useCurrentSaleTransaction";
import { IparamPrintAPI } from "./interfaces";

const Context = React.createContext({ name: 'Lojas Infor' });

interface IAddSaleProductModalProps {
    visible: boolean, handleCancel: Function,
    toPrint?: boolean
}

export const CasherSalePrinterModal = (props: IAddSaleProductModalProps) => {
    const [api, contextHolder] = notification.useNotification();
    const { storeId, saleId } = useParams();    
    const [printType, setPrintType] = useState("Completo");
    const sale = useCurrentSale(storeId, saleId);
    const saleProducts = useCurrentSaleProducts(saleId);
    const store = useStore(storeId);
    const saleTransaction = useCurrentSaleTransaction(saleId);
    const [statusPrinter, setStatusPrinter] = useState(false);    
    const dataFetchedRef = useRef(false);

    useEffect(()=>{
        if (dataFetchedRef.current){
            return;
        }
        if(props.toPrint){
            handleOk();
        }
    }, [props.toPrint]);
    
    const openNotificationSuccess = (placement: NotificationPlacement, description: string) => {    
        api.success({
          message: "Sucesso",
          description: description,
          placement,
          duration: 2
        });
    }

    const openNotificationError = (placement: NotificationPlacement, description: string) => {    
        api.error({
          message: "Erro",
          description: description,
          placement,
          duration: 2
        });
    }

    const handleOk = () => {        
        let param: IparamPrintAPI;
        param = {
            lines: [
                {
                    config: {
                        double_width: true,
                        double_height: true,
                        bold: true,
                        align: "center",
                    },
                    text: store?.data?.name ? store?.data?.name : "Loja Não Identificada",
                    cut: false
                },
                {
                    config: {    
                        align: "center",
                    },
                    text: "Orçamento Para Aprovação",
                    cut: false
                },
                {
                    config: {    
                        align: "left",
                    },
                    text: "COD. Venda: "+(sale?.data?.id ? sale?.data?.id : " - "),
                    cut: false
                },
                {
                    config: {    
                        align: "left",
                    },
                    text: "Nível: "+(sale?.data?.client_level ? sale?.data?.client_level : " - "),
                    cut: false
                },
                {
                    config: {    
                        align: "left",
                    },
                    text: "COD. Vendedor: "+(sale?.data?.saler_by_auth_user?.id ? sale?.data?.saler_by_auth_user?.id : " - ")+ " | "+(sale?.data?.saler_by_auth_user?.first_name ? sale?.data?.saler_by_auth_user?.first_name : "Não Identificado"),
                    cut: false
                },
                {
                    config: {    
                        align: "left",
                    },
                    text: "COD. Cliente: "+(sale?.data?.buyer_by_auth_user?.id ? sale?.data?.buyer_by_auth_user?.id : " - ")+ " | "+(sale?.data?.buyer_by_auth_user?.first_name ? sale?.data?.buyer_by_auth_user?.first_name : "Não Identificado"),
                    cut: false
                },
                {
                    config: {    
                        align: "right",
                    },
                    text: "Cell: " + (sale?.data?.buyer_by_auth_user?.auth_user_information_related?.whatsapp_phone ? sale?.data?.buyer_by_auth_user?.auth_user_information_related?.whatsapp_phone : " - ") ,
                    cut: false
                },                
                {
                    config: {    
                        align: "right",
                        bold: true
                    },
                    text: "Estado: "+(sale?.data?.state ? sale?.data?.state : " - "),
                    cut: false
                },                
            ]
        }      

        if( sale?.data?.exchange ){
            let param_exchange = [
                {
                    config: {    
                        // bold: true,
                        invert: true,
                        align: "center",
                    },
                    text: "Troca de Produto",
                    cut: false
                }
            ];   
            param.lines = param.lines.concat(param_exchange);
        }
        else if( sale?.data?.gift ){
            let param_exchange = [
                {
                    config: {    
                        // bold: true,
                        invert: true,
                        align: "center",
                    },
                    text: "Brinde",
                    cut: false
                }
            ];   
            param.lines = param.lines.concat(param_exchange);
        }
         
        if( printType == "Completo" ){    
                                    
            let param_header_product = [
                {
                    config: {    
                        align: "left",
                        bold: true,
                        underline: 2
                    },
                    text: "Produtos: ",
                    cut: false
                },
                {
                    config: {    
                        align: "left",
                        bold: true,
                        underline: 2
                    },
                    text: "COD| PROD.| PREÇO| P. C/ DES.| QTD| S. TOTAL",
                    cut: false
                }
            ];   
            param.lines = param.lines.concat(param_header_product);
            
            let param_products:any = [];
            saleProducts?.data?.forEach((prod: any) => {
                if(prod.is_canceled){
                    return;
                }
                param_products.push({
                        config: {    
                            align: "left",                            
                        },
                        text: (prod?.iventory_product?.code ? prod?.iventory_product?.code : "-") + " | "  + 
                                (prod?.iventory_product?.name ? prod?.iventory_product?.name.slice(0, 30) : "-") + " | ",
                        cut: false
                    })
                param_products.push({
                        config: {    
                            align: "right",                            
                        },
                        text: ( 
                            (prod?.price ? floatToMoneyFormat(prod?.price, 2) : "R$ 0,00") + " | " +
                            (prod?.price_with_discount ? floatToMoneyFormat(prod?.price_with_discount, 2) : "R$ 0,00") + " | " +
                            "x" + (prod?.quantity ? prod?.quantity : "0") + (prod?.iventory_product?.unit ? prod?.iventory_product?.unit.slice(0, 2) : "") + " | " +
                            (prod?.sub_total ? floatToMoneyFormat(prod?.sub_total, 2) : "R$ 0,00")
                        ),
                        cut: false
                    })                                    
            });

            param.lines = param.lines.concat(param_products);

            let param_header_transactions = [
                {
                    config: {    
                        align: "left",
                        bold: true,
                        underline: 2
                    },
                    text: "Pagamentos: ",
                    cut: false
                }
            ];   
            param.lines = param.lines.concat(param_header_transactions);
            
            let param_trans:any = [];
            saleTransaction?.data?.forEach((trans: any) => {
                if(trans.canceled){
                    return;
                }
                param_trans.push({
                    config: {    
                        align: "left",                            
                        bold: true,
                    },
                    text: "Pago: " + (trans?.value ? floatToMoneyFormat(trans?.value, 2) : " - ") + " em " +(trans?.method ? trans?.method : " - "),
                    cut: false
                })  
                param_trans.push({
                        config: {    
                            align: "left",                            
                        },
                        text:  "COD.: " + (trans?.id ? trans?.id : " - ")  + " | Caixa: " + (trans?.payments_casher_id ? trans?.payments_casher_id : " - ") + " | Usua.: " + (trans?.inserted_by_auth_user?.first_name ? trans?.inserted_by_auth_user?.first_name : " - "),
                        cut: false
                    });                
                param_trans.push({
                        config: {    
                            align: "left",                            
                        },
                        text: "Em : " + (trans?.inserted_at ? dateFormatter(trans?.inserted_at) : " - "),
                        cut: false
                    });               
            });
            param.lines = param.lines.concat(param_trans);

        }

        let general_infor_param = [
            {
                config: {    
                    align: "right",
                },
                text: "_________________________",
                cut: false
            },
            {
                config: {    
                    align: "left",
                },
                text: "Total Produtos | Frete | Taxas:",
                cut: false
            },
            {
                config: {    
                    bold: true,
                    align: "left",
                },
                text: (sale?.data?.sub_total_without_discount ? floatToMoneyFormat(sale?.data?.sub_total_without_discount, 2) : "-") + " | " +
                    (sale?.data?.shipping_price_to_buyer ? floatToMoneyFormat(sale?.data?.shipping_price_to_buyer, 2) : "-") + " | "+
                    (sale?.data?.taxes ? floatToMoneyFormat(sale?.data?.taxes, 2) : "-") + " | ",
                cut: false
            },
            {
                config: {    
                    align: "left",
                },
                text: "Descontos:",
                cut: false
            },
            {
                config: {    
                    bold: true,
                    align: "left",
                },
                text: (sale?.data?.total_discount ? floatToMoneyFormat(sale?.data?.total_discount, 2) : "-"),
                cut: false
            },
            {
                config: {    
                    align: "left",
                },
                text: "Total Geral:",
                cut: false
            },
            {
                config: {                            
                    bold: true,
                    align: "left",
                },
                text: (sale?.data?.total_to_receive ? floatToMoneyFormat(sale?.data?.total_to_receive, 2) : "-"),
                cut: false
            }                        
        ];  

        param.lines = param.lines.concat(general_infor_param);
        if (sale?.data?.total_received && sale?.data?.total_received > 0){
            param.lines.push(
                {
                    config: {    
                        align: "right",
                    },
                    text: "Pago:",
                    cut: false
                },
                {
                    config: {                         
                        bold: true,
                        align: "right",
                    },
                    text: (sale?.data?.total_received ? floatToMoneyFormat(sale?.data?.total_received, 2) : "-"),
                    cut: false
                }
            )
        }

        if (sale?.data?.total_wait_receive && sale?.data?.total_wait_receive > 0){
            param.lines.unshift(
                {
                    config: {    
                        align: "center",
                        invert: true,                        
                        double_width: true,
                        double_height: true,
                    },
                    text: "Aguardando Pagamento:",
                    cut: false
                },
                {
                    config: {    
                        double_width: true,
                        double_height: true,                        
                        bold: true,
                        align: "center",
                        invert: true,
                    },
                    text: (sale?.data?.total_wait_receive ? floatToMoneyFormat(sale?.data?.total_wait_receive, 2) : "-"),
                    cut: false
                }
            )
        }

        param.lines.push(
            {
                config: {    
                    align: "center",
                },
                text: "Por Favor, Aguarde o Cupom Fiscal",
                cut: true
            }            
        )

        
        CasherService.to_print(param)
            .then((res: any) =>{                        
                openNotificationSuccess("bottomRight", "Atualizado com Sucesso." );
                onCancel();
            })
            .catch((error:any)=>{
                if(error.response?.data?.detail) {
                    openNotificationError("bottomRight", error.response?.data?.detail);
                }
                else{
                    openNotificationError("bottomRight", "Problema ao Imprimir");
                }
            })
    };
    
    const onCancel = () => {        
        props.handleCancel();    
        setPrintType("Completo");
    };    

    const handleChange = (value: string) =>{        
        setPrintType(value);        
    }

    const handleCheck = () => {
        CasherService.check_printer()
        .then((response: IAPIPrinterRes)=>{
            setStatusPrinter(true);
            openNotificationSuccess("bottomRight", "Impressora ok." );
        })
        .catch((error: any)=>{
            setStatusPrinter(false);
            if(error.response?.data?.detail) {
                openNotificationError("bottomRight", error.response?.data?.detail);
            }
            else{
                openNotificationError("bottomRight", "Problema ao Atualizar Nível");
            }
        })
    }

    return (
        <Context.Provider value={{name: "Geral"}}>
        {contextHolder}
        <Modal
            open={props.visible}
            title="Imprimir Informações"
            onOk={handleOk}
            onCancel={onCancel}
            footer={[
            <>
            {statusPrinter
                    ?                         
                        <Button icon={<ReloadOutlined />} onClick={handleCheck}>Impressora OK</Button>
                    : 
                    <>
                        <Button style={{color: "red"}} icon={<ReloadOutlined />} onClick={handleCheck}>Impressora Offline</Button>                        
                    </>
            }
            </>,
            <Button key="back" onClick={onCancel}>
                Voltar
            </Button>,            
            <Button type="primary" key="remove" onClick={handleOk}>
                Confirmar
            </Button>
            ]}
    >                 
            <Input.Group compact>                  
                <Select
                    size="large"
                    defaultValue="Completo"
                    style={{ width: "30%" }}
                    onChange={handleChange}     
                    value={printType}               
                    options={[
                        {
                            value: 'Completo',
                            label: 'Completo',
                        },
                        {
                            value: 'Resumo',
                            label: 'Resumo',
                        },
                    ]}
                    />                
            </Input.Group>           
                        
        </Modal>
        </Context.Provider>
    );
}