import React, { useEffect } from 'react';
import { floatToMoneyFormat } from '../../utils/formatters';
import useProductsPriceTag from '../../store/useProductsPriceTag';
import Barcode from 'react-barcode';


const ProductsPrintTag = () => {           
    const useProducts = useProductsPriceTag( (state: any) => state.products );
   
    useEffect(() => {
        document.title = 'Etiqueta - Lojas Infor';
    }, []);

    return(     
        <>
        <div style={{margin: 0}}>
        {useProducts.map((prod: any)=>{
            return <div style={{margin: "0px", height: "70px",  position: "relative"}}>
                    <small>{prod.code} {prod.abbreviation_name ? prod.abbreviation_name : prod.name.slice(0, 50)}</small>
                    <br/>
                    <span style={{ top: 0, margin: "0px", float: "right", right: 0}}>
                        {(prod.code_bar && prod.code_bar.length < 15) &&
                            <Barcode value={prod.code_bar} height={10} width={1} fontSize={10}/>                                    
                        }
                        <span style={{fontWeight: "bold", fontSize: "40px"}}>{floatToMoneyFormat(prod.price, 2)}</span>                                    
                    </span>                        
                    {prod.discount_level && prod.discount_level.length > 0 &&                            
                        <>
                        {prod.discount_level.map((level_d: any)=>{
                            if(level_d.client_level==2){
                                return <small style={{fontSize: "15px"}}>Nível {level_d.client_level} {floatToMoneyFormat(prod.price-(prod.price*(level_d.percent_discount/100)) ,3)}</small>
                            }
                        })}
                        </>
                    }                        
                </div>
        })}
        </div>
        </>
    ); 
}
  
export default ProductsPrintTag;