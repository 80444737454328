import api from "../APICommon";
import { IAPIStoreRes, IAPIUserStoreRes } from "./organization.interfaces";

class StoreService{
    get_store(storeId: string | undefined){
        return api().get<IAPIStoreRes>("auth/me/stores/" + storeId)
    }        
    get_user_store(storeId: string | undefined){
        return api().get<IAPIUserStoreRes>("organization/store/" + storeId + "/user/me")
    }
}

export default new StoreService();