import { Alert, Button, Input, Modal, notification, Select } from "antd";
import casherSale from "../../services/CasherSale";
import { useParams } from "react-router-dom";
import { useQueryClient } from "react-query";
import React, { useState } from "react";
import { NotificationPlacement } from "antd/es/notification/interface";

const Context = React.createContext({ name: 'Lojas Infor' });

interface IAddSaleProductModalProps {
    visible: boolean, handleCancel: Function
}

export const CasherUpdateLevelModal = (props: IAddSaleProductModalProps) => {
    const [api, contextHolder] = notification.useNotification();
    const { storeId, saleId } = useParams();    
    const queryClient = useQueryClient()    
    const [searchType, setSearchType] = useState("1");
    
    const openNotificationSuccess = (placement: NotificationPlacement, description: string) => {    
        api.success({
          message: "Sucesso",
          description: description,
          placement,
          duration: 2
        });
    }

    const openNotificationError = (placement: NotificationPlacement, description: string) => {    
        api.error({
          message: "Erro",
          description: description,
          placement,
          duration: 2
        });
    }

    const handleOk = (user: any) => {        
        const param = {
            client_level: parseInt(searchType),
        }        
        
        casherSale.put(saleId, param)
            .then((res: any) =>{                
                queryClient.invalidateQueries(['currentSale', storeId, saleId]);
                queryClient.invalidateQueries(['currentSaleProducts', saleId]);           
                openNotificationSuccess("bottomRight", "Atualizado com Sucesso." );
                onCancel();
            })
            .catch((error:any)=>{
                if(error.response?.data?.detail) {
                    openNotificationError("bottomRight", error.response?.data?.detail);
                }
                else{
                    openNotificationError("bottomRight", "Problema ao Atualizar Nível");
                }
            })
    };
    
    const onCancel = () => {        
        props.handleCancel();      
        setSearchType("1");
    };    

    const handleChange = (value: string) =>{        
        setSearchType(value);        
    }

    return (
        <Context.Provider value={{name: "Geral"}}>
        {contextHolder}
        <Modal
            open={props.visible}
            title="Atualizar Nível de Venda"
            onOk={handleOk}
            onCancel={onCancel}
            footer={[,
            <Button key="back" onClick={onCancel}>
                Voltar
            </Button>,            
            <Button type="primary" key="remove" onClick={handleOk}>
                Confirmar
            </Button>
            ]}
    >                 
            <Input.Group compact>                  
                <Select
                    size="large"
                    defaultValue="1"
                    style={{ width: "30%" }}
                    onChange={handleChange}     
                    value={searchType}               
                    options={[
                        {
                            value: '1',
                            label: 'Nível 1',
                        },
                        {
                            value: '2',
                            label: 'Nível 2',
                        },
                        {
                            value: '3',
                            label: 'Nível 3',
                        },
                        {
                            value: '4',
                            label: 'Nível 4',
                        },
                        {
                            value: '5',
                            label: 'Nível 5',
                        },
                    ]}
                    />
                <Alert style={{marginTop: "15px"}} type="warning" message="Atualização de Nível Irá Remover os Descontos Manuais" />
            </Input.Group>
        </Modal>
        </Context.Provider>
    );
}